import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

function getGreeting() {
  const now = new Date().getHours();
  if (now > 3 && now < 11) {
    return "Good Morning";
  } else if (now > 11 && now < 17) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
}

function WelcomeBanner({ user }) {
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const hideWishes = setTimeout(() => setHidden(true), 30000);
    return () => {
      clearTimeout(hideWishes);
    };
  }, []);

  return (
    <React.Fragment>
      {!hidden && (
        <div className="relative bg-gradient-to-r from-primary to-secondary p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
          {/* Content */}
          <div className="relative">
            <h1 className="text-2xl md:text-3xl text-onprimary  mb-1">
              <span>👋 {`${getGreeting()}, `}</span>
              <span className="italic">{user?.nickname}</span>
            </h1>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

WelcomeBanner.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    email_verified: PropTypes.bool,
    name: PropTypes.string,
    nickname: PropTypes.string,
    picture: PropTypes.string,
    sub: PropTypes.string,
    updated_at: PropTypes.string,
  }),
};

export default React.memo(WelcomeBanner);
