import {
  ALERT_KEYS_TYPE,
  ALERT_KEYS_VALUE,
  FrequencyListNames,
} from '../../../services/userProfile/userProfile.transformer';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import {
  PROFILE_OPERATION_TYPE,
  SOCIAL_MEDIA_TYPE,
  testGroupCompanyData,
} from '../../../common/constants';
import React, { useEffect, useState } from 'react';

import AddEditCompabyView from './AddEditCompanyView';
import { DIALOG_TYPE } from '../../../common/metadata';
import Dialog from '../../../common/Dialog';
import PropTypes from 'prop-types';
import useCompaniesCustomHook from '../../customHooks/useCompanies.customHook';

const MediaRowItem = ({ item, data, isExpanded }) => {
  const [toogleIcon, setToggleIcon] = useState(false);
  const [toogleIconTiktok, setToogleIconTiktok] = useState(false);

  const { searchTerm, type, alerts = [], categories = [] } = item;
  return (
    <div className="flex items-center justify-between border-b pl-2 ">
      <div
        className={`h-6 w-6 p-1 border rounded-full m-1 mr-2  flex justify-center items-center`}
      >
        <img
          className="h-6 w-6"
          src={`/icons/social-media-icons/colored/${type}.svg`}
          alt={''}
        />
      </div>
      <div className={`${isExpanded ? 'basis-1/5' : ''} text-sm pl-4`}>
        <div className="pr-4">
          {type != SOCIAL_MEDIA_TYPE.instagram &&
            type != SOCIAL_MEDIA_TYPE.tiktok && // Added condition for type
            (type === 'fb-simple' && searchTerm
              ? data?.masterSearch
              : searchTerm
              ? searchTerm.length > 29
                ? searchTerm.slice(0, 29)
                : searchTerm
              : '-')}
        </div>
        {type == SOCIAL_MEDIA_TYPE.instagram && (
          <div className="flex justify-end w-full pr-4">
            <span onClick={() => setToggleIcon(!toogleIcon)}>
              {' '}
              {!toogleIcon ? (
                <div className=" cursor-pointer">
                  <IoIosArrowDown />
                </div>
              ) : (
                <div className=" cursor-pointer">
                  <IoIosArrowUp />
                </div>
              )}
            </span>
          </div>
        )}
        {type == SOCIAL_MEDIA_TYPE.instagram &&
          data.instagramType &&
          toogleIcon && (
            <table>
              <tbody>
                {data?.instagramType?.userAccount != '' && (
                  <tr>
                    <td className="border-[1px] p-1 w-[100px]">User account</td>
                    <td className="border-[1px] p-1 w-[150px]">
                      {data?.instagramType?.userAccount != '' &&
                        data.instagramType.userAccount}
                    </td>
                  </tr>
                )}
                {data?.instagramType?.userTag != '' && (
                  <tr>
                    <td className="border-[1px] p-1 w-[100px]">User tag</td>
                    <td className="border-[1px] p-1 w-[150px]">
                      {data?.instagramType?.userTag != '' &&
                        `@${data.instagramType.userTag}`}
                    </td>
                  </tr>
                )}
                {data?.instagramType?.hashtag != '' && (
                  <tr>
                    <td className="border-[1px] p-1 w-[100px]">Hashtag</td>
                    <td className="border-[1px] p-1 w-[150px]">
                      {data?.instagramType?.hashtag != '' &&
                        `#${data.instagramType.hashtag}`}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        {type == SOCIAL_MEDIA_TYPE.tiktok && (
          <span
            className="flex justify-end w-full pr-4"
            onClick={() => setToogleIconTiktok(!toogleIconTiktok)}
          >
            {' '}
            {!toogleIconTiktok ? (
              <div className=" cursor-pointer">
                <IoIosArrowDown />
              </div>
            ) : (
              <div className=" cursor-pointer">
                <IoIosArrowUp />
              </div>
            )}
          </span>
        )}

        {type == SOCIAL_MEDIA_TYPE.tiktok &&
          data.tiktokType &&
          toogleIconTiktok && (
            <table>
              <tbody>
                {data?.tiktokType?.userAccount != '' && (
                  <tr>
                    <td className="border-[1px] p-1 w-[100px]">User account</td>
                    <td className="border-[1px] p-1 w-[150px]">
                      {data?.tiktokType?.userAccount != '' &&
                        data.tiktokType.userAccount}
                    </td>
                  </tr>
                )}
                {data?.tiktokType?.hashtag != '' && (
                  <tr>
                    <td className="border-[1px] p-1 w-[100px]">Hashtag</td>
                    <td className="border-[1px] p-1 w-[150px]">
                      {data?.tiktokType?.hashtag != '' &&
                        `#${data.tiktokType.hashtag}`}
                    </td>
                  </tr>
                )}
                {data?.tiktokType?.keyword != '' && (
                  <tr>
                    <td className="border-[1px] p-1 w-[100px]">Keyword</td>
                    <td className="border-[1px] p-1 w-[150px]">
                      {data?.tiktokType?.keyword != '' &&
                        `@${data.tiktokType.keyword}`}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}

        {/* {type == SOCIAL_MEDIA_TYPE.tiktok && data.tiktokType && (
          <>
            {" "}
            <p>
              {data?.tiktokType?.userAccount != ""
                ? data?.tiktokType?.userAccount
                : ""}
            </p>
            <p>
              {data?.tiktokType?.hashtag != ""
                ? `#${data?.tiktokType?.hashtag}`
                : ""}
            </p>
            <p>
              {data?.tiktokType?.keywords != ""
                ? `@${data?.tiktokType?.keywords}`
                : ""}
            </p>
          </>
        )} */}
      </div>
      <div className={`basis-2/5 ${isExpanded ? '' : 'hidden'}`}>
        <div>
          {alerts.map((alert) => (
            <div
              key={alert.id}
              className="flex flex-nowrap items-center justify-start my-1 text-xs"
            >
              <span className="inline-block p-1.5 text-primarycontainer bg-onprimarycontainer rounded-l">
                {ALERT_KEYS_VALUE[alert.type]}
              </span>
              <span className="inline-block p-1.5 text-onprimarycontainer bg-primarycontainer rounded-r">{`${
                alert.keyword
              } (${FrequencyListNames[alert.frequency]})`}</span>
            </div>
          ))}
        </div>
      </div>
      <div className={`basis-2/5 ${isExpanded ? '' : 'hidden'}`}>
        <div>
          {categories.map((cate) => (
            <div
              key={cate.category}
              className="flex flex-nowrap items-center justify-start my-1 text-xs"
            >
              <span className="inline-block p-1.5 text-ellipsis flex-nowrap text-primarycontainer bg-onprimarycontainer rounded-l">
                {cate.category}
              </span>
              <span className="inline-block p-1.5 text-ellipsis flex-nowrap text-onprimarycontainer bg-primarycontainer rounded-r">
                {cate.keyword}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

MediaRowItem.propTypes = {
  item: PropTypes.shape({
    alerts: PropTypes.array,
    categories: PropTypes.array,
    id: PropTypes.string,
    searchTerm: PropTypes.any,
    type: PropTypes.any,
  }),
  isExpanded: PropTypes.bool,
};

const CompanyCard = ({
  data,
  handleCompanyEdit,
  handleDeleteCompany,
  toggleCompanyInfo,
  viewState,
}) => {
  const [showDialog, setShowDialog] = useState(false);
  return (
    <div
      className={`shadow-lg rounded-md m-4 self-center bg-surface border max-w-[280px] overflow-hidden`}
    >
      <div className="flex justify-between items-center w-full p-2 text-base rounded-t-md border-b">
        <div className="text-sm ml-2 text-primary text-ellipsis w-[20ch] overflow-hidden">
          {data.masterSearch}
        </div>
        <div>
          <button
            className={`mx-1 invisible lg:visible text-base material-icons scale-100 hover:scale-150 pointer ${
              viewState ? 'text-primary' : 'text-outline'
            }`}
            onClick={() => toggleCompanyInfo(data, !viewState)}
            title="Show detailed Info"
          >
            {viewState ? 'notifications' : 'notifications_off'}
          </button>
          <button
            className="mx-1 text-base material-icons scale-100 hover:scale-150 pointer"
            onClick={() => handleCompanyEdit(data)}
          >
            edit
          </button>
          <button
            className="mx-1 text-base material-icons scale-100 hover:scale-150 pointer"
            onClick={() => setShowDialog(true)}
          >
            delete
          </button>
        </div>
      </div>
      <div>
        {viewState && (
          <div className="flex items-center justify-between border-b px-4 py-1">
            <div className={`h-6 w-[3ch] m-1 p-1`}>{'  '}</div>
            <div className="basis-1/5">searchTerm</div>
            <div className={`basis-2/5`}>Alerts</div>
            <div className={`basis-2/5`}>Categories</div>
          </div>
        )}
        {data &&
          data.socialInfo.map((item) => (
            <MediaRowItem
              key={item.id}
              item={item}
              data={data}
              isExpanded={viewState}
            />
          ))}
      </div>
      <Dialog
        title={DIALOG_TYPE.manageCompany.delete.title}
        body={DIALOG_TYPE.manageCompany.delete.body}
        negativeButton2={{
          text: DIALOG_TYPE.manageCompany.delete.negativeButtonText,
          callback: () => handleDeleteCompany(data),
        }}
        positiveButton1={{
          text: DIALOG_TYPE.manageCompany.delete.positiveButtonText,
          callback: () => {},
        }}
        showDialog={showDialog}
        setShowDialog={setShowDialog}
      />
    </div>
  );
};

CompanyCard.propTypes = {
  handleCompanyEdit: PropTypes.func,
  handleDeleteCompany: PropTypes.func,
  viewState: PropTypes.bool,
  data: PropTypes.shape({
    masterSearch: PropTypes.string,
    socialInfo: PropTypes.arrayOf(
      PropTypes.shape({
        alerts: PropTypes.array,
        id: PropTypes.string,
        type: PropTypes.string,
        searchTerm: PropTypes.string,
      }),
    ),
  }),
  toggleCompanyInfo: PropTypes.func.isRequired,
};

const ManageCompaniesViewGroup = ({
  context,
  isLinkedin = false,
  setShowPopUpform = () => {},
}) => {
  const {
    companies = [],
    companiesAdded,
    trackedCompaniesLimit,
  } = context?.profileInfo || {};

  // const companies = testGroupCompanyData;
  const [showSlider, setShowSlider] = useState(false);
  const [showManageCompaniesDialog, setShowManageCompaniesDialog] =
    useState(false);
  const [currentSelection, setCurrentSelection] = useState();
  const [expandedCompanies, setExpandedCompanies] = useState([]);
  const [companyCardList, setCompanyCardList] = useState([]);
  const [alertsList, setAlertsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [isAddCompany, setIsAddCompany] = useState(false);
  const { addCompany, editCompany, removeCompany } = useCompaniesCustomHook();

  console.log(companies, 'currentSelection');

  const uniqueGroups = [...new Set(companies.map((item) => item.group_name))];

  const groupedData = companies.reduce((acc, current) => {
    const group = current.group_name;
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(current);
    return acc;
  }, {});

  useEffect(() => {
    if (companies) {
      setCompanyCardList(companies);
      setExpandedCompanies([]);
    }
  }, [companies]);

  useEffect(() => {
    if (currentSelection) {
      let alerts = [];
      let categories = [];
      let tempArray;
      const { socialInfo = [] } = currentSelection || {};
      for (const k of socialInfo) {
        if (k['alerts'].length) {
          // isEditable flag = 0 - Not Edited or changes saved to backend, 1 - Edit In Progress, 2 - Edit done but not saved
          tempArray = k.alerts.map((alrt) => ({
            ...alrt,
            channel: k.type,
            newchannel: k.type,
            newtype: alrt.type,
            newkeyword: alrt.keyword,
            newfrequency: alrt.frequency,
            newfrequency: alrt.subtype,
            newthreshold: alrt.threshold,
            isEditable: false,
          }));
          alerts = [...alerts, ...tempArray];
        }
        if (k['categories']) {
          tempArray = k.categories.map((cate) => ({
            ...cate,
            channel: k.type,
            newchannel: k.type,
            newcategory: cate.category,
            newkeyword: cate.keyword,
            isEditable: false,
          }));
          categories = [...categories, ...tempArray];
        }
      }
      setAlertsList(alerts);
      setCategoriesList(categories);
    } else {
      setAlertsList([]);
      setCategoriesList([]);
    }
  }, [currentSelection]);

  const handleDeleteCompany = (companyInfo) => {
    removeCompany(companyInfo.id);
  };

  const handleCompanyEdit = (companyInfo) => {
    setCurrentSelection(companyInfo);
    setShowSlider(true);
    setSelectedId(companyInfo.id);
  };

  const handleCompanyAdd = () => {
    if (companiesAdded === context.profileInfo?.CompetitorIntelligence?.limit) {
      setShowManageCompaniesDialog('addNew');
      return;
    }
    setShowSlider(true);
    setIsAddCompany(true);
  };

  const handlePortalClose = () => {
    const { isAlertEdit } = getAlertData();
    const { isCategoriesEdit } = getCategoriesData();
    if (isAlertEdit || isCategoriesEdit) {
      setShowManageCompaniesDialog('exitUnsavedData');
    } else {
      setCurrentSelection(null);
      setShowSlider(false);
    }
  };

  const getSentimentDropAlertData = (data) => {
    const sentimentDropData = {
      alertType: ALERT_KEYS_TYPE.averageSentimentDrop,
    };
    switch (data.channel.toLocaleLowerCase()) {
      case SOCIAL_MEDIA_TYPE.twitter:
        sentimentDropData.tweetAlert = 'yes';
        sentimentDropData.tweetAverageSentimentValue = data.keyword;
        sentimentDropData.twitterSentimentDropfrequency = data.frequency;
        sentimentDropData.subtype = data.subtype;

        break;
      case SOCIAL_MEDIA_TYPE.facebook:
        sentimentDropData.facebookAlert = 'yes';
        sentimentDropData.facebookAverageSentimentValue = data.keyword;
        sentimentDropData.facebookSentimentDropfrequency = data.frequency;
        break;
      case SOCIAL_MEDIA_TYPE.reddit:
        sentimentDropData.redditAlert = 'yes';
        sentimentDropData.redditAverageSentimentValue = data.keyword;
        sentimentDropData.redditSentimentDropfrequency = data.frequency;
        break;
      case 'news':
        sentimentDropData.newsAlert = 'yes';
        sentimentDropData.newsAverageSentimentValue = data.keyword;
        sentimentDropData.newsSentimentDropfrequency = data.frequency;
        break;
      case SOCIAL_MEDIA_TYPE.instagram:
        sentimentDropData.instagramAlert = 'yes';
        sentimentDropData.instagramAverageSentimentValue = data.keyword;
        sentimentDropData.instagramSentimentDropfrequency = data.frequency;
        sentimentDropData.subtype = data.subtype;
        break;
      case SOCIAL_MEDIA_TYPE.tiktok:
        sentimentDropData.tiktokAlert = 'yes';
        sentimentDropData.tiktokAverageSentimentValue = data.keyword;
        sentimentDropData.tiktokSentimentDropfrequency = data.frequency;
        sentimentDropData.subtype = data.subtype;
        break;
      case SOCIAL_MEDIA_TYPE.youtube:
        sentimentDropData.youtubeAlert = 'yes';
        sentimentDropData.youtubeAverageSentimentValue = data.keyword;
        sentimentDropData.youtubeSentimentDropfrequency = data.frequency;
        break;
      case SOCIAL_MEDIA_TYPE.linkedin:
        sentimentDropData.linkedinAlert = 'yes';
        sentimentDropData.linkedinAverageSentimentValue = data.keyword;
        sentimentDropData.linkedinSentimentDropfrequency = data.frequency;
        break;
    }
    return sentimentDropData;
  };

  const getKeywordDetectionAlertData = (data) => {
    const keywordDetectionData = {
      alertType: ALERT_KEYS_TYPE.keywordDetection,
    };
    switch (data.channel.toLocaleLowerCase()) {
      case SOCIAL_MEDIA_TYPE.twitter:
        keywordDetectionData.tweetAlert = 'yes';
        keywordDetectionData.tweetKeyword = data.keyword;
        keywordDetectionData.twitterKeywordfrequency = data.frequency;
        break;
      case SOCIAL_MEDIA_TYPE.facebook:
        keywordDetectionData.facebookHandlerAlert = 'yes';
        keywordDetectionData.facebookKeyword = data.keyword;
        keywordDetectionData.facebookKeywordfrequency = data.frequency;
        break;
      case SOCIAL_MEDIA_TYPE.reddit:
        keywordDetectionData.redditAlert = 'yes';
        keywordDetectionData.redditKeyword = data.keyword;
        keywordDetectionData.redditKeywordfrequency = data.frequency;
        break;
      case 'news':
        keywordDetectionData.newsAlert = 'yes';
        keywordDetectionData.newsKeyword = data.keyword;
        keywordDetectionData.newsKeywordfrequency = data.frequency;
        break;
      case SOCIAL_MEDIA_TYPE.instagram:
        keywordDetectionData.instagramAlert = 'yes';
        keywordDetectionData.instagramKeyword = data.keyword;
        keywordDetectionData.instagramKeywordfrequency = data.frequency;
        keywordDetectionData.subtype = data.subtype;
        break;
      case SOCIAL_MEDIA_TYPE.tiktok:
        keywordDetectionData.tiktokAlert = 'yes';
        keywordDetectionData.tiktokKeyword = data.keyword;
        keywordDetectionData.tiktokKeywordfrequency = data.frequency;
        keywordDetectionData.subtype = data.subtype;

        break;
      case SOCIAL_MEDIA_TYPE.youtube:
        keywordDetectionData.youtubeAlert = 'yes';
        keywordDetectionData.youtubeKeyword = data.keyword;
        keywordDetectionData.youtubeKeywordfrequency = data.frequency;
        break;
      case SOCIAL_MEDIA_TYPE.linkedin:
        keywordDetectionData.linkedinAlert = 'yes';
        keywordDetectionData.linkedinKeyword = data.keyword;
        keywordDetectionData.linkedinKeywordfrequency = data.frequency;
        break;
    }
    return keywordDetectionData;
  };

  const getCountSentimentAlertData = (data) => {
    const countSentimentAlertData = {
      alertType: ALERT_KEYS_TYPE.countSentimentAlert,
    };
    switch (data.channel.toLocaleLowerCase()) {
      case SOCIAL_MEDIA_TYPE.twitter:
        countSentimentAlertData.tweetAlert = 'yes';
        countSentimentAlertData.twitterCountSentiment = data.keyword;
        countSentimentAlertData.twitterCountfrequency = data.frequency;
        countSentimentAlertData.threshold = data.threshold;
        break;
      case SOCIAL_MEDIA_TYPE.facebook:
        countSentimentAlertData.facebookAlert = 'yes';
        countSentimentAlertData.facebookCountSentiment = data.keyword;
        countSentimentAlertData.facebookCountfrequency = data.frequency;
        countSentimentAlertData.threshold = data.threshold;

        break;
      case SOCIAL_MEDIA_TYPE.reddit:
        countSentimentAlertData.redditAlert = 'yes';
        countSentimentAlertData.redditCountSentiment = data.keyword;
        countSentimentAlertData.redditCountfrequency = data.frequency;
        countSentimentAlertData.threshold = data.threshold;

        break;
      case 'news':
        countSentimentAlertData.newsAlert = 'yes';
        countSentimentAlertData.newsCountSentiment = data.keyword;
        countSentimentAlertData.newsCountfrequency = data.frequency;
        countSentimentAlertData.threshold = data.threshold;

        break;
      case SOCIAL_MEDIA_TYPE.instagram:
        countSentimentAlertData.instagramAlert = 'yes';
        countSentimentAlertData.instagramCountSentiment = data.keyword;
        countSentimentAlertData.instagramCountfrequency = data.frequency;
        countSentimentAlertData.threshold = data.threshold;
        countSentimentAlertData.subtype = data.subtype;

        break;
      case SOCIAL_MEDIA_TYPE.tiktok:
        countSentimentAlertData.tiktokAlert = 'yes';
        countSentimentAlertData.tiktokCountSentiment = data.keyword;
        countSentimentAlertData.tiktokCountfrequency = data.frequency;
        countSentimentAlertData.threshold = data.threshold;
        countSentimentAlertData.subtype = data.subtype;

        break;
      case SOCIAL_MEDIA_TYPE.youtube:
        countSentimentAlertData.youtubeAlert = 'yes';
        countSentimentAlertData.youtubeCountSentiment = data.keyword;
        countSentimentAlertData.youtubeCountfrequency = data.frequency;
        countSentimentAlertData.threshold = data.threshold;

        break;
      case SOCIAL_MEDIA_TYPE.linkedin:
        countSentimentAlertData.linkedinAlert = 'yes';
        countSentimentAlertData.linkedinCountSentiment = data.keyword;
        countSentimentAlertData.linkedinCountfrequency = data.frequency;
        countSentimentAlertData.threshold = data.threshold;

        break;
    }
    return countSentimentAlertData;
  };

  const getAlertData = () => {
    const alertsData = [];
    let isAlertEdit = false;
    for (let i = 0; i < alertsList.length; i++) {
      if (alertsList[i].isEditable) {
        isAlertEdit = true;
        setShowManageCompaniesDialog('saveData');
        break;
      }
      if (alertsList[i].type === ALERT_KEYS_TYPE.averageSentimentDrop) {
        alertsData.push(getSentimentDropAlertData(alertsList[i]));
      } else if (alertsList[i].type === ALERT_KEYS_TYPE.keywordDetection) {
        alertsData.push(getKeywordDetectionAlertData(alertsList[i]));
      } else if (alertsList[i].type === ALERT_KEYS_TYPE.countSentimentAlert) {
        alertsData.push(getCountSentimentAlertData(alertsList[i]));
      }
    }
    return { isAlertEdit, alertsData };
  };

  const getCategoriesData = () => {
    const categoriesData = [];
    let isCategoriesEdit = false;
    for (let i = 0; i < categoriesList.length; i++) {
      if (categoriesList[i].isEditable) {
        isCategoriesEdit = true;
        setShowManageCompaniesDialog('saveData');
        break;
      }
      switch (categoriesList[i].channel.toLocaleLowerCase()) {
        case SOCIAL_MEDIA_TYPE.twitter:
          categoriesData.push({
            connector: 'twitterHandler',
            categoryName: categoriesList[i].category,
            categoryKeywords: categoriesList[i].keyword,
          });
          break;
        case SOCIAL_MEDIA_TYPE.facebook:
          categoriesData.push({
            connector: 'facebookHandler',
            categoryName: categoriesList[i].category,
            categoryKeywords: categoriesList[i].keyword,
          });
          break;
        case SOCIAL_MEDIA_TYPE.youtube:
          categoriesData.push({
            connector: 'youtubeHandler',
            categoryName: categoriesList[i].category,
            categoryKeywords: categoriesList[i].keyword,
          });
          break;
        case SOCIAL_MEDIA_TYPE.tiktok:
          categoriesData.push({
            connector: 'tiktokHandler',
            categoryName: categoriesList[i].category,
            categoryKeywords: categoriesList[i].keyword,
          });
          break;
        case SOCIAL_MEDIA_TYPE.instagram:
          categoriesData.push({
            connector: 'instagramHandler',
            categoryName: categoriesList[i].category,
            categoryKeywords: categoriesList[i].keyword,
          });
          break;
        case 'news':
          categoriesData.push({
            connector: 'newsHandler',
            categoryName: categoriesList[i].category,
            categoryKeywords: categoriesList[i].keyword,
          });
          break;
        case SOCIAL_MEDIA_TYPE.reddit:
          categoriesData.push({
            connector: 'redditHandler',
            categoryName: categoriesList[i].category,
            categoryKeywords: categoriesList[i].keyword,
          });
          break;
        case SOCIAL_MEDIA_TYPE.linkedin:
          categoriesData.push({
            connector: 'linkedinHandler',
            categoryName: categoriesList[i].category,
            categoryKeywords: categoriesList[i].keyword,
          });
          break;
        // case SOCIAL_MEDIA_TYPE.trustpilot:
        //   categoriesData.push({
        //     connector: "trustPilotcompanyName",
        //     categoryName: categoriesList[i].category,
        //     categoryKeywords: categoriesList[i].keyword,
        //   });
      }
    }
    return { isCategoriesEdit, categoriesData };
  };

  const saveCompanyInfo = (data, type) => {
    console.log(data, 'datadatadata');
    const { isAlertEdit, alertsData } = getAlertData();
    const { isCategoriesEdit, categoriesData } = getCategoriesData();
    if (
      type === PROFILE_OPERATION_TYPE.editCompany &&
      !isAlertEdit &&
      !isCategoriesEdit
    ) {
      editCompany(
        {
          keyword: data.formData.mainSearchTerm || '',
          facebook_searchname: data.channels.facebook || '',
          trustPilotcompanyName: data.channels.trustPilotcompanyName || '',
          Facebook_screen_name: data.channels.facebookDisplayName || '',
          twitter_searchname: data.channels.twitter || '',
          instagram_searchname: data.channels.instagram || '',
          Reddit: data.channels.reddit || '',
          youtube_channel: data.channels.youtube || '',
          news_keyword: data.channels.news,
          tiktok_searchname: data.channels.tiktok,
          linkedin_publicpage_url: data.channels.linkedin_publicpage_url,
          linkedin_publicpage: data.channels.linkedin_publicpage,
          lang: data.formData.lang || '',
          option: data.formData.youtubeOption,
          tiktok_type: data.formData.tiktokOption,
          ig_type: data.formData.instagramOption,
          alertsData,
          categories: categoriesData,
          tiktokType: data.formData.tiktokType,
          instagramType: data.formData.instagramType,
          youtubeType: data.formData.youtubeType,
          group_name: data.group_name,
        },
        selectedId,
      );
      setCurrentSelection(null);
      setShowSlider(false);
    } else if (
      type === PROFILE_OPERATION_TYPE.addCompany &&
      !isAlertEdit &&
      !isCategoriesEdit
    ) {
      addCompany({
        keyword: data.formData.mainSearchTerm || '',
        facebook_searchname: data.channels.facebook || '',
        Facebook_screen_name: data.channels.facebookDisplayName || '',
        twitter_searchname: data.channels.twitter || '',
        instagram_searchname: data.channels.instagram || '',
        trustPilotcompanyName:
          data.channels.trustrustPilotcompanyNametpilot || '',
        Reddit: data.channels.reddit || '',
        youtube_channel: data.channels.youtube || '',
        news_keyword: data.channels.news,
        tiktok_searchname: data.channels.tiktok,
        linkedin_publicpage_url: data.channels.linkedin_publicpage_url,
        linkedin_publicpage: data.channels.linkedin_publicpage,
        lang: data.formData.lang || '',
        option: data.formData.youtubeOption,
        tiktok_type: data.formData.tiktokOption,
        ig_type: data.formData.instagramOption,
        alertsData,
        categories: categoriesData,
        tiktokType: data.formData.tiktokType,
        instagramType: data.formData.instagramType,
        youtubeType: data.formData.youtubeType,
        group_name: data.group_name,
      });
      setIsAddCompany(false);
      setCurrentSelection(null);
      setShowSlider(false);
    }
  };

  const toggleCompanyInfo = (item, isExpanded) => {
    if (isExpanded) {
      setExpandedCompanies([...expandedCompanies, item]);
      setCompanyCardList(companyCardList.filter((card) => card.id !== item.id));
    } else {
      setExpandedCompanies(
        expandedCompanies.filter((card) => card.id !== item.id),
      );
      setCompanyCardList([...companyCardList, item]);
    }
  };

  useEffect(() => {
    if (isLinkedin) {
      setShowPopUpform(true);
    }
  }, []);

  return (
    <>
      {!isLinkedin && (
        <div>
          {!isLinkedin && (
            <div className="flex flex-wrap">
              {/* {companyCardList.map((company) => (
                <>
                  <span>{company?.group_name}</span>
                  <CompanyCard
                    key={company.id}
                    data={company}
                    handleCompanyEdit={handleCompanyEdit}
                    handleDeleteCompany={handleDeleteCompany}
                    toggleCompanyInfo={toggleCompanyInfo}
                    viewState={false}
                  />
                </>
              ))} */}

              <div>
                {Object.keys(groupedData).map((group) => (
                  <div key={group} className="flex flex-wrap">
                    <div className="w-full px-2 py-3 text-base bg-surface border-b text-onsurface rounded-b-lg">
                      <div class="flex justify-between mx-2">
                        <h5 class="">
                          <span class="font-semibold">
                            {group != 'undefined' ? group : 'General Group'}
                          </span>
                        </h5>
                        {/* <span class="material-icons">expand_less</span> */}
                      </div>
                    </div>
                    {/* <h2 className="w-full px-2 py-3 text-base bg-surface border-b text-onsurface rounded-b-lg">{group}</h2> */}
                    {groupedData[group].map((company) => (
                      <CompanyCard
                        key={company.id}
                        data={company}
                        handleCompanyEdit={handleCompanyEdit}
                        handleDeleteCompany={handleDeleteCompany}
                        toggleCompanyInfo={toggleCompanyInfo}
                        viewState={false}
                      />
                    ))}
                  </div>
                ))}
              </div>
            </div>
          )}
          {expandedCompanies.map((company) => (
            <div key={company.id} className="">
              <CompanyCard
                data={company}
                handleCompanyEdit={handleCompanyEdit}
                handleDeleteCompany={handleDeleteCompany}
                toggleCompanyInfo={toggleCompanyInfo}
                viewState={true}
              />
            </div>
          ))}
        </div>
      )}
      {!isLinkedin && (
        <div className="m-6 mx-auto w-[20ch]">
          <button
            className={`p-2 m-2 ${
              (companiesAdded ===
                context?.profileInfo?.CompetitorIntelligence?.limit &&
                'bg-outline') ||
              'bg-primary'
            } flex items-center text-onprimary rounded-sm`}
            onClick={handleCompanyAdd}
          >
            <span className="material-icons">add</span>
            <span>Add New</span>
          </button>
        </div>
      )}
      {isLinkedin && (
        <div className="flex items-center justify-start ">
          <button
            className="py-0.5 px-2 hover:ring-secondary hover:ring-1 shadow border rounded bg-onprimary  flex items-center text-secondary hover:bg-secondary hover:text-onprimary"
            onClick={handleCompanyAdd}
          >
            <span class="material-icons">add</span>
            <span>Add</span>
          </button>

          {/* <button
            className="rounded-full p-1 mb-2 flex justify-flex-start items-center ring-1 ring-primary text-primary transition ease-in duration-200 
        text-center text-base hover:bg-primary hover:text-onsecondary hover:shadow-md"
            onClick={handleCompanyAdd}
          >
            <svg
              width={20}
              height={20}
              fill="none"
              strokeWidth={1.5}
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          </button> */}
        </div>
      )}
      {showSlider && (
        <AddEditCompabyView
          data={currentSelection}
          handleSubmit={saveCompanyInfo}
          handlePortalClose={handlePortalClose}
          saveCompaniesOperationType={
            (isAddCompany && PROFILE_OPERATION_TYPE.addCompany) ||
            PROFILE_OPERATION_TYPE.editCompany
          }
          alertsList={alertsList}
          categoriesList={categoriesList}
          setAlertsList={setAlertsList}
          setCategoriesList={setCategoriesList}
          isEdit={true}
        />
      )}
      <Dialog
        title={DIALOG_TYPE.manageCompany[showManageCompaniesDialog]?.title}
        body={DIALOG_TYPE.manageCompany[showManageCompaniesDialog]?.body}
        positiveButton1={{
          text: DIALOG_TYPE.manageCompany[showManageCompaniesDialog]
            ?.positiveButtonText,
          callback: () => {
            setShowManageCompaniesDialog(false);
          },
        }}
        negativeButton2={{
          text: DIALOG_TYPE.manageCompany[showManageCompaniesDialog]
            ?.negativeButtonText,
          callback: () => {
            if (showManageCompaniesDialog === 'exitUnsavedData') {
              setCurrentSelection(null);
              setShowSlider(false);
            }
          },
        }}
        showDialog={showManageCompaniesDialog}
        setShowDialog={setShowManageCompaniesDialog}
      />
    </>
  );
};

ManageCompaniesViewGroup.propTypes = {
  context: PropTypes.shape({
    profileInfo: PropTypes.shape({
      companies: PropTypes.arrayOf(
        PropTypes.shape({ masterSearch: PropTypes.string }),
      ),
    }),
  }),
};

export default React.memo(ManageCompaniesViewGroup);
