import { createContext } from "react";
import { profileOperationHandler } from "../../services/userProfile/userProfile.service";

export const UserProfileContext = createContext({});

export const fetchUserProfile = (user, callbackFn, profileOperationType) =>
  profileOperationHandler({
    params: user,
    callbackFn: callbackFn,
    profileOperationType: profileOperationType,
  });

export const updateUserProfile = (data, callbackFn, profileOperationType) =>
  profileOperationHandler({ data, callbackFn, profileOperationType });

export const saveSocialMediaToken = (
  data,
  callbackFn,
  profileOperationType,
  socialMediaType
) =>
  profileOperationHandler({
    data,
    callbackFn,
    profileOperationType,
    socialMediaType,
  });
