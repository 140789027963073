import { isValidArray, testStringValdiity } from '../../common/utils';

import { searchHandlers } from '../../services/InsightsDashboard';
import { useState } from 'react';

const useSearchTermCustomHook = () => {
  const [searchTermLoader, setSearchTermLoader] = useState(false);
  const [searchTermResponse, setSearchTermResponse] = useState([]);

  const callbackFn = (value) => {
    // TODO:: check tiktok search api response for null value
    if (!value) {
      setSearchTermLoader(false);
      return;
    }
    setSearchTermResponse((isValidArray(value, true) && value) || []);
    setSearchTermLoader(false);
  };
  const handleSearch = (searchQuery, socialMediaType, option, isActive) => {
    console.log(searchQuery, socialMediaType, option, isActive, 'searchQuery');
    if (testStringValdiity(searchQuery) && isActive) {
      setSearchTermLoader(true);
      setSearchTermResponse([]);
      if (socialMediaType === 'youtube') {
        searchHandlers(socialMediaType, option, searchQuery, callbackFn);
      } else if (socialMediaType === 'tiktok') {
        searchHandlers(socialMediaType, option, searchQuery, callbackFn);
      } else if (socialMediaType === 'twitter') {
        searchHandlers(socialMediaType, option, searchQuery, callbackFn);
      } else if (socialMediaType === 'instagram') {
        searchHandlers(socialMediaType, option, searchQuery, callbackFn);
      } else {
        searchHandlers(socialMediaType, false, searchQuery, callbackFn);
      }
    }
  };

  return {
    searchTermLoader,
    searchTermResponse,
    handleSearch,
  };
};

export default useSearchTermCustomHook;
