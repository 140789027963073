import { TYPE } from "../common/constants";
import { ACTIVE_INFLUENCERS_TAB_DATA, TAB_DATA } from "../common/metadata";
import { post, get } from "../services/common/axiosInstance";
import {
  AI_CONVO_GET_COMPANY,
  AI_CONVO_History,
  AI_CONVO_History_SHOW,
  AI_CONVO_USER_History,
  AI_CONVO_USER_History_By_Chart,
} from "../services/common/endpoints";

/**
 *
 * @param {Object} body
 * @returns
 */
export async function ConvoHistory(body) {
  if (typeof body !== "object" || !body)
    return { message: "request body is required." };

  const response = await post(AI_CONVO_History, {
    data: body,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response;
}

export async function ConvoHistoryDisplay(body) {
  if (typeof body !== "object" || !body)
    return { message: "request body is required." };

  const response = await get(AI_CONVO_History_SHOW, {
    params: body,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response;
}


export async function ConvoHistoryDisplayByChart(body) {
  if (typeof body !== "object" || !body)
    return { message: "request body is required." };

  const response = await get(AI_CONVO_USER_History_By_Chart, {
    params: body,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
}


export async function UserHistory(body) {
    if (typeof body !== "object" || !body)
    return { message: "request body is required." };

  const response = await get(AI_CONVO_USER_History,{
      params: body,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response;
}


export async function getCompany(source) {
  const response = await get(`${AI_CONVO_GET_COMPANY}&source=${source}`,{
  });

  return response;
}

// export const CHARTS_RESPONSE = {
//   [TAB_DATA.activeFollowers]: TYPE.activefollowers,
//   [TAB_DATA.aspectSentiment]: TYPE.aspectsentiment,
//   [TAB_DATA.aspectSentimentTrend]: TYPE.aspectsentiment,
//   [TAB_DATA.followersTimeline]: 'timelinePageLikes',
//   [TAB_DATA.hashtagTrend]: TYPE.hashtags,
//   [TAB_DATA.mentionsTimeline]: TYPE.posts,
//   [TAB_DATA.sentimentByCategory]: TYPE.categorySentiment,
//   [TAB_DATA.mentionsTimelineSummary]: TYPE.,
//   [TAB_DATA.mentionsTimelineHistory]: TYPE.activefollowers,
//   [TAB_DATA.engagementEntries]: TYPE.activefollowers,
//   [TAB_DATA.sentimentTrend]: 'timeline',
//   [TAB_DATA.engagementPercentageTrend]: TYPE.activefollowers,
//   [TAB_DATA.stats]: TYPE.activefollowers,
//   [TAB_DATA.topCategories]: TYPE.activefollowers,
//   [TAB_DATA.imageAnalysisRanking]: TYPE.activefollowers,
//   [TAB_DATA.imageAnalysisTrend]: TYPE.activefollowers,
//   [TAB_DATA.topEntities]: TYPE.activefollowers,
//   [TAB_DATA.topEntitiesTrend]: TYPE.activefollowers,
//   [TAB_DATA.topHashtags]: TYPE.activefollowers,
//   [TAB_DATA.topInfluencersRanking]: TYPE.activefollowers,
//   [TAB_DATA.topFollowers]: TYPE.activefollowers,
//   [TAB_DATA.userActiveTimeline]: TYPE.activefollowers,
//   [TAB_DATA.engagementStats]: TYPE.activefollowers,
//   [TAB_DATA.userActivity]: TYPE.activefollowers,
//   [TAB_DATA.emotionInsights]: TYPE.activefollowers,
//   [TAB_DATA.followersDemographic]: TYPE.activefollowers,
//   [TAB_DATA.topInfluencers]: TYPE.activefollowers,
//   [TAB_DATA.topComments]: TYPE.activefollowers,
//   [ACTIVE_INFLUENCERS_TAB_DATA.followersReach]: TYPE,
//   [ACTIVE_INFLUENCERS_TAB_DATA.influencerStats]: TYPE,
//   [ACTIVE_INFLUENCERS_TAB_DATA.followersReach]: TYPE,
//   [ACTIVE_INFLUENCERS_TAB_DATA.followersTimeline]: TYPE,
//   [ACTIVE_INFLUENCERS_TAB_DATA.followersTrend]: TYPE,
// };
