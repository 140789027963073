import {
  ALERT_KEYS_TYPE,
  ALERT_KEYS_VALUE,
  FrequencyListNames,
} from '../../../services/userProfile/userProfile.transformer';
import {
  DIALOG_TYPE,
  NOTIFICATION_ALERT_TEXT,
  SOCIAL_MEDIA_METADATA,
} from '../../../common/metadata';
import React, { useState } from 'react';

import Button from '../../Components/Button';
import Dialog from '../../../common/Dialog';
import DropdownList from '../../Components/Dropdown';
import PropTypes from 'prop-types';
import { SOCIAL_MEDIA_NAMES } from '../../../common/constants';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

// const AlertsTableColumns = [
//   { label: "Media Channel" },
//   {
//     label: "Alert Type",
//     propertyAccessor: "type",
//   },
//   {
//     label: "Keyword",
//     placeholder: "Keyword to be Alerted?",
//     propertyAccessor: "type",
//   },
//   { label: "Frequency", propertyAccessor: "type" },
//   { label: "Actions", propertyAccessor: "type" },
// ];

const FrequencyList = [
  { label: '', value: '' },
  { label: 'Hourly', value: 'hourly' },
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
];

const AlertTypes = [
  { label: '', value: '' },
  {
    label: ALERT_KEYS_VALUE.keywordDetectionAlert,
    value: ALERT_KEYS_TYPE.keywordDetection,
  },
  {
    label: ALERT_KEYS_VALUE.averageSentimentDropAlert,
    value: ALERT_KEYS_TYPE.averageSentimentDrop,
  },
  {
    label: ALERT_KEYS_VALUE.countSentimentAlert,
    value: ALERT_KEYS_TYPE.countSentimentAlert,
  },
];

// const CategoriesTableColumns = [
//   { label: "Media Channel" },
//   {
//     label: "Name",
//     placeholder: "Name",
//   },
//   {
//     label: "Keyword(s)",
//     placeholder: "Keyword(s)",
//   },
//   { label: "Actions" },
// ];

const SOCIALMEDIALIST = [
  { label: '', value: '' },
  ...SOCIAL_MEDIA_METADATA.map((media) => ({
    label: media.label,
    value: media.label.toLocaleLowerCase(),
  })),
  { label: 'Linkedin Public Page', value: 'linkedinPublicPageHandler' },
];

const CompanyNotificationsTable = ({
  alertsList,
  categoriesList,
  setAlertsList,
  setCategoriesList,
}) => {
  const [informationDialog, setInformationDialog] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState('');

  console.log(categoriesList, 'categoriesList');

  const updateAlertRecord = (id, field, value) => {
    setAlertsList(
      alertsList.map((item) => {
        if (item.id === id) {
          if (field === 'isEditable') {
            return value
              ? { ...item, isEditable: value }
              : {
                  ...item,
                  newchannel: item.channel,
                  newtype: item.type,
                  newkeyword: item.keyword,
                  newfrequency: item.frequency,
                  newsubtype: item.subtype,
                  isEditable: value,
                };
          }
          return { ...item, [`new${field}`]: value };
        }
        return item;
      }),
    );
  };

  const updateCategoryRecord = (id, field, value) => {
    setCategoriesList(
      categoriesList.map((item) => {
        if (item.id === id) {
          if (field === 'isEditable') {
            return value
              ? { ...item, isEditable: value }
              : {
                  ...item,
                  newchannel: item.channel,
                  newcategory: item.category,
                  newkeyword: item.keyword,
                  isEditable: value,
                };
          }
          return { ...item, [`new${field}`]: value };
        }
        return item;
      }),
    );
  };

  const createNewAlert = () => {
    setAlertsList([
      ...alertsList,
      {
        id: uuidv4(),
        channel: '',
        type: '',
        keyword: '',
        frequency: '',
        subtype: '',
        active: true,
        threshold: '',
        newchannel: '',
        newtype: '',
        newkeyword: '',
        newsubtype: '',
        newfrequency: '',
        newactive: true,
        newthreshold: '', // applicable for countsentiment
        isEditable: true,
      },
    ]);
  };

  const createNewCategory = () => {
    setCategoriesList([
      ...categoriesList,
      {
        id: uuidv4(),
        channel: '',
        category: '',
        keyword: '',
        newchannel: '',
        newcategory: '',
        newkeyword: '',
        isEditable: true,
      },
    ]);
  };

  const saveAlert = (record) => {
    if (
      !record ||
      !record.newchannel ||
      !record.newtype ||
      !record.newsubtype ||
      !record.newkeyword ||
      !record.newfrequency
    ) {
      toast.error(NOTIFICATION_ALERT_TEXT.requiredFields.companyAlert, {
        toastId: 'saveAlert',
      });
      return;
    }
    setAlertsList(
      alertsList.map((item) => {
        if (item.id === record.id) {
          return {
            ...item,
            channel: item.newchannel,
            type: item.newtype,
            subtype: item.newsubtype,
            keyword: item.newkeyword,
            frequency: item.newfrequency,
            threshold: item.newthreshold,
            isEditable: false,
          };
        }
        return item;
      }),
    );
    setSelectedMedia('');
  };

  const saveCategory = (record) => {
    // const recordToSave = { channel: record.newchannel, category: record.newcategory, keyword: record.newkeyword };
    if (
      !record ||
      !record.newchannel ||
      !record.newcategory ||
      !record.newkeyword
    ) {
      toast.error(NOTIFICATION_ALERT_TEXT.requiredFields.companyCategories, {
        toastId: 'saveCategory',
      });
      return;
    }
    setCategoriesList(
      categoriesList.map((item) => {
        if (item.id === record.id) {
          return {
            ...item,
            channel: item.newchannel,
            category: item.newcategory,
            keyword: item.newkeyword,
            isEditable: false,
          };
        }
        return item;
      }),
    );
  };

  const deleteAlert = (record) => {
    // const recordToSave = { channel: record.newchannel, type: record.newtype, keyword: record.newkeyword, frequency: record.newfrequency };
    // TODO Make API call to save recordToSave object
    setAlertsList(alertsList.filter((item) => item.id !== record.id));
  };

  const deleteCategory = (record) => {
    // const recordToSave = { channel: record.newchannel, category: record.newcategory, keyword: record.newkeyword };
    // TODO Make API call to save recordToSave object
    setCategoriesList(categoriesList.filter((item) => item.id !== record.id));
  };

  const showInformation = (e) => {
    setInformationDialog(e.target.id);
  };

  const typeArr = {
    '': [
      {
        label: '',
        value: '',
      },
    ],
    instagram: [
      {
        label: '',
        value: '',
      },
      {
        label: 'User account',
        value: 'manual_users',
      },
      {
        label: 'Tagged',
        value: 'manual_tags',
      },
      {
        label: 'Hashatg',
        value: 'manual_hashtags',
      },
    ],
    tiktok: [
      {
        label: '',
        value: '',
      },
      {
        label: 'User account',
        value: 'userposts',
      },
      {
        label: 'Hashtag',
        value: 'userhashtags',
      },
      {
        label: 'Keyword',
        value: 'keywordposts',
      },
    ],
    facebook: [
      {
        label: 'User account',
        value: 'useraccount',
      },
    ],
    twitter: [
      {
        label: 'User account',
        value: 'useraccount',
      },
    ],
    news: [
      {
        label: 'User account',
        value: 'useraccount',
      },
    ],
    reddit: [
      {
        label: 'User account',
        value: 'useraccount',
      },
    ],
    linkedin: [
      {
        label: 'User account',
        value: 'useraccount',
      },
    ],
    youtube: [
      {
        label: 'User account',
        value: 'useraccount',
      },
    ],
  };

  const getTYpe = () => {
    return typeArr[selectedMedia];
  };

  return (
    <div className="m-6">
      <div className="my-1 border-outline border rounded p-4">
        <h6 className="p-2">
          <span className="font-bold">Configured Alerts </span>
          <span id="alert" className="text-sm" onClick={showInformation}>
            (Click here to know how to enter data)
          </span>
        </h6>
        <Button
          icon="add"
          label="New Alert"
          type="primary"
          onClick={createNewAlert}
        />
        {alertsList.length ? (
          <React.Fragment>
            <div className="border font-bold text-md flex flex-nowrap items-center justify-center">
              <div className="p-1 flex-1 basis-1/6 border-l">
                {'Channel'}
                <abbr
                  title="required"
                  className="text-sm text-red-500 no-underline"
                >
                  *
                </abbr>
              </div>
              {/* 
              <div className="p-1 flex-1 basis-1/6 border-l">
                <DropdownList
                  // onChange={(e) =>
                  //   updateCategoryRecord(
                  //     record.id,
                  //     "category",
                  //     e.target.value
                  //   )
                  // }
                  // value={record.newcategory}
                  options={getTYpe()}
                  isRequired={true}
                  // inValid={!record.newcategory.length}
                />
              </div> */}

              <div className="p-1 flex-1 basis-2/6 border-l">
                {'Sub Type'}
                <abbr
                  title="required"
                  className="text-sm text-red-500 no-underline"
                >
                  *
                </abbr>
              </div>
              <div className="p-1 flex-1 basis-1/6 border-l">
                {'Type'}
                <abbr
                  title="required"
                  className="text-sm text-red-500 no-underline"
                >
                  *
                </abbr>
              </div>
              <div className="p-1 flex-1 basis-2/6 border-l">
                {'Keyword(s) / Values'}
                <abbr
                  title="required"
                  className="text-sm text-red-500 no-underline"
                >
                  *
                </abbr>
              </div>
              <div className="p-1 flex-1 basis-1/6 border-l">
                {'Frequency'}
                <abbr
                  title="required"
                  className="text-sm text-red-500 no-underline"
                >
                  *
                </abbr>
              </div>
              <div className="flex-1 basis-1/6 border-l border-r">
                {'Actions'}
              </div>
            </div>
            {alertsList.map((record) => (
              <div
                key={record.id}
                className="border-l border-b flex flex-nowrap items-center justify-center"
              >
                {/* channel drop down */}
                <div className="p-1 flex-1 basis-1/6 border-l">
                  {record.isEditable ? (
                    <DropdownList
                      onChange={(e) => {
                        updateAlertRecord(record.id, 'channel', e.target.value);
                        setSelectedMedia(e.target.value);
                      }}
                      value={record.newchannel}
                      options={SOCIALMEDIALIST}
                      isRequired={true}
                      inValid={!record.newchannel.length}
                    />
                  ) : (
                    SOCIAL_MEDIA_NAMES[record.newchannel]
                  )}
                </div>
                {/* type drop down */}

                {/* subtype */}
                <div className="p-1 flex-1 basis-1/6 border-l">
                  {record.isEditable ? (
                    <DropdownList
                      onChange={(e) =>
                        updateAlertRecord(record.id, 'subtype', e.target.value)
                      }
                      value={record.newsubtype}
                      options={getTYpe()}
                      isRequired={true}
                      // inValid={!record.newcategory.length}
                    />
                  ) : (
                    record.newsubtype
                  )}{' '}
                </div>
                <div className="p-1 flex-1 basis-1/6 border-l">
                  {record.isEditable ? (
                    <DropdownList
                      onChange={(e) =>
                        updateAlertRecord(record.id, 'type', e.target.value)
                      }
                      value={record.newtype}
                      options={AlertTypes}
                      isRequired={true}
                      inValid={!record.newtype.length}
                    />
                  ) : (
                    (record.newtype && ALERT_KEYS_VALUE[record.newtype]) || ''
                  )}
                </div>
                {/* keyword drop down */}
                <div className="p-1 flex-1 basis-1/6 border-l">
                  {record.isEditable ? (
                    <input
                      className={`${
                        !record.newkeyword.length ? 'border-red-400' : ''
                      }`}
                      type="text"
                      onChange={(e) =>
                        updateAlertRecord(record.id, 'keyword', e.target.value)
                      }
                      value={record.newkeyword}
                    />
                  ) : (
                    record.newkeyword
                  )}
                </div>
                <div className="p-1 flex-1 basis-1/6 border-l">
                  {record.newtype === ALERT_KEYS_TYPE.countSentimentAlert && (
                    <>
                      <label className="block mb-2 text-sm font-medium text-gray-900">
                        Sentiment Threshold: {record.newthreshold}
                      </label>
                      <input
                        type="range"
                        className="form-range appearance-none h-2 p-1 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                        id="customRange1"
                        min="-1"
                        max="1"
                        step=".1"
                        value={record.newthreshold || '0'}
                        onChange={(e) =>
                          updateAlertRecord(
                            record.id,
                            'threshold',
                            e.target.value,
                          )
                        }
                        disabled={!record.isEditable}
                      />
                    </>
                  )}
                </div>
                {/* frequency drop down */}
                <div className="p-1 flex-1 basis-1/6 border-l">
                  {record.isEditable ? (
                    <DropdownList
                      onChange={(e) =>
                        updateAlertRecord(
                          record.id,
                          'frequency',
                          e.target.value,
                        )
                      }
                      value={record.newfrequency}
                      options={FrequencyList}
                      isRequired={true}
                      inValid={!record.newfrequency.length}
                    />
                  ) : (
                    FrequencyListNames[record.newfrequency]
                  )}
                </div>
                <div className="flex-1 basis-1/6 border-l border-r">
                  {record.isEditable ? (
                    <React.Fragment>
                      <Button
                        icon="save"
                        type="primary"
                        variant="filled"
                        onClick={() => saveAlert(record)}
                      />
                      <Button
                        icon="cancel"
                        type="primary"
                        variant="outline"
                        onClick={() => {
                          if (!record.channel.length) {
                            deleteAlert(record);
                          } else {
                            updateAlertRecord(record.id, 'isEditable', false);
                          }
                        }}
                        classes="text-error border-error"
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Button
                        icon="edit"
                        type="primary"
                        variant="filled"
                        onClick={() =>
                          updateAlertRecord(record.id, 'isEditable', true)
                        }
                      />
                      <Button
                        icon="delete"
                        type="primary"
                        variant="outline"
                        onClick={() => deleteAlert(record)}
                        classes="text-error border-error"
                      />
                    </React.Fragment>
                  )}
                </div>
              </div>
            ))}
          </React.Fragment>
        ) : (
          <div className="m-4 p-1">No alerts configured yet.</div>
        )}
      </div>
      <div className="my-1 p-4 border-outline border rounded">
        <h6 className="p-2">
          <span className="font-bold">Mapped Categories</span>
          <span id="categories" className="text-sm" onClick={showInformation}>
            (Click here to know how to enter data)
          </span>
        </h6>
        <Button
          icon="add"
          label="New Category"
          type="primary"
          onClick={createNewCategory}
        />
        {categoriesList.length ? (
          <React.Fragment>
            <div className="border font-bold text-md flex flex-nowrap items-center justify-center">
              <div className="p-1 flex-1 basis-1/6 border-l">
                {'Channel'}
                <abbr
                  title="required"
                  className="text-sm text-red-500 no-underline"
                >
                  *
                </abbr>
              </div>
              <div className="p-1 flex-1 basis-2/6 border-l">
                {'Category'}
                <abbr
                  title="required"
                  className="text-sm text-red-500 no-underline"
                >
                  *
                </abbr>
              </div>
              <div className="p-1 flex-1 basis-2/6 border-l">
                {'Keyword(s)'}
                <abbr
                  title="required"
                  className="text-sm text-red-500 no-underline"
                >
                  *
                </abbr>
              </div>
              <div className="flex-1 basis-1/6 border-l border-r">
                {'Actions'}
              </div>
            </div>
            {categoriesList.map((record) => (
              <div
                key={record.id}
                className="border-b border-l flex flex-nowrap items-center justify-center"
              >
                <div className="p-1 flex-1 basis-1/6 border-l">
                  {record.isEditable ? (
                    <DropdownList
                      onChange={(e) =>
                        updateCategoryRecord(
                          record.id,
                          'channel',
                          e.target.value,
                        )
                      }
                      value={record.newchannel}
                      options={SOCIALMEDIALIST}
                      isRequired={true}
                      inValid={!record.newchannel.length}
                    />
                  ) : (
                    SOCIAL_MEDIA_NAMES[record.newchannel]
                  )}
                </div>
                <div className="p-1 flex-1 basis-2/6 border-l">
                  {record.isEditable ? (
                    <input
                      className={`${
                        !record.newcategory.length ? 'border-red-400' : ''
                      }`}
                      type="text"
                      onChange={(e) =>
                        updateCategoryRecord(
                          record.id,
                          'category',
                          e.target.value,
                        )
                      }
                      value={record.newcategory}
                      isRequired={true}
                    />
                  ) : (
                    record.newcategory
                  )}{' '}
                </div>
                <div className="p-1 flex-1 basis-2/6 border-l">
                  {record.isEditable ? (
                    <input
                      className={`${
                        !record.newkeyword.length ? 'border-red-400' : ''
                      }`}
                      type="text"
                      onChange={(e) =>
                        updateCategoryRecord(
                          record.id,
                          'keyword',
                          e.target.value,
                        )
                      }
                      value={record.newkeyword}
                      isRequired={true}
                    />
                  ) : (
                    record.newkeyword
                  )}{' '}
                </div>
                <div className="flex-1 basis-1/6 border-l border-r">
                  {record.isEditable ? (
                    <React.Fragment>
                      <Button
                        icon="save"
                        type="primary"
                        variant="filled"
                        onClick={() => saveCategory(record)}
                      />
                      <Button
                        icon="cancel"
                        type="primary"
                        variant="outline"
                        onClick={() => {
                          if (!record.channel.length) {
                            deleteCategory(record);
                          } else {
                            updateCategoryRecord(
                              record.id,
                              'isEditable',
                              false,
                            );
                          }
                        }}
                        classes="text-error border-error"
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Button
                        icon="edit"
                        type="primary"
                        variant="filled"
                        onClick={() =>
                          updateCategoryRecord(record.id, 'isEditable', true)
                        }
                      />
                      <Button
                        icon="delete"
                        type="primary"
                        variant="outline"
                        onClick={() => deleteCategory(record)}
                        classes="text-error border-error"
                      />
                    </React.Fragment>
                  )}
                </div>
              </div>
            ))}
          </React.Fragment>
        ) : (
          <div className="m-4 p-1">No categories configured yet.</div>
        )}
      </div>
      <Dialog
        title={DIALOG_TYPE.manageCompany[informationDialog]?.title}
        body={DIALOG_TYPE.manageCompany[informationDialog]?.body}
        positiveButton1={{
          text: DIALOG_TYPE.manageCompany[informationDialog]
            ?.positiveButtonText,
          callback: () => {
            setInformationDialog(false);
          },
        }}
        showDialog={informationDialog}
        setShowDialog={setInformationDialog}
      />
    </div>
  );
};

CompanyNotificationsTable.propTypes = {
  item: PropTypes.shape({
    category: PropTypes.any,
    channel: PropTypes.any,
    frequency: PropTypes.any,
    id: PropTypes.string,
    keyword: PropTypes.any,
    newcategory: PropTypes.any,
    newchannel: PropTypes.any,
    newfrequency: PropTypes.any,
    newkeyword: PropTypes.any,
    newtype: PropTypes.any,
    socialInfo: PropTypes.array,
    type: PropTypes.any,
  }),
  alertsList: PropTypes.array,
  categoriesList: PropTypes.array,
  setAlertsList: PropTypes.func,
  setCategoriesList: PropTypes.func,
};

export default CompanyNotificationsTable;
