import PropTypes from 'prop-types';
import React from 'react';

const Pill = ({
  onSelect,
  isActive,
  content,
  value,
  sentimentColor,
  needDisabledOnce,
  handlePillClick,
}) => {
  const arr = {
    positive: '',
    negative: '',
    neutral: '',
  };

  return (
    <button
      style={{ borderColor: sentimentColor }}
      disabled={needDisabledOnce && isActive}
      className={`m-0.5 px-2 cursor-pointer hover:ring-primary hover:ring-1 shadow border rounded ${
        isActive
          ? value === 'positive'
            ? 'bg-[#04AA6D] text-white' // Green background for positive when active
            : value === 'negative'
            ? 'bg-[#df2108] text-white' // Red background for negative when active
            : value === 'neutral'
            ? 'bg-[#dbbc0d] text-black' // Yellow background for neutral when active
            : 'bg-primary text-onprimary'
          : 'bg-onprimary' // Default background when not active
      }`}
      // className={`m-0.5 px-2 cursor-pointer hover:ring-primary hover:ring-1 shadow border rounded ${
      //   isActive ? "bg-primary text-onprimary" : "bg-onprimary"
      // } ${sentimentColor ? "border-2" : ""}`}
      onClick={() => {
        onSelect(value, isActive);

        /**
         * only calls the function when prop "handlePillClick" not undefined.
         */
        if (handlePillClick) {
          handlePillClick(value); // Call the function from the prop
        }
      }}
    >
      <div className="flex items-center py-1">
        {isActive && (
          <span className="material-icons text-sm inline-block pr-1">
            check
          </span>
        )}
        <span
          className={`text-sm border-l border-white pl-1 whitespace-nowrap flex items-center ${
            isActive ? 'justify-end' : 'justify-center'
          }`}
          // style={{
          //   width: '100px',
          //   overflow: 'hidden',
          //   textOverflow: 'ellipsis',
          //   whiteSpace: 'nowrap',
          //   direction: 'rtl',
          //   textAlign: 'left',
          // }}
          title={content}
        >
          {content}
        </span>
      </div>
    </button>
  );
};

Pill.propTypes = {
  onSelect: PropTypes.func,
  isActive: PropTypes.bool,
  content: PropTypes.string,
  value: PropTypes.string,
  sentimentColor: PropTypes.string,
  needDisabledOnce: PropTypes.bool,
};

export default React.memo(Pill);
