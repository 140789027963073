import React, { createContext, useEffect, useState } from 'react';

export const HeaderContext = createContext();
export const HeaderProvider = ({ children }) => {
  const [titleHeader, setTitleHeader] = useState('');

  useEffect(() => {
    return () => {
      setTitleHeader('');
    };
  }, []);

  return (
    <HeaderContext.Provider value={{ titleHeader, setTitleHeader }}>
      {children}
    </HeaderContext.Provider>
  );
};
