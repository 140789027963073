import React, { useState } from 'react';

const Error404Page = ({ error = '' }) => {
  // State to toggle error details visibility
  const [showErrorDetails, setShowErrorDetails] = useState(false);

  // Function to handle showing error details
  const handleShowErrorDetails = () => {
    setShowErrorDetails(!showErrorDetails);
  };

  const handleContactClick = () => {
    window.location.href =
      'mailto:support@yourcompany.com?subject=Contact%20Us';
  };
  return (
    <div className="flex flex-col h-screen ">
      {/* 404 Error Page */}
      <div className="flex-grow flex items-center justify-center bg-gray-100">
        <div className="container mx-auto px-2 py-6 bg-white shadow-lg rounded-lg ">
          <div className="flex flex-col  lg:flex-row">
            <div className="px-6 py-6 lg:w-3/5">
              <h1 className="text-6xl font-bold">
                <span className="text-blue-500">4</span>
                <span className="text-red-500">0</span>
                <span className="text-green-500">4</span>
              </h1>
              <h2 className="text-4xl font-bold mt-4">
                Oops! Something went wrong. 😢
              </h2>
              {/* <p className="mt-4 text-lg">
                You have reached the edge of the universe.
                <br />
                The page you requested could not be found.
                <br />
                Don't worry and return to the previous page.
              </p> */}

              <p className="mt-4 text-lg">
                This issue may be temporary, <br />
                Please go back and try again. If the problem persist, <br />
                Please get in touch with our team at team@haix.ai
              </p>
              <div className="mt-6 flex space-x-4">
                <a
                  href="/"
                  className="bg-blue-500 text-white px-6 py-2 rounded-lg shadow hover:bg-blue-600"
                >
                  Go Home
                </a>
                <a
                  href="#"
                  className="border border-gray-300 text-gray-800 px-6 py-2 rounded-lg shadow hover:bg-gray-100"
                >
                  Reload
                </a>
                <a
                  onClick={handleContactClick}
                  className="border border-gray-300 text-gray-800 px-6 py-2 rounded-lg shadow hover:bg-gray-100"
                >
                  Contact us
                </a>
              </div>
              <button
                onClick={handleShowErrorDetails}
                className="mt-4  inline-flex items-center text-primary rounded hover:text-blue-500 underline hover:underline"
              >
                <span>Error Details</span>
              </button>
            </div>

            <div className="lg:w-2/5">
              <img
                src="https://cdn.searchenginejournal.com/wp-content/uploads/2019/03/shutterstock_1338315902.png"
                alt="Error 404"
                className="w-full h-full  object-cover rounded-lg"
              />
            </div>
          </div>
          {showErrorDetails && (
            <p className="mt-2 px-6 w-full  text-red-500 ">
              {/* Display error details here */}
              {error}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Error404Page;
