import './Testimonial.css';

import React, { useEffect, useRef, useState } from 'react';

import pic1 from './logo/Picture1.png';
import pic10 from './logo/Picture10.png';
import pic11 from './logo/Picture11.png';
import pic12 from './logo/Picture12.png';
import pic13 from './logo/Picture13.png';
import pic2 from './logo/Picture2.png';
import pic3 from './logo/Picture3.png';
import pic4 from './logo/Picture4.png';
import pic5 from './logo/Picture5.png';
import pic6 from './logo/Picture6.png';
import pic7 from './logo/Picture7.png';
import pic8 from './logo/Picture8.png';
import pic9 from './logo/Picture9.png';

const FooterTestimonial = ({ setIsFooterDisplay = () => {} }) => {
  const logosRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
    setIsFooterDisplay(true);
  };

  // useEffect(() => {
  //   if (logosRef.current) {
  //     const copy = logosRef.current.cloneNode(true);
  //     logosRef.current.appendChild(copy);
  //   }
  // }, []);

  return isVisible ? (
    <div className="">
      <div className="" ref={logosRef}>
        <div className="logos-slide flex flex-wrap gap-8">
          <img src={pic1} />
          <img src={pic2} />
          <img src={pic3} />
          <img src={pic4} />
          <img src={pic5} />
          <img src={pic8} />
          <img src={pic9} />
          <img src={pic10} />
          <img src={pic12} />
          <img src={pic13} />
        </div>
      </div>
    </div>
  ) : null;
};

export default FooterTestimonial;
