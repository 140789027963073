import React from "react";
import PropTypes from "prop-types";
import ChannelCard from "./ChannelCard";

const MediaWell = ({
  socialMediaChannels,
  youtubeOption,
  twitterOption,
  tiktokOption,
  instagramOption,
  facebookOption,
  youtubeType,
  setTiktokType,
  setInstagramType,
  setYoutubeType,
  companyInfo,
  isEdit,
  ...props
}) => {
  return (
    <div>
      <div className="overflow-x-scroll overflow-y-hidden flex px-1">
        {socialMediaChannels.map((mediaItem) => {
          // if (
          //   mediaItem.label.toLowerCase() === "youtube" ||
          //   mediaItem.label.toLowerCase() === "twitter"
          // ) {
          //   mediaItem.state = false;
          // }

          return (
            <ChannelCard
              key={mediaItem.id}
              mediaItem={mediaItem}
              option={
                (mediaItem.label.toLowerCase() === "tiktok" && tiktokOption) ||
                (mediaItem.label.toLowerCase() === "facebook" &&
                  facebookOption) ||
                (mediaItem.label.toLowerCase() === "instagram" &&
                  instagramOption) ||
                undefined
              }
              {...props}
              youtubeOption={youtubeOption}
              twitterOption={twitterOption}
              tiktokOption={tiktokOption}
              instagramOption={instagramOption}
              facebookOption={facebookOption}
              setTiktokType={setTiktokType}
              setYoutubeType={setYoutubeType}
              setInstagramType={setInstagramType}
              companyInfo={companyInfo}
              isEdit={isEdit}
            />
          );
        })}
      </div>
    </div>
  );
};

MediaWell.propTypes = {
  socialMediaChannels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
};

export default React.memo(MediaWell);
