import React from "react";
import PropTypes from "prop-types";

const LogoHolder = ({ data, iconClickEvent, isActiveChannel = false }) => {
  const { icon, label, state, id } = data;
  return (
    <div
      className={`w-16 h-16 rounded-full p-3 flex mx-auto ${
        state || isActiveChannel ? "bg-white -my-8" : "bg-neutral-100 mt-9 mb-2"
      }`}
      onClick={() => iconClickEvent(id, true)}
      title={state || isActiveChannel ? label : `Enable ${label} channel`}
    >
      <img
        src={`/icons/social-media-icons/${
          state || isActiveChannel ? "colored" : "glyph"
        }/${icon}.svg`}
        alt={label}
      />
    </div>
  );
};

LogoHolder.defaultProps = {
  iconClickEvent: () => {},
};

LogoHolder.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.string,
    label: PropTypes.string,
    state: PropTypes.bool,
    id: PropTypes.string,
  }),
  iconClickEvent: PropTypes.func,
};

export default React.memo(LogoHolder);
