import React, { memo } from "react";
import { useEffect } from "react";
import { BsFillMicFill, BsFillMicMuteFill } from "react-icons/bs";

export const MicListingComponent = memo(
  ({ isMicON, setisMicON, start, stop }) => {
    useEffect(() => {
      start(); // start Listening from user mic

      return () => {
        setisMicON(false);
        stop();
      };
    }, []);
    return (
      <div class="w-40 flex justify-center gap-1 flex-col items-center text-[24px]">
        <div
          onClick={() => (!isMicON ? start() : stop())}
          title="say something"
          class={`"cursor-pointer ${
            isMicON ? "bg-red-600" : "bg-primary "
          } relative w-16 h-16 flex justify-center text-white items-center rounded-full`}
        >
          {isMicON && (
            <div className=" inline-flex rounded-full bg-red-500 animate-ping opacity-75 absolute w-12 h-12"></div>
          )}
          {isMicON ? (
            <BsFillMicFill className="cursor-pointer" onClick={stop} />
          ) : (
            <BsFillMicMuteFill className="cursor-pointer" />
          )}
        </div>
        <div class="w-full text-[16px] text-center mt-2">
          {isMicON && "Listening..."}
        </div>
      </div>
    );
  }
);
