import PropTypes from "prop-types";
import React from "react";

function DropdownList({
  label,
  value,
  options,
  onChange,
  isRequired = false,
  inValid,
}) {
  return (
    <label>
      {label}
      <select
        value={value}
        onChange={onChange}
        required={isRequired}
        className={`${inValid ? "border-red-400" : ""}`}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </label>
  );
}

DropdownList.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  value: PropTypes.string,
  isRequired: PropTypes.bool,
  inValid: PropTypes.bool,
};

export default DropdownList;
