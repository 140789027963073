import { NavLink, useNavigate } from 'react-router-dom';
import React, { useContext, useState } from 'react';

import Assistant from '../../../chatgpt_summarize/Assistant';
import { HeaderContext } from './header/HeaderContext';
import Help from './header/Help';
import Notifications from './header/Notifications';
import PropTypes from 'prop-types';
import UserMenu from './header/UserMenu';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

function Header({ sidebarOpen, setSidebarOpen, user }) {
  const { titleHeader } = useContext(HeaderContext);
  const [showVideo, setShowVideo] = useState(false);
  const [sidebarStatus, setSidebarStatus] = useState(false);
  const [convoId, setconvoId] = useState('');

  // Function to toggle the visibility of the summary UI component

  const toggleVideo = () => {
    setShowVideo(!showVideo);
  };

  const navigate = useNavigate();

  const video_link = {
    'Lead Insight': {
      url: 'https://www.loom.com/embed/24ce31011d71413eb1f635efc2106e1d?sid=75f7ca25-aa6a-4658-ac5e-6a6b1585906c',
    },
    'Socialmedia Insight': {
      url: 'https://www.loom.com/embed/581a95e7cbba44a08cd4b596393cff85?sid=b05e46ba-ff34-4889-887c-fe5afc10e290',
    },
    'Company Insight': {
      url: 'https://www.loom.com/embed/6e828c6d87a946bfbd3d63964b7342e8?sid=30650d64-ce1d-439e-91c2-c3e1d1cfa62e',
    },
    'Actionable Insight': {
      url: 'https://www.loom.com/embed/9346e7e20adf43d19bcff83d976c32f9?sid=d165221b-ac32-4e69-b0e0-8965064b37bc',
    },
    'Facebook Insight': {
      url: 'https://www.loom.com/embed/a95e54c013ca48ca8f7eec69e2c00b2f?sid=7aadc837-a7f3-44b8-ac44-aff7dbe01a40',
    },
    'Linkedin Insight': {
      url: 'https://www.loom.com/embed/47a9e2b25ebb48d5a6e3cfa8078ed7e0?sid=f5f688b1-4313-49e3-9586-afde40ac0719',
    },
  };
  const location = useLocation();
  let videoUrl;

  switch (true) {
    case location.pathname.includes('lead/generation'):
    case location.pathname.includes('/lead/jobinsights'):
    case location.pathname.includes('/lead/salesleadinsights'):
      videoUrl = video_link['Lead Insight'].url;
      break;

    case location.pathname.includes('/lead/socialmediainsights'):
      videoUrl = video_link['Socialmedia Insight'].url;
      break;

    case location.pathname.includes('/companyinsights/companydetails'):
      videoUrl = video_link['Company Insight'].url;
      break;

    case location.pathname.includes('/socialinsights'):
    case location.pathname.includes('/portfolio/trackcompanies'):
    case location.pathname.includes('/portfolio/dashboard'):
    case location.pathname.includes('/portfolio/insights'):
    case location.pathname.includes('/profile'):
      videoUrl = video_link['Actionable Insight'].url;
      break;

    case location.pathname.includes('/ad/facebook'):
    case location.pathname.includes('ad/trends'):
      videoUrl = video_link['Facebook Insight'].url;
      break;

    case location.pathname.includes('/linkedin/insights'):
    case location.pathname.includes('/linkedin/public_page'):
    case location.pathname.includes('/linkedin/user'):
      videoUrl = video_link['Linkedin Insight'].url;
      break;

    default:
      videoUrl = video_link['Actionable Insight'].url;
      break;
  }

  return (
    <>
      <header className="sticky top-0 bg-white border-b border-slate-200 z-[30]">
        <div className="px-2 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16 -mb-px">
            {/* Header: Left side */}
            <div className="flex mr-2 sm:mr-4">
              {/* Hamburger button */}

              <button
                className="text-slate-500 hover:text-slate-600 lg:hidden"
                aria-controls="sidebar"
                aria-expanded={sidebarOpen}
                onClick={() => setSidebarOpen(!sidebarOpen)}
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="w-6 h-6 fill-current"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="4" y="5" width="16" height="2" />
                  <rect x="4" y="11" width="16" height="2" />
                  <rect x="4" y="17" width="16" height="2" />
                </svg>
              </button>
              <NavLink end to="/" className="block">
                <img
                  src="/avatars/HAIXforwebsite.png"
                  className="lg:sidebar-expanded:hidden ml-2 h-auto w-16 sm:w-24"
                  alt="logo"
                />
              </NavLink>
            </div>
            <div className="flex justify-between w-full">
              {/* chat bot */}
              <div className="flex justify-between px-2 md:px-0">
                {/* <div className="flex flex-row space-x-4">
                <button
                  className="fixed bottom-[10px] right-[-10px] bg-gradient-to-r from-[#01A0F9] via-[#058388] to-[#09C778]  flex items-center text-onprimary rounded-full mr-8 "
                  onClick={() => {
                    setSidebarStatus(!sidebarStatus);
                    setconvoId(uuid());
                  }}
                >
                  <svg
                    dataSlot="icon"
                    fill="none"
                    strokeWidth={1.5}
                    width="40px"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z"
                    />
                  </svg>
                </button>
              </div> */}
                <div className="flex items-center justify-center w-full">
                  <h3 className="text-center text-[10px] sm:text-[20px] font-semibold text-secondary">
                    {titleHeader ? titleHeader : ''}
                  </h3>
                </div>
              </div>

              {/* Header: Right side */}
              <div className="flex items-center">
                {/* <Notifications /> */}
                <button
                  className="p-2 bg-gradient-to-r from-[#01A0F9] via-[#058388] to-[#09C778] text-[8px] sm:text-[14px] flex items-center text-onprimary rounded-md mr-2 sm:mr-8 "
                  onClick={() => {
                    navigate('/haix-genai/summary');
                  }}
                >
                  HaiX AI Assistant
                </button>
                <button
                  className="p-2 bg-gradient-to-r from-[#01A0F9] via-[#058388] to-[#09C778] flex items-center text-[10px] sm:text-[14px] text-onprimary rounded-md"
                  onClick={toggleVideo}
                >
                  Watch Demo
                </button>
                <Help />
                {/*  Divider */}
                <hr className="w-px h-6 bg-slate-200 mx-3" />
                <UserMenu user={user} />
              </div>
            </div>
          </div>
        </div>
      </header>
      {showVideo && (
        <div className="fixed top-0 left-0 w-full h-full flex items-start p-5 justify-end bg-black bg-opacity-50 z-50">
          <div className="relative">
            <button
              className="absolute top-0 right-0 m-4 text-white text-2xl cursor-pointer"
              onClick={toggleVideo}
            >
              &times;
            </button>
            <iframe
              width="560"
              height="315"
              src={videoUrl}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              title="Demo Video"
            ></iframe>
          </div>
        </div>
      )}

      <Assistant
        sidebarStatus={sidebarStatus}
        setSidebarStatus={setSidebarStatus}
        convoId={convoId}
        setconvoId={setconvoId}
      />
    </>
  );
}

Header.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    email_verified: PropTypes.bool,
    name: PropTypes.string,
    nickname: PropTypes.string,
    picture: PropTypes.string,
    sub: PropTypes.string,
    updated_at: PropTypes.string,
  }),
  sidebarOpen: PropTypes.bool,
  setSidebarOpen: PropTypes.func,
};

export default React.memo(Header);
