import React, { useContext, useEffect, useState } from 'react';

import { BASE_URI } from '../../../services/common/endpoints';
import Dialog from '../../../common/Dialog';
import Loader from '../../App/LayoutComponents/Loader/Loader';
import { UserProfileContext } from '../../../store/context/profile.context';
import { getToken } from '../../../services/common/authToken';

const ManageCopilotSubscription = () => {
  const profileContext = useContext(UserProfileContext);
  const [subscriptionType, setSubscriptionType] = useState('paid');
  const { profileInfo = {}, refreshUserProfile = () => {} } = profileContext;
  const [showModal, setshowModal] = useState(false);
  const [showModalMessage, setshowModalMessage] = useState('');
  const [ModalTitle, setModalTitle] = useState('');
  const [loading, setLoading] = useState(false);

  console.log(profileInfo);

  const [valueLeadEnrichment, setValueLeadEnrichment] = useState(50);
  const [valueLeadSearch, setValueLeadSearch] = useState(50);

  const [profileData, setProfileData] = useState(profileInfo);
  useEffect(() => {
    setProfileData(profileInfo);
  }, [profileInfo]);
  const [value, setValue] = useState(profileData?.GenAiInsights?.token_limit);

  const [formData, setFormData] = useState({
    // SocialListening: {
    //   label: 'Social Listening',
    //   limit: profileInfo?.SocialListening?.limit || 1000,
    //   subscription_requested:
    //     profileInfo?.SocialListening?.subscription_requested || false,
    //   subscription_status: true,
    // },
    // CompetitorIntelligence: {
    //   label: 'Competitor Intelligence',
    //   limit: profileInfo?.CompetitorIntelligence?.limit || 5,
    //   subscription_requested:
    //     profileInfo?.CompetitorIntelligence?.subscription_requested || false,
    //   subscription_status: false,
    // },
    // GenAISocialInsights: {
    //   label: 'GenAI Social Insights',
    //   limit: profileInfo?.GenAISocialInsights?.limit || 100,
    //   subscription_requested:
    //     profileInfo?.GenAISocialInsights?.subscription_requested || false,
    //   subscription_status: false,
    // },
    // LinkedInSocialListening: {
    //   label: 'LinkedIn Social Listening',
    //   limit: '5',
    //   limit: profileInfo?.LinkedInSocialListening?.limit || 5,
    //   subscription_requested:
    //     profileInfo?.LinkedInSocialListening?.subscription_requested || false,
    //   subscription_status:
    //     profileInfo?.LinkedInSocialListening?.subscription_status,
    // },
    // LinkedInDemographics: {
    //   label: 'LinkedIn Demographics',
    //   limit: '5',
    //   limit: profileInfo?.LinkedInDemographics?.limit || 5,
    //   subscription_requested:
    //     profileInfo?.LinkedInDemographics?.subscription_requested || false,
    //   subscription_status: 'Approved',
    // },

    // LinkedInOwnAccountIntelligence: {
    //   label: 'LinkedIn Own Account Intelligence',
    //   limit: '1',
    //   limit: profileInfo?.LinkedInOwnAccountIntelligence?.limit || 1,
    //   subscription_requested:
    //     profileInfo?.LinkedInOwnAccountIntelligence?.subscription_requested ||
    //     false,
    //   subscription_status: false,
    // },
    // TrustpilotIntelligence: {
    //   label: 'Trustpilot intelligence',
    //   limit: '20',
    //   limit: profileInfo?.TrustpilotIntelligence?.limit || 20,
    //   subscription_requested:
    //     profileInfo?.TrustpilotIntelligence?.subscription_requested || false,
    //   subscription_status: false,
    // },
    // // LeadIQInsights: {
    // //   label: "LeadIQ Insights",
    // //   limit: profileInfo?.LeadIQInsights.limit || "",
    // //   subscription_requested:
    // //     profileInfo?.LeadIQInsights.subscription_requested || false,
    // //   subscription_status: true,
    // //   leads_limit: value,
    // //   leads_enrichment_limit: valueLeadEnrichment,
    // //   leads_linkedininsights_limit: valueLeadSearch,
    // // },
    // CompanyMarketIntelligence: {
    //   label: 'Company Market Intelligence',
    //   limit: profileInfo?.CompanyMarketIntelligence?.limit || '',
    //   subscription_requested:
    //     profileInfo?.CompanyMarketIntelligence?.subscription_requested || false,
    //   subscription_status: false,
    // },
    // MarketingCampaignsMeta: {
    //   label: 'Marketing Campaigns Meta',
    //   limit: profileInfo?.MarketingCampaignsMeta?.limit || '',
    //   subscription_requested:
    //     profileInfo?.MarketingCampaignsMeta?.subscription_requested || false,
    //   subscription_status: false,
    // },

    // Alerts: {
    //   label: 'Alerts',
    //   limit: profileInfo?.Alerts?.limit || '',
    //   subscription_requested:
    //     profileInfo?.Alerts?.subscription_requested || false,
    //   subscription_status: false,
    // },
    // GenAiSocialMediaSearch: {
    //   label: 'Gen AI social media search',
    //   limit: profileInfo?.GenAiSocialMediaSearch?.limit || '',
    //   subscription_requested:
    //     profileInfo?.GenAiSocialMediaSearch?.subscription_requested || false,
    //   subscription_status: false,
    // },
    GenAiInsights: {
      label: 'HaiX Gen AI Copilot',
      limit: profileData?.GenAiInsights?.limit || '',
      token_limit: profileData?.GenAiInsights?.token_limit || 500000,
      subscription_requested:
        profileData?.GenAiInsights?.subscription_requested || false,
      subscription_status: profileData?.GenAiInsights?.subscription_status,
    },

    // UtilityFunctions: {
    //   label: 'Utility Functions',
    //   limit: profileInfo?.UtilityFunctions?.limit || '',
    //   subscription_requested:
    //     profileInfo?.UtilityFunctions?.subscription_requested || false,
    //   subscription_status: false,
    // },
  });
  const [showSlider, setShowSlider] = useState(true);

  const handleOptionChange = (option) => {
    if (option == 'GenAiInsights' && formData[option].subscription_requested) {
      setShowSlider(true);
    }
    // if (
    //   option == "LeadIQInsights" &&
    //   !formData[option].subscription_requested
    // ) {
    //   setShowSlider(true);
    // }

    setFormData({
      ...formData,
      [option]: {
        ...formData[option],
        subscription_requested: !formData[option]?.subscription_requested,
      },
    });
  };

  const handleLimitChange = (option, value) => {
    setFormData({
      ...formData,
      [option]: { ...formData[option], limit: value },
    });
  };

  // const handleTokenLimitZChange = (option, value) => {
  //   setFormData({
  //     ...formData,
  //     [option]: { ...formData[option] },
  //   });
  // };

  const handlesubscription_statusChange = (option) => {
    setFormData({
      ...formData,
      [option]: {
        ...formData[option],
        subscription_status: !formData[option]?.subscription_status,
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Data:', formData);
    handlePostRequest();
  };

  const handlePostRequest = async () => {
    setLoading(true);
    //  const newPromptData = {
    //    promptName: newPromptName,
    //    promptValue: newPromptValue,
    //    chartName: ChatgptData ? ChatgptData.title : "",
    //    socialMediaName: socialMediaType,
    //    source: selectedModel == "Default" ? "ag" : "ms",
    //  };

    // const dataValue = {
    //   fieldname: "subscriptionDetails",
    //   value: formData,
    //   // fieldname: "subscription",
    //   // value: subscriptionType,
    // };

    // setFormData({
    //   ...formData,
    //   GenAiInsights: {
    //     ...formData['GenAiInsights'],
    //     token_limit: Number(value),
    //   },
    // });

    const dataValue = [
      // {
      //   fieldname: "subscriptionDetails",
      //   value: formData,
      // },
      // {
      //   fieldname: 'subscription',
      //   value: subscriptionType,
      // },
      // {
      //   fieldname: 'SocialListening',
      //   value: formData.SocialListening,
      // },
      // {
      //   fieldname: 'CompetitorIntelligence',
      //   value: formData.CompetitorIntelligence,
      // },
      // {
      //   fieldname: 'GenAISocialInsights',
      //   value: formData.GenAISocialInsights,
      // },
      // {
      //   fieldname: 'LinkedInSocialListening',
      //   value: formData.LinkedInSocialListening,
      // },
      // {
      //   fieldname: 'LinkedInDemographics',
      //   value: formData.LinkedInDemographics,
      // },
      // {
      //   fieldname: 'LinkedInOwnAccountIntelligence',
      //   value: formData.LinkedInOwnAccountIntelligence,
      // },
      // {
      //   fieldname: 'TrustpilotIntelligence',
      //   value: formData.TrustpilotIntelligence,
      // },
      // {
      //   fieldname: "LeadIQInsights",
      //   value: formData.LeadIQInsights,
      // },
      // {
      //   fieldname: 'CompanyMarketIntelligence',
      //   value: formData.CompanyMarketIntelligence,
      // },
      // {
      //   fieldname: 'MarketingCampaignsMeta',
      //   value: formData.MarketingCampaignsMeta,
      // },
      // {
      //   fieldname: 'Alerts',
      //   value: formData.Alerts,
      // },

      // {
      //   fieldname: 'GenAiSocialMediaSearch',
      //   value: formData.GenAiSocialMediaSearch,
      // },
      {
        fieldname: 'GenAiInsights',
        value: formData.GenAiInsights,
      },
      {
        fieldname: 'gen_ai_subscription',
        value: subscriptionType,
      },
      // {
      //   fieldname: 'UtilityFunctions',
      //   value: formData.UtilityFunctions,
      // },
    ];
    try {
      // Replace 'http://172.187.179.39:3002/api/promptnameupdate/v5' with your actual API endpoint
      const response = await fetch(`${BASE_URI}/api/profilefieldupdate/v5`, {
        method: 'POST',
        headers: {
          // Authorization:
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataValue),
      });
      if (response.ok) {
        setModalTitle(' Gen Ai Subscription');
        setshowModalMessage(`Gen Ai Subscription Updated Successfully`);
        setshowModal(true);
        setTimeout(() => setshowModal(false), 2000);
      }
      setLoading(false);
      if (!response.ok) {
        // Handle non-2xx responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      refreshUserProfile();
      console.log(responseData);
      // Handle the response data as needed
      //    setIsClickedAddNew(false);
      //    setNewPromptName("");
      //    setNewPromptValue("");
      //    handleGetRequest();
    } catch (error) {
      // Handle errors
      console.error('Error in Post Request:', error.message);
    }
  };

  // Initial value of the slider

  const handleOnChangeMinus = (e) => {
    if (value > 0) {
      setFormData((prevState) => ({
        ...prevState,
        ['GenAiInsights']: {
          ...prevState['GenAiInsights'],
          token_limit: value - 5000000,
          subscription_status: 'Pending',
        },
      }));
      setValue(value - 500000);
    }
  };

  const handleOnChange = (e) => {
    setValue(Number(e.target.value));
    setFormData((prevState) => ({
      ...prevState,
      ['GenAiInsights']: {
        ...prevState['GenAiInsights'],
        token_limit: Number(event.target.value),
        subscription_status: 'Pending',
      },
    }));
  };

  const handleOnChangeAdd = (e) => {
    if (value < 10000000) {
      setFormData((prevState) => ({
        ...prevState,
        ['GenAiInsights']: {
          ...prevState['GenAiInsights'],
          token_limit: value + 5000000,
          subscription_status: 'Pending',
        },
      }));
      setValue(value + 500000);
    }
  };
  const handleOnChangeEnrichment = (e) => {
    setValueLeadEnrichment(e.target.value);
  };
  const handleOnChangeLeadSearch = (e) => {
    setValueLeadSearch(e.target.value);
  };

  const calculateFinalPrice = (value, type) => {
    let finalPrice = 0;
    // const token = {
    //   500000: 0,
    //   1000000: 200,
    //   2000000: 300,
    //   3000000: 400,
    //   4000000: 500,
    //   5000000: 600,
    //   6000000: 700,
    //   7000000: 800,
    //   8000000: 900,
    //   9000000: 1000,
    //   10000000: 1100,
    // };
    return value / 5000 || 0;
  };

  return (
    <div className="flex">
      <form
        onSubmit={handleSubmit}
        className="w-full my-4 sm:ml-10 sm:px-0 px-2  ml-0"
      >
        <div className="overflow-y-auto">
          <div className="w-[100%] h-[100%]">
            <div className="w-fit group overflow-x-hidden overflow-y-hidden scroll-smooth">
              {Object.keys(formData).map((option, index) => (
                <div key={index} className="mb-6 flex items-center gap-4">
                  <label className="block mb-2 whitespace-nowrap text-lg w-[20rem]">
                    {formData[option].label}:
                  </label>
                  <div className="relative flex items-center">
                    <input
                      type="checkbox"
                      id={`${option}-toggle`}
                      checked={formData[option].subscription_requested}
                      onChange={() => handleOptionChange(option)}
                      className="hidden"
                    />
                    <label
                      htmlFor={`${option}-toggle`}
                      className={`${
                        formData[option]?.subscription_requested
                          ? 'bg-primary'
                          : 'bg-gray-300'
                      } w-12 h-6 rounded-full cursor-pointer transition-colors duration-300 flex items-center`}
                    >
                      <span
                        className={`${
                          formData[option]?.subscription_requested
                            ? 'translate-x-6'
                            : 'translate-x-1'
                        } inline-block w-4 h-4 bg-white rounded-full shadow-md transform transition-transform duration-300`}
                      />
                    </label>
                    <label htmlFor={`${option}-toggle`} className="ml-3 w-8">
                      {formData[option].subscription_requested ? 'Yes' : 'No'}
                    </label>
                  </div>

                  <div className=" flex gap-1 items-center">
                    <label className="block mb-1">Status:</label>
                    <div className="flex items-center">
                      {formData[option].subscription_status == 'Approved' && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 text-green-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M0 10c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10zM6.293 9.293a1 1 0 0 1 1.414-1.414L10 10.586l3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 0-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                      {formData[option].subscription_status == 'Pending' && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 text-yellow-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <circle cx="10" cy="10" r="8" />
                        </svg>
                      )}

                      {formData[option].subscription_status == 'Rejected' && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 text-red-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <circle cx="10" cy="10" r="8" />
                        </svg>
                      )}

                      {formData[option].subscription_status == 'Approved' && (
                        <span className="text-green-500 font-medium ml-2">
                          {formData[option].subscription_status}
                        </span>
                      )}
                      {formData[option].subscription_status == 'Pending' && (
                        <span className="text-gray-500 ml-2 font-medium">
                          {formData[option].subscription_status}
                        </span>
                      )}
                      {formData[option].subscription_status == 'Rejected' && (
                        <span className="text-red-500 ml-2 font-medium">
                          {formData[option].subscription_status}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              ))}

              {
                <div className=" flex items-center mb-2 ">
                  <label className="block">Request Limit:</label>
                  <input
                    type="number"
                    value={formData['GenAiInsights'].limit}
                    onChange={(e) =>
                      handleLimitChange('GenAiInsights', Number(e.target.value))
                    }
                    className="w-[10rem] px-3 py-2 ml-2 border rounded-md border-gray-300 focus:outline-none focus:border-blue-500"
                  />
                </div>
              }

              {showSlider && (
                <div className="mr-2">
                  Choose Token Limit
                  <div className=" flex gap-2 items-center mt-6">
                    <div className=" flex flex-col ">
                      <div className="flex gap-2 mb-4">
                        <button
                          type="button"
                          className="rounded-full px-[0.7rem] py-[0.5rem] flex justify-flex-start items-center ring-1 ring-primary text-primary transition ease-in duration-200 
text-center text-base hover:bg-primary hover:text-onsecondary hover:shadow-md"
                          onClick={handleOnChangeMinus}
                        >
                          <svg
                            width={20}
                            height={20}
                            fill="none"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 12h12"
                            />
                          </svg>
                        </button>
                        <input
                          type="number"
                          // min={0}
                          max={1.7976931348623157e308}
                          // step={500000}
                          value={value}
                          onChange={handleOnChange}
                          className="w-[8rem]  px-3 py-2 ml-2 border rounded-md border-gray-300 focus:outline-none focus:border-blue-500"
                        />
                        <button
                          type="button"
                          className="rounded-full px-[0.7rem] py-[0.5rem] flex justify-flex-start items-center ring-1 ring-primary text-primary transition ease-in duration-200 
        text-center text-base hover:bg-primary hover:text-onsecondary hover:shadow-md"
                          onClick={handleOnChangeAdd}
                        >
                          <svg
                            width={20}
                            height={20}
                            fill="none"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 4.5v15m7.5-7.5h-15"
                            />
                          </svg>
                        </button>
                      </div>
                      <input
                        type="range"
                        min={0}
                        max={10000000}
                        step={500000}
                        value={value}
                        onChange={handleOnChange}
                        className="slider-thumb w-[32rem] appearance-none  h-3 bg-onsecondaryvariant rounded-full outline-none focus:outline-none"
                      />
                      <p className="text-center mt-2 text-gray-600">
                        Selected Tokens: {value}
                      </p>
                    </div>
                    <div className="bg-gray-100 rounded-lg shadow-md px-2 py-1 text-center">
                      <h1 className="text-2xl font-bold text-gray-800">
                        £{calculateFinalPrice(value, 'token')}
                      </h1>
                    </div>
                  </div>
                  <div className=" flex items-center mt-2 ">
                    <label className="block mb-2 whitespace-nowrap text-lg w-[20rem]">
                      Subscription Type:
                    </label>
                    <select
                      value={subscriptionType}
                      onChange={(e) => setSubscriptionType(e.target.value)}
                      className="w-24 px-3 py-2 ml-2 border rounded-md border-gray-300 focus:outline-none focus:border-blue-500"
                    >
                      <option value="paid">Paid</option>
                      <option value="trial">Trial</option>
                      <option value="pilot">Pilot</option>
                    </select>
                  </div>
                </div>
              )}

              {loading ? (
                <Loader message={'Loading...'} />
              ) : (
                <button
                  type="submit"
                  className="bg-primary hover:bg-primary text-white font-bold py-2 px-4 rounded"
                >
                  Submit
                </button>
              )}
            </div>{' '}
          </div>{' '}
        </div>
      </form>
      {showModal && (
        <Dialog
          title={ModalTitle}
          body={showModalMessage}
          positiveButton1={{
            text: 'Ok',
            callback: () => {},
          }}
          showDialog={showModal}
          setShowDialog={setshowModal}
        />
      )}
    </div>
  );
};

export default ManageCopilotSubscription;
