import React from "react";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";

const Slider = ({ title, handlePortalClose, children }) => {
  return (
    <div>
      {createPortal(
        <React.Fragment>
          <div className="z-[33] absolute top-0 left-0 w-full h-full bg-outline bg-opacity-60"></div>
          <div className="z-[34] absolute top-0 right-0 w-full h-full md:w-3/4 border-l bg-surface overflow-y-scroll shadow-l-2xl ">
            <div className="bg-primary bg-opacity-5 w-full h-full">
              <div className="shadow-b-2xl bg-surface text-onsurface border-b">
                <div className="flex items-center border-b border-outline">
                  <button
                    className="text-onbackground border border-outline hover:ring-1 hover:ring-primary hover:text-semibold rounded-md m-2 p-1 flex items-center"
                    onClick={handlePortalClose}
                  >
                    <span className="mx-1 material-icons text-sm">close</span>
                    <span className="p-1 mr-1 text-sm">close</span>
                  </button>
                  <span className="p-1 mx-2 text-md text-center">{title}</span>
                </div>
              </div>
              <div className="mt-1 ">{children}</div>
              <div className="pb-4" />
            </div>
          </div>
        </React.Fragment>,
        document.getElementById("slider")
      )}
    </div>
  );
};

Slider.propTypes = {
  children: PropTypes.any,
  handlePortalClose: PropTypes.func,
  title: PropTypes.string,
};

export default React.memo(Slider);
