import { PROFILE_OPERATION_TYPE } from '../../common/constants';
import { profileOperationHandler } from './userProfile.service';

const CompaniesOperation = function () {
  this.addCompany = (companyData, callbackFn) => {
    const { alertsData, categories, instagramType, tiktokType, youtubeType } =
      companyData;
    const params = {
      operationType: PROFILE_OPERATION_TYPE.addCompany,
      ...companyData,
    };
    delete params.alertsData;
    delete params.categories;
    delete params.instagramType;
    delete params.tiktokType;
    delete params.youtubeType;

    return profileOperationHandler({
      params,
      profileOperationType: PROFILE_OPERATION_TYPE.addCompany,
      callbackFn,
      data: {
        alerts: alertsData,
        categories,
        instagram: instagramType,
        tiktok: tiktokType,
        youtube: youtubeType,
      },
    });
  };

  this.editCompany = (companyData, id, callbackFn) => {
    const { alertsData, categories, instagramType, tiktokType, youtubeType } =
      companyData;
    const params = {
      operationType: PROFILE_OPERATION_TYPE.editCompany,
      ...companyData,
      id,
    };
    delete params.alertsData;
    delete params.categories;
    delete params.instagramType, delete params.tiktokType;
    delete params.youtubeType;
    return profileOperationHandler({
      params,
      profileOperationType: PROFILE_OPERATION_TYPE.editCompany,
      callbackFn,
      data: {
        alerts: alertsData,
        categories,
        instagram: instagramType,
        tiktok: tiktokType,
        youtube: youtubeType,
      },
    });
  };

  this.removeCompany = (id, callbackFn) => {
    const params = {
      operationType: PROFILE_OPERATION_TYPE.removeCompany,
      id,
    };
    return profileOperationHandler({
      params,
      profileOperationType: PROFILE_OPERATION_TYPE.removeCompany,
      callbackFn,
    });
  };

  this.operationHandler = async ({ operationType, data, id, callbackFn }) => {
    if (operationType === PROFILE_OPERATION_TYPE.addCompany) {
      return this.addCompany(data, callbackFn);
    } else if (operationType === PROFILE_OPERATION_TYPE.editCompany) {
      return this.editCompany(data, id, callbackFn);
    } else if (operationType === PROFILE_OPERATION_TYPE.removeCompany) {
      return this.removeCompany(id, callbackFn);
    }
  };
};

export const companiesOperationHandler = new CompaniesOperation()
  .operationHandler;
