import React from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "../../auth/protected-route";
import AppBase from "./AppBase";
// import 'bootstrap/dist/css/bootstrap.css';

function App() {
  return (
    <Routes>
      <Route path="*" element={<ProtectedRoute component={AppBase} />} />
    </Routes>
  );
}

export default App;
