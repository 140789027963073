import React, { useEffect, useState } from 'react';

import { AiFillEdit } from 'react-icons/ai';
import SelectFilter from '../SelectFilter/SelectFilter';

const BooleanSearchNew = ({ handleInputChangeIncludedCustom }) => {
  const [groups, setGroups] = useState([
    { name: 'Group 1', conditions: [], operator: 'AND' },
  ]);
  const [inputValues, setInputValues] = useState(['']);
  const [conditionOperator, setConditionOperator] = useState('AND');
  const [groupOperator, setGroupOperator] = useState('AND');
  const [editingGroupIndex, setEditingGroupIndex] = useState(null);
  const [isEditingQuery, setIsEditingQuery] = useState(false);
  const [queryText, setQueryText] = useState('');

  const addCondition = (groupIndex) => {
    if (inputValues[groupIndex].trim() !== '') {
      const newGroups = [...groups];
      newGroups[groupIndex].conditions.push({
        value: inputValues[groupIndex],
        operator: conditionOperator,
      });
      setGroups(newGroups);

      const newInputValues = [...inputValues];
      newInputValues[groupIndex] = '';
      setInputValues(newInputValues);
    }
  };

  useEffect(() => {
    // setQueryText(buildQuery());
    handleInputChangeIncludedCustom(queryText);
  }, [queryText]);

  useEffect(() => {
    // setQueryText(buildQuery());
    handleInputChangeIncludedCustom(buildQuery());
  }, [inputValues]);

  const addGroup = () => {
    setGroups([
      ...groups,
      {
        name: `Group ${groups.length + 1}`,
        conditions: [],
        operator: groupOperator,
      },
    ]);
    setInputValues([...inputValues, '']);
  };

  const handleInputChange = (e, groupIndex, condIndex) => {
    const newInputValues = [...inputValues];
    newInputValues[groupIndex] = e.target.value;
    setInputValues(newInputValues);

    const newGroups = [...groups];
    if (newGroups[groupIndex] && newGroups[groupIndex].conditions[condIndex]) {
      newGroups[groupIndex].conditions[condIndex].value = e.target.value;
      setGroups(newGroups);
    }
  };

  const handleConditionOperatorChange = (e, groupIndex, condIndex) => {
    const newGroups = [...groups];
    if (newGroups[groupIndex] && newGroups[groupIndex].conditions[condIndex]) {
      newGroups[groupIndex].conditions[condIndex].operator = e.target.value;
      setGroups(newGroups);
    }
  };

  const handleGroupOperatorChange = (e) => {
    setGroupOperator(e);
  };

  const handleEditGroup = (groupIndex) => {
    setEditingGroupIndex(groupIndex);
  };

  const handleSaveGroup = () => {
    setEditingGroupIndex(null);
  };

  const buildQuery = () => {
    return groups
      .map((group, groupIndex) => {
        const groupQuery = group.conditions
          .map((cond, condIndex) => {
            if (condIndex === 0) {
              return cond.value;
            }
            return `${cond.operator} ${cond.value}`;
          })
          .join(' ');
        if (groupIndex === 0) {
          return `(${groupQuery})`;
        }
        return `${group.operator} (${groupQuery})`;
      })
      .join(' ');
  };

  const handleEditQuery = () => {
    setIsEditingQuery(true);
    setQueryText(buildQuery());
  };

  const handleSaveQuery = () => {
    setIsEditingQuery(false);
  };

  return (
    <div className="p-4 space-y-4">
      <table className="min-w-full border-collapse border border-gray-200">
        <thead>
          <tr>
            <th className="border border-gray-200 p-2 text-left">Group Name</th>
            {groups[0].conditions.map((_, index) => (
              <>
                <th
                  key={`keyword-${index}`}
                  className="border border-gray-200 p-2 text-left"
                >
                  Keyword {index + 1}
                </th>
                {index < groups[0].conditions.length - 1 && (
                  <th
                    key={`operator-${index}`}
                    className="border border-gray-200 p-2 text-left"
                  >
                    Operator {index + 1}
                  </th>
                )}
              </>
            ))}
            <th className="border border-gray-200 p-2 text-left">
              New Condition
            </th>
            <th className="border border-gray-200 p-2 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {groups.map((group, groupIndex) => (
            <tr key={groupIndex}>
              <td className="border border-gray-200 p-2">{group.name}</td>
              {group.conditions.map((condition, condIndex) => (
                <>
                  <td
                    key={`keyword-${condIndex}`}
                    className="border border-gray-200 p-2"
                  >
                    {editingGroupIndex === groupIndex ? (
                      <input
                        type="text"
                        value={condition?.value}
                        onChange={(e) =>
                          handleInputChange(e, groupIndex, condIndex)
                        }
                        className="border p-2 rounded-md w-full"
                      />
                    ) : (
                      <div className="flex">{condition?.value}</div>
                    )}
                  </td>
                  {condIndex < group.conditions.length - 1 && (
                    <td
                      key={`operator-${condIndex}`}
                      className="border border-gray-200 p-2"
                    >
                      {editingGroupIndex === groupIndex ? (
                        <input
                          type="text"
                          value={condition?.operator}
                          onChange={(e) =>
                            handleConditionOperatorChange(
                              e,
                              groupIndex,
                              condIndex,
                            )
                          }
                          className="border p-2 rounded-md w-full"
                        />
                      ) : (
                        <div className="flex">{condition?.operator}</div>
                      )}
                    </td>
                  )}
                </>
              ))}
              <td className="border border-gray-200 px-2 flex">
                <input
                  type="text"
                  value={inputValues[groupIndex]}
                  onChange={(e) => handleInputChange(e, groupIndex)}
                  className="border px-2 py-1 h-10 rounded-md w-full mt-auto mr-2"
                  placeholder="Enter keyword"
                />
                {inputValues[groupIndex].trim() !== '' && (
                  <SelectFilter
                    className="mr-2"
                    value={conditionOperator}
                    onValueChange={(value) => setConditionOperator(value)}
                    placeholder="Operator"
                    options={[
                      { value: 'AND', label: 'AND' },
                      { value: 'OR', label: 'OR' },
                    ]}
                  />
                )}
                <button
                  onClick={() => addCondition(groupIndex)}
                  className="bg-primary rounded-md text-white px-2 py-1 mt-auto w-10 h-10"
                >
                  +
                </button>
              </td>
              <td className="border border-gray-200 p-2 ">
                {editingGroupIndex === groupIndex ? (
                  <button
                    onClick={handleSaveGroup}
                    className="bg-green-500 rounded-md text-white p-2"
                  >
                    Save
                  </button>
                ) : (
                  <div
                    onClick={() => handleEditGroup(groupIndex)}
                    className="text-primary mr-4 cursor-pointer"
                  >
                    <AiFillEdit />
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="w-full flex flex-col md:flex-row items-start space-y-2 md:space-y-0 md:space-x-2 mt-4">
        <div className="">
          <SelectFilter
            value={groupOperator}
            onValueChange={(value) => handleGroupOperatorChange(value)}
            placeholder="Operator"
            options={[
              { value: 'AND', label: 'AND' },
              { value: 'OR', label: 'OR' },
            ]}
          />
        </div>
        <button
          onClick={addGroup}
          style={{
            marginTop: '1.5rem',
          }}
          className="bg-primary rounded-md text-white p-2 mt-4"
        >
          Add Group
        </button>
      </div>
      <div className="w-full mt-4">
        <h3 className="text-lg font-bold">Boolean Query:</h3>
        {isEditingQuery ? (
          <div className="flex items-center">
            <input
              type="text"
              value={queryText}
              onChange={(e) => setQueryText(e.target.value)}
              className="border p-2 rounded-md w-full"
            />
            <button
              onClick={handleSaveQuery}
              className="bg-green-500 rounded-md text-white p-2 ml-2"
            >
              Save
            </button>
          </div>
        ) : (
          <div className="flex items-center">
            <p className="border p-2 flex-grow">{buildQuery()}</p>
            <div
              onClick={handleEditQuery}
              className="text-primary ml-2 cursor-pointer"
            >
              <AiFillEdit />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BooleanSearchNew;
