import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import { SOCIAL_MEDIA_METADATA } from "./metadata";

export const debounce = (func, timeout = 3000) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const isValidArray = (array, withData = false) => {
  const validArray =
    !!array && typeof array === "object" && Array.isArray(array);
  if (validArray && withData) {
    const result = validArray && !!array.length;
    return result;
  }
  return validArray;
};

export const arrayEquals = (array1, array2) => {
  return (
    isValidArray(array1, true) &&
    isValidArray(array2, true) &&
    array1.length === array2.length &&
    array1.every((val, index) => val === array2[index])
  );
};

export const searchTermOrSocialMediaCheck = (
  savedSearchTerm,
  currentSearchTerm
) => {
  if (
    isValidArray(savedSearchTerm, true) &&
    isValidArray(currentSearchTerm, true)
  ) {
    return arrayEquals(savedSearchTerm.sort(), currentSearchTerm.sort());
  } else {
    return savedSearchTerm === currentSearchTerm;
  }
};

export const isValidObject = (object, withData = false) => {
  const validObject =
    object && !Array.isArray(object) && typeof object === "object";
  if (withData) {
    const result = validObject && !!Object.keys(object).length;
    return result;
  }
  return validObject;
};

export const testStringValdiity = (val) => val && val.trim().length;

// get labels
export const generateMediChannelSearchTerm = (route) => {
  if (route.startsWith("/linkedin/public_page/")) {
    return SOCIAL_MEDIA_METADATA.reduce((searchObject, channel) => {
      const state = channel.state.find(
        (data) => data.route === "/linkedin/public_page/"
      );
      let obj = { ...searchObject };
      if (state && state.isVisible) {
        obj = {
          ...obj,
          [channel["label"].toLowerCase()]: "",
        };
      }
      return obj;
    }, {});
  } else {
    return SOCIAL_MEDIA_METADATA.reduce((searchObject, channel) => {
      const state = channel.state.find(
        (data) =>
          data.route === route || data.route === "/linkedin/public_page/"
      );
      let obj = { ...searchObject };
      if (state && state.isVisible) {
        obj = {
          ...obj,
          [channel["label"].toLowerCase()]: "",
        };
      }
      return obj;
    }, {});
  }
};

export const getSocialMediaChannel = (route) =>
  SOCIAL_MEDIA_METADATA.reduce((searchObject, channel) => {
    const state = channel.state.find(
      (data) => data.route === route || data.route === "/linkedin/public_page/"
    );
    let obj = [...searchObject];
    if (state && state.isVisible) {
      obj.push({
        id: channel.id,
        icon: channel.icon,
        label: channel.label,
        state: state.isValid,
      });
    }
    return obj;
  }, []);

export const downloadHandler = async (name, callbackFn) => {
  try {
    const canvas = await html2canvas(document.getElementById(name));
    canvas.toBlob(function (blob) {
      try {
        saveAs(blob, `${name}_${new Date().getTime()}.png`);
        callbackFn(false);
      } catch (exception) {
        callbackFn(true); //TODO log alert message
      }
    });
  } catch (exception) {
    callbackFn(true);
  }
};

export const tableDataSegregation = (data, statsType, platformType) => {
  let plotData = [];
  data.map((e) => {
    if (statsType === "campaigns_stats") {
      e.campaigns.map((e2) => {
        if (platformType === "Facebook") {
          plotData.push({
            name: e2?.facebook?.account_name,
            updatedOn: e2?.facebook?.date_stop,
            impressions: e2?.facebook?.impressions,
            reach: e2?.facebook?.reach,
            clicks: e2?.facebook?.clicks,
            spend: e2?.facebook?.spend,
          });
        } else if (platformType === "Instagram") {
          plotData.push({
            name: e2?.instagram?.account_name,
            updatedOn: e2?.instagram?.date_stop,
            impressions: e2?.instagram?.impressions,
            reach: e2?.instagram?.reach,
            clicks: e2?.instagram?.clicks,
            spend: e2?.instagram?.spend,
          });
        } else if (platformType === "Messenger") {
          plotData.push({
            name: e2?.messenger?.account_name,
            updatedOn: e2?.messenger?.date_stop,
            impressions: e2?.messenger?.impressions,
            reach: e2?.messenger?.reach,
            clicks: e2?.messenger?.clicks,
            spend: e2?.messenger?.spend,
          });
        } else if (platformType === "Audience Network") {
          plotData.push({
            name: e2?.audience_network?.account_name,
            updatedOn: e2?.audience_network?.date_stop,
            impressions: e2?.audience_network?.impressions,
            reach: e2?.audience_network?.reach,
            clicks: e2?.audience_network?.clicks,
            spend: e2?.audience_network?.spend,
          });
        } else {
          plotData.push({
            name: e2?.summary?.account_name,
            updatedOn: e2?.summary?.date_stop,
            impressions: e2?.summary?.impressions,
            reach: e2?.summary?.reach,
            clicks: e2?.summary?.clicks,
            spend: e2?.summary?.spend,
          });
        }
      });
    } else if (statsType === "adsets_stats") {
      e.adsets.map((e2) => {
        if (platformType === "Facebook") {
          plotData.push({
            name: e2?.facebook?.account_name,
            updatedOn: e2?.facebook?.date_stop,
            impressions: e2?.facebook?.impressions,
            reach: e2?.facebook?.reach,
            clicks: e2?.facebook?.clicks,
            spend: e2?.facebook?.spend,
          });
        } else if (platformType === "Instagram") {
          plotData.push({
            name: e2?.instagram?.account_name,
            updatedOn: e2?.instagram?.date_stop,
            impressions: e2?.instagram?.impressions,
            reach: e2?.instagram?.reach,
            clicks: e2?.instagram?.clicks,
            spend: e2?.instagram?.spend,
          });
        } else if (platformType === "Messenger") {
          plotData.push({
            name: e2?.messenger?.account_name,
            updatedOn: e2?.messenger?.date_stop,
            impressions: e2?.messenger?.impressions,
            reach: e2?.messenger?.reach,
            clicks: e2?.messenger?.clicks,
            spend: e2?.messenger?.spend,
          });
        } else if (platformType === "Audience Network") {
          plotData.push({
            name: e2?.audience_network?.account_name,
            updatedOn: e2?.audience_network?.date_stop,
            impressions: e2?.audience_network?.impressions,
            reach: e2?.audience_network?.reach,
            clicks: e2?.audience_network?.clicks,
            spend: e2?.audience_network?.spend,
          });
        } else {
          plotData.push({
            name: e2?.summary?.account_name,
            updatedOn: e2?.summary?.date_stop,
            impressions: e2?.summary?.impressions,
            reach: e2?.summary?.reach,
            clicks: e2?.summary?.clicks,
            spend: e2?.summary?.spend,
          });
        }
      });
    } else {
      e.ads.map((e2) => {
        if (platformType === "Facebook") {
          plotData.push({
            name: e2?.facebook?.account_name,
            updatedOn: e2?.facebook?.date_stop,
            impressions: e2?.facebook?.impressions,
            reach: e2?.facebook?.reach,
            clicks: e2?.facebook?.clicks,
            spend: e2?.facebook?.spend,
          });
        } else if (platformType === "Instagram") {
          plotData.push({
            name: e2?.instagram?.account_name,
            updatedOn: e2?.instagram?.date_stop,
            impressions: e2?.instagram?.impressions,
            reach: e2?.instagram?.reach,
            clicks: e2?.instagram?.clicks,
            spend: e2?.instagram?.spend,
          });
        } else if (platformType === "Messenger") {
          plotData.push({
            name: e2?.messenger?.account_name,
            updatedOn: e2?.messenger?.date_stop,
            impressions: e2?.messenger?.impressions,
            reach: e2?.messenger?.reach,
            clicks: e2?.messenger?.clicks,
            spend: e2?.messenger?.spend,
          });
        } else if (platformType === "Audience Network") {
          plotData.push({
            name: e2?.audience_network?.account_name,
            updatedOn: e2?.audience_network?.date_stop,
            impressions: e2?.audience_network?.impressions,
            reach: e2?.audience_network?.reach,
            clicks: e2?.audience_network?.clicks,
            spend: e2?.audience_network?.spend,
          });
        } else {
          plotData.push({
            name: e2?.summary?.account_name,
            updatedOn: e2?.summary?.date_stop,
            impressions: e2?.summary?.impressions,
            reach: e2?.summary?.reach,
            clicks: e2?.summary?.clicks,
            spend: e2?.summary?.spend,
          });
        }
      });
    }
  });
  return plotData;
};

/**
 *
 * @param {String} getParam
 * @returns {String}
 *
 * `getCompanynameUsingId()` method gets parameter from url which is unique id of company
 * after getting it will look for company which has the same id same as in url.
 * after a id match it will return company name which is used for API as handler name.
 *
 */
export const getCompanynameUsingId = (getParam, linkedinPublicPageArray) => {
  if (!getParam) return;
  if (isValidArray(linkedinPublicPageArray)) {
    const activCompany = linkedinPublicPageArray?.filter(
      (data) => data.id === getParam
    );
    const activeCompanyName = activCompany[0]?.value;

    return activeCompanyName;
  }
};

/**
 *
 * @param {String} newParamId
 * @returns
 *
 *
 * This function will be responsible for changing the URL according to the tabs you click in `sales lead insight`.
 *
 * newParamId - this parameter contains the name of tab you have clicked on.
 *
 */
export function changeURL(newParamId, params, location, navigate) {
  /**
   * the below line will check if you clicking on active tab or new tab
   * if you are clicking the same tab again and again then it will terminate the code and will not execute futher
   * until you click on new tab.
   */
  if (newParamId === params?.id) return;

  let p = params?.id; // this has the name of tab from url.

  if (location?.pathname?.includes("%20")) {
    /**
     * this if condition will only execute when contains `%20`
     * for example: For `data scientist` when it will be set into url
     * the space between `data` and `scientist` will be displayed as `%20` in url.
     *
     * so, in this case url contains `%20`.
     * when page will be loaded the pathname will have `%20`. which will always makes next if statement false.
     * so, to prevent the if statement from being false.
     *
     * (Note: `%20` is available on in pathname but in `params.id` it will be displayed with space.)
     *
     * the below line will replace space into `%20` for if statement to check the condition.
     * [if pathname does not have `%20` then the code will skip the this if statement and will got to next if statement.]
     *
     */
    p = params?.id?.replaceAll(" ", "%20");
  }

  if (location?.pathname?.includes(p)) {
    /**
     * Here it will check if pathname contains the `params.id` if it has params.id
     * below line will replace the old `params.id` with new param we getting from this functions parameter 'newParamId'.
     */
    const newPath = location?.pathname.replace(p, newParamId); // this will create new hyperlink

    navigate(newPath, { replace: true }); // this will set new created url in browser.
  } else {
    /**
     * If pathname doesn't have `params.id` value. then the code will enter in else.
     * mostly code will only enter in else part when you first time click on `sales lead insight` on sidebar.
     */
    navigate(newParamId, { replace: true }); // sets param to existing url.

    /**
     * If you have any questions please ask me.
     * -sarfaraj [MrDark]
     *
     */
  }
}
