import { FACEBOOK_SDK_API_OPERATION } from "../../common/constants";
import { facebookSDKAPIHandler } from "./facebookApi";

export const initFacebook = () => {
  try {
    facebookSDKAPIHandler({
      operation: FACEBOOK_SDK_API_OPERATION.init,
      callbackFn: (event) => {
        // console.log(event, " :Event");
      },
    });
  } catch (error) {
    console.error("initFacebook error =>", error);
  }
};
