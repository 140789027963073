import React, { useState } from 'react';

import { Input } from '@uirack/components/ui/input';
import { Label } from '@uirack/components/ui/label';

function InputWithLabel({
  label,
  id,
  type = 'text',
  placeholder = '',
  value = '',
  onChange,
  ...inputProps
}) {
  const [isFocused, setIsFocused] = useState(false);
  console.log(type, 'typetypsse');

  return (
    <div className="w-[350px]">
      <div className="relative w-full flex flex-col">
        <div className="relative">
          {label && (
            <Label
              htmlFor={id}
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              style={{ pointerEvents: 'none' }}
            >
              {label}
            </Label>
          )}
          <Input
            type={type}
            id={id}
            value={value}
            placeholder={placeholder}
            className={`relative block w-full h-12 mt-1 bg-[#fefefe] rounded-md  px-3 appearance-none leading-normal focus:border-[#00639c] inputBoxShadow ${
              isFocused ? 'shadow-outline' : ''
            }`}
            onChange={onChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            {...inputProps}
          />
        </div>
      </div>
    </div>
  );
}

export default InputWithLabel;
