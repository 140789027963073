import { AiOutlineClose } from 'react-icons/ai';
import FooterTestimonial from './FooterTestimonial';
import { GoLinkExternal } from 'react-icons/go';
import React from 'react';
import SummaryDialog from './SummaryDialog';
import { useEffect } from 'react';
import { useState } from 'react';

function Testimonial(props) {
  const [model, setModel] = useState(true);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Get the modalShown item from localStorage
    const modalShown = localStorage.getItem('modalShown');

    if (modalShown) {
      // Parse the stored data
      const data = JSON.parse(modalShown);
      const now = new Date();

      // Check if 7 days have passed
      if (now.getTime() - data.date > 2 * 24 * 60 * 60 * 1000) {
        // If 7 days have passed, delete the item and show the modal again
        localStorage.removeItem('modalShown');
        setIsOpen(true);
      }
    } else {
      // If the item doesn't exist, show the modal and set the item
      setIsOpen(true);
      localStorage.setItem(
        'modalShown',
        JSON.stringify({ date: new Date().getTime() }),
      );
    }
  }, []);

  const closeModal = () => {
    setIsOpen(false);
  };

  const overlayStyle = {
    backgroundColor: 'transparent',
    backdropFilter: 'none',
    zIndex: 40, // Set the overlay background color to transparent
  };

  useEffect(() => {
    setTimeout(() => {
      setModel(false);
    }, 10000);
  }, []);
  return (
    <>
      {isOpen && (
        // <ReactModal
        //   initWidth={1000}
        //   intiHeight={500}
        //   isOpen={model}
        //   onRequestClose={() => setModel(false)}
        //   disableResize={true}
        //   customStyles={{
        //     overlay: overlayStyle,
        //   }}
        // >

        <SummaryDialog
          title={''}
          body={''}
          positiveButton1={{
            text: 'Close',
            callback: () => {},
          }}
          showDialog={model}
          setShowDialog={setModel}
          width="70%"
        >
          <div className="w-[70vw]">
            <p className="text-[20px] text-black font-medium text-center pt-2">
              Our Clients
            </p>

            <div className="flex mt-3 justify-end bg-gray-50 ">
              <button
                title="Close"
                onClick={() => setModel(false)}
                className="mx-4 inline-block  "
              >
                <AiOutlineClose color="#ff0000" />
              </button>
            </div>

            <section className="pb-6 pt-6 h-[550px] overflow-y-auto bg-gray-50 sm:pb-16 lg:pb-20">
              <div className="text-center">
                <p className="text-lg mb-4 font-medium text-gray-600 font-pj"></p>
              </div>
              <FooterTestimonial />
              <div className="px-4 mx-auto mt-8 max-w-7xl sm:px-6 lg:px-8">
                <div className="flex flex-col items-center">
                  <div className="text-center">
                    <p className="text-lg font-medium text-gray-600 font-pj">
                      Fortune500 and top companies / top influencers using HaiX
                      AI
                    </p>
                    <h2 className="mt-4 text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj">
                      Our happy clients say about us
                    </h2>
                  </div>

                  <div className="relative mt-10 md:mt-24 md:order-2">
                    <div className="absolute -inset-x-1 inset-y-16 md:-inset-x-2 md:-inset-y-6">
                      <div
                        className="w-full h-full max-w-5xl mx-auto rounded-3xl opacity-30 blur-lg filter"
                        style={{
                          background:
                            'linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)',
                        }}
                      ></div>
                    </div>

                    <div className="relative grid max-w-lg grid-cols-1 gap-6 mx-auto md:max-w-none lg:gap-10 md:grid-cols-3">
                      <div className="flex flex-col overflow-hidden shadow-xl">
                        <div className="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                          <div className="flex-1">
                            <div className="flex items-center">
                              <svg
                                className="w-5 h-5 text-[#FDB241]"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                              <svg
                                className="w-5 h-5 text-[#FDB241]"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                              <svg
                                className="w-5 h-5 text-[#FDB241]"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                              <svg
                                className="w-5 h-5 text-[#FDB241]"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                              <svg
                                className="w-5 h-5 text-[#FDB241]"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                            </div>

                            <blockquote className="flex-1 mt-8">
                              <p className="text-lg leading-relaxed text-gray-900 font-pj">
                                “HaiX AI helps save significant time with
                                detailed video insights (trends, engagement,
                                entities, sentiment) from thousands of videos on
                                TikTok, YouTube at scale, unlike most other
                                social listening tools which are limited to text
                                data only.”
                              </p>
                            </blockquote>
                          </div>

                          <div className="flex items-center mt-8">
                            {/* <img
                       className="flex-shrink-0 object-cover rounded-full w-11 h-11"
                       src="https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-1.png"
                       alt=""
                     /> */}
                            <div className="ml-4">
                              <p className="text-base font-bold text-gray-900 font-pj">
                                Wendy Scherer
                              </p>
                              <p className="mt-0.5 text-sm font-pj text-gray-600">
                                Founder & Managing Partner The Social Studies
                                Group
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col overflow-hidden shadow-xl">
                        <div className="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                          <div className="flex-1">
                            <div className="flex items-center">
                              <svg
                                className="w-5 h-5 text-[#FDB241]"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                              <svg
                                className="w-5 h-5 text-[#FDB241]"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                              <svg
                                className="w-5 h-5 text-[#FDB241]"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                              <svg
                                className="w-5 h-5 text-[#FDB241]"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                              <svg
                                className="w-5 h-5 text-[#FDB241]"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                            </div>

                            <blockquote className="flex-1 mt-8">
                              <p className="text-lg leading-relaxed text-gray-900 font-pj">
                                “We use HaiX vs. other social listening tools
                                due to its real-time insights beyond just
                                mentions. It Saves us 70% manual time in
                                monitoring our Marketing campaigns & Social
                                Media.”
                              </p>
                            </blockquote>
                          </div>

                          <div className="flex items-center mt-8">
                            {/* <img
                       className="flex-shrink-0 object-cover rounded-full w-11 h-11"
                       src="https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-1.png"
                       alt=""
                     /> */}
                            <div className="ml-4">
                              <p className="text-base font-bold text-gray-900 font-pj">
                                Jeffrey McDonnell
                              </p>
                              <p className="mt-0.5 text-sm font-pj text-gray-600">
                                COO at Eden Energy Group
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col overflow-hidden shadow-xl">
                        <div className="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                          <div className="flex-1">
                            <div className="flex items-center">
                              <svg
                                className="w-5 h-5 text-[#FDB241]"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                              <svg
                                className="w-5 h-5 text-[#FDB241]"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                              <svg
                                className="w-5 h-5 text-[#FDB241]"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                              <svg
                                className="w-5 h-5 text-[#FDB241]"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                              <svg
                                className="w-5 h-5 text-[#FDB241]"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                            </div>

                            <blockquote className="flex-1 mt-8">
                              <p className="text-lg leading-relaxed text-gray-900 font-pj">
                                ”We Partnered with HaiX to augment our Credit
                                Rating Engine to augment Market Social
                                Intelligence in helping our clients stay ahead
                                with emerging Risks, trending sentiment,
                                issues.”
                              </p>
                            </blockquote>
                          </div>

                          <div className="flex items-center mt-8">
                            {/* <img
                       className="flex-shrink-0 object-cover rounded-full w-11 h-11"
                       src="https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-2.png"
                       alt=""
                     /> */}
                            <div className="ml-4">
                              <p className="text-base font-bold text-gray-900 font-pj">
                                Valentino Pediroda
                              </p>
                              <p className="mt-0.5 text-sm font-pj text-gray-600">
                                Group CEO, Modefinance
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col overflow-hidden shadow-xl">
                        <div className="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                          <div className="flex-1">
                            <div className="flex items-center">
                              <svg
                                className="w-5 h-5 text-[#FDB241]"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                              <svg
                                className="w-5 h-5 text-[#FDB241]"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                              <svg
                                className="w-5 h-5 text-[#FDB241]"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                              <svg
                                className="w-5 h-5 text-[#FDB241]"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                              <svg
                                className="w-5 h-5 text-[#FDB241]"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                            </div>

                            <blockquote className="flex-1 mt-8">
                              <p className="text-lg leading-relaxed text-gray-900 font-pj">
                                ”We use HaiX Actionable Intelligence to monitor
                                in real-time emerging Risk and Issues in a
                                country and our portfolio and advise our
                                Investment Partners to mitigate losses and stay
                                ahead.”
                              </p>
                            </blockquote>
                          </div>

                          <div className="flex items-center mt-8">
                            {/* <img
                       className="flex-shrink-0 object-cover rounded-full w-11 h-11"
                       src="https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-female.png"
                       alt=""
                     /> */}
                            <div className="ml-4">
                              <p className="text-base font-bold text-gray-900 font-pj">
                                Ralf M
                              </p>
                              <p className="mt-0.5 text-sm font-pj text-gray-600">
                                Risk Officer a CFE Group
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </SummaryDialog>
      )}
    </>
  );
}

export default Testimonial;
