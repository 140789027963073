import React, { useState } from 'react';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@uirack/components/ui/select';

// Adjust import according to your select library

const SelectFilterFilter = ({
  value,
  onValueChange,
  placeholder,
  options,
  className = '',
  width,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  return (
    <div className={width ? width : 'w-[350px]'}>
      <div className="relative w-full flex flex-col">
        <div className="relative">
          <label
            // className={`relative left-2  bg-white top-[10px] transform -translate-y-1/2 transition-all duration-200 ease-in-out ${
            //   isFocused || value
            //     ? 'top-0 text-gray-500 text-xs bg-white px-1'
            //     : 'text-gray-500 text-base'
            // }`}
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            style={{ pointerEvents: 'none' }}
          >
            {/* <span class="text-sm font-semibold text-primary material-icons cursor-pointer pr-2">
              filter_alt
            </span> */}
            {placeholder}
          </label>

          <Select
            onValueChange={onValueChange}
            value={value}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={`relative block w-full bg-[#fefefe]  border-gray-500 rounded-md  px-3 appearance-none leading-normal focus:border-[#00639c] ${
              isFocused ? 'shadow-outline' : ''
            }`}
          >
            <SelectTrigger className="bg-white h-12 mt-1">
              <SelectValue placeholder={placeholder} />
            </SelectTrigger>
            <SelectContent className="bg-white ">
              <SelectGroup>
                {options?.map((option) => (
                  <SelectItem key={option?.value} value={option?.value}>
                    {option?.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>
      {/* <Select onValueChange={onValueChange} value={value}>
        <SelectTrigger
          className={`bg-white border-2 border-primary text-primary hover:bg-gray-100 hover:border-primary-dark ${className}`}
        >
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent className="bg-white border-2 border-primary hover:bg-gray-100 hover:border-primary-dark">
          <SelectGroup>
            {options.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select> */}
    </div>
  );
};

export default SelectFilterFilter;
