import React from "react";
import PropTypes from "prop-types";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loader from "../modules/App/LayoutComponents/Loader/Loader";

const ProtectedRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, {
    ...args,
    onRedirecting: () => <Loader message="Verifying Identity" />,
  });
  return <Component />;
};

ProtectedRoute.propTypes = {
  component: PropTypes.func,
};

export default ProtectedRoute;
