import './tooltipdes.scss';

import React, { useRef } from 'react';

const TooltipDes = ({
  text,
  children,
  align = 'center',
  width = '',
  searchText = '',
}) => {
  const tooltipRef = useRef(null);

  return (
    <div className="tooltip-wrapper">
      <div
        className={`tooltip-wrapper__content tooltip-wrapper--align-${align}`}
        ref={tooltipRef}
        style={{
          width: width,
        }}
      >
        <div className="tooltip-wrapper__content--arrow"></div>
        {searchText ? (
          <div
            className={`tooltip-wrapper__text ${
              width ? width : ''
            } font-normal`}
            dangerouslySetInnerHTML={{ __html: searchText }}
            style={{ fontSize: '14px', textAlign: 'left', width: '30vw' }}
          />
        ) : (
          <p
            className="tooltip-wrapper__text font-normal"
            style={{ fontSize: '14px', textAlign: 'left' }}
          >
            {text}
          </p>
        )}
      </div>
      {children}
    </div>
  );
};

export default TooltipDes;
