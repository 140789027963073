/* eslint-disable no-undef */
import { debounce } from "lodash";
import { FACEBOOK_SDK_API_OPERATION } from "../../common/constants";

const facebookAPIHandler = function () {
  this.login = debounce(async function (callbackFn, scope) {
    try {
      window.FB.login(callbackFn, { scope });
    } catch (error) {
      // console.log("login FB error: ", error);
    }
  }, 2000);

  this.logout = debounce(async function (callbackFn) {
    return window.FB.logout(callbackFn);
  }, 2000);

  this.initFacebook = debounce(async function (callbackFn) {
    window.fbAsyncInit = function () {
      const params = {
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: "v15.0",
      };
      window.FB.init(params);

      window.FB.getLoginStatus((response) => {
        if (response.authResponse) {
          callbackFn(response);
        }
      });
    };

    // load facebook sdk script
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, 2000);

  this.getAccessToken = () => {
    return window.FB.getAuthResponse()?.accessToken;
  };

  this.facebookApi = async function (...args) {
    return new Promise((resolve) => {
      window.FB.api(...args, resolve);
    });
  };

  this.instagramUserDetails = async function () {
    const data = (await this.facebookApi("/me/accounts"))?.data;
    const users = await Promise.all(
      data?.length
        ? data.map(({ id, access_token }) =>
            this.facebookApi(
              `/${id}?fields=name,username,instagram_business_account&access_token=${access_token}`
            )
          )
        : [this.facebookApi("/me")]
    );
    if (data.length) {
      const instagram_pageData = [];
      users.forEach((user) => {
        if (user?.instagram_business_account) {
          instagram_pageData.push({
            ig_user_id: user?.instagram_business_account?.id,
            isInstagramLoggedIn: "true",
            fbpage_facebookid: user.id,
            fbpage_facebookName: user.name,
            fbpage_facebookUserName: user.username,
            ig_acode: this.getAccessToken(),
          });
        }
      });
      return {
        ig_acode: this.getAccessToken(),
        instagram_pageData,
      };
    }
  };

  this.facebookUserDetails = async function (isFbAdsLoggedIn) {
    try {
      const data = (await this.facebookApi("/me/accounts"))?.data;

      if (data?.length) {
        const fbPageAccountLoginData = [];
        for (let i = 0; i < data?.length; i++) {
          fbPageAccountLoginData.push({
            fbpage_facebookPageName: data[i].name,
            fbpage_facebookId: data[i]?.id,
            isFbLoggedIn: "true",
            isFbAdsLoggedIn,
            fbpage_accesstoken: data[i]?.access_token,
          });
        }

        return fbPageAccountLoginData;
      } else {
        const data = await this.facebookApi("/me");

        return data;
      }
    } catch (error) {
      // console.log("Facebook User Details: ", error);
    }
  };

  this.facebookAdsUserDetails = async function (isFbLoggedIn) {
    const adAccountsData = (await this.facebookApi("/me/adaccounts"))?.data;
    const fbPageAccountLoginData = [];
    if (adAccountsData?.length) {
      const accountsData = (await this.facebookApi("/me/accounts"))?.data;
      for (let i = 0; i < adAccountsData?.length; i++) {
        const account_id = adAccountsData[i]?.account_id;
        fbPageAccountLoginData.push({
          fbpage_facebookPageName: accountsData[i].name,
          fbpage_facebookId: accountsData[i]?.id,
          fbpage_ads_accountId: account_id,
          isFbAdsLoggedIn: "true",
          isFbLoggedIn,
          fbpage_accesstoken: accountsData[i]?.access_token,
        });
      }
      return fbPageAccountLoginData;
    } else {
      const data = (await this.facebookApi("/me"))?.data;
      return data;
    }
  };

  this.getFacebookAPIHandler = async ({
    operation,
    callbackFn,
    scope,
    isFbLoggedIn,
    isFbAdsLoggedIn,
  }) => {
    switch (operation) {
      case FACEBOOK_SDK_API_OPERATION.init:
        if (!window.FB || window.FB === undefined) {
          this.initFacebook(callbackFn);
        }
        return;
      case FACEBOOK_SDK_API_OPERATION.login:
        return this.login(callbackFn, scope);
      case FACEBOOK_SDK_API_OPERATION.logout:
        return this.logout(callbackFn);
      case FACEBOOK_SDK_API_OPERATION.instagramUserDetails:
        return this.instagramUserDetails();
      case FACEBOOK_SDK_API_OPERATION.facebookUserDetails:
        return this.facebookUserDetails(isFbAdsLoggedIn);
      case FACEBOOK_SDK_API_OPERATION.facebookAdsUserDetails:
        return this.facebookAdsUserDetails(isFbLoggedIn);
      default:
        if (!window.FB) {
          return this.initFacebook(callbackFn);
        }
        return;
    }
  };
};

export const facebookSDKAPIHandler = new facebookAPIHandler()
  .getFacebookAPIHandler;
