import React, { useRef } from "react";
import "./tooltip.scss";

const Tooltip = ({
  text,
  children,
  align = "start",
  width = "",
  searchText = "",
}) => {
  const tooltipRef = useRef(null);
  const xPos = () => {
    // currently center and end alignment is in progress will update the values accordingly
    if (align === "start") {
      return 0;
    } else if (align === "center") {
      return 0;
    } else if (align === "end") {
      return 0;
    }
  };
  return (
    <div className="tooltip ">
      <div
        className={`tooltip__content ${align === "end" ? "right-0" : ""} ${
          align === "start" ? xPos() + "%" : ""
        }  `}
        ref={tooltipRef}
        style={{
          transform: align === "center" ? `translate(-50%)` : "unset",
        }}
      >
        <div
          className="tooltip__content--arrow"
          // style={{
          //   left: xPos() + 10 + "%",
          // }}
        ></div>

        {searchText != "" ? (
          <div
            className={` ${
              width != "" ? width : "whitespace-nowrap"
            } font-normal `}
            dangerouslySetInnerHTML={{ __html: searchText }}
          />
        ) : (
          <p
            className={` ${
              width != "" ? width : "whitespace-nowrap"
            } font-normal `}
          >
            {" "}
            {text}
          </p>
        )}
      </div>
      {children}
    </div>
  );
};

export default Tooltip;
