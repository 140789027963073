import React from "react";
import PropTypes from "prop-types";

const Button = ({ label, icon, type, variant, disabled, onClick, classes }) => {
  if (variant === "filled") {
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        className={`p-2 m-2 text-base rounded scale-95 hover:scale-100 ${classes} ${
          type === "primary"
            ? "bg-primary text-onprimary"
            : "bg-secondary text-onsecondary"
        }`}
      >
        <div className="flex items-center">
          <span className="material-icons mx-1">{icon}</span>
          <span>{label}</span>
        </div>
      </button>
    );
  } else if (variant === "outline") {
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        className={`p-2 m-2 text-base rounded scale-95 hover:scale-100  ${classes} ${
          type === "primary"
            ? "border-primary text-primary hover:bg-primary hover:text-onprimary"
            : "border-secondary text-secondary hover:bg-secondary hover:text-onsecondary"
        } border`}
      >
        <div className="flex items-center">
          <span className="material-icons mx-1">{icon}</span>
          <span>{label}</span>
        </div>
      </button>
    );
  } else {
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        className={` p-2 text-base scale-95 hover:scale-100 ${classes} ${
          type === "primary"
            ? "text-primary hover:bg-primary hover:text-onprimary"
            : "text-secondary hover:bg-secondary hover:text-onsecondary"
        }`}
      >
        <div className="flex items-center">
          <span className="material-icons mx-1">{icon}</span>
          <span>{label}</span>
        </div>
      </button>
    );
  }
};

Button.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  variant: PropTypes.string,
  classes: PropTypes.string,
};

export default Button;
