import { PROFILE_OPERATION_TYPE } from "../../common/constants";
import { get, post } from "../common/axiosInstance";
import {
  PROFILE_INFO_URI,
  SAVE_LOGIN_TOKEN,
  UPDATE_PROFILE_URI,
} from "../common/endpoints";

import { profileResponseTransformer } from "./userProfile.transformer";
const profileInfo = function () {
  // gets the profile info
  this.getProfileInfo = async function (params, callback) {
    const response = await get(PROFILE_INFO_URI(params));
    callback(profileResponseTransformer(response.data["User Info"]));
  };

  // saves the token received from the login of social media
  this.saveToken = async ({ socialMediaType, params, data, isLogout }) => {
    const url = SAVE_LOGIN_TOKEN(socialMediaType, isLogout);

    const response = await post(SAVE_LOGIN_TOKEN(socialMediaType, isLogout), {
      data,
      params,
    });

    return response;
  };

  // adds the company with the data to the profile info
  this.editCompany = async (params, data, callbackFn) => {
    const response = await post(UPDATE_PROFILE_URI, {
      params: { operation: params.operationType, ...params }, // opration type = addCompany or removeCompany or editCompany
      data,
    });
    callbackFn();
    return response;
  };

  // TODO:: check security risk for this API
  // updates the profile info with any data parameter
  this.updateInfo = async (data, callbackFn) => {
    const response = await post(UPDATE_PROFILE_URI, {
      params: { operation: PROFILE_OPERATION_TYPE.updateInfo },
      data: data,
    });
    // console.log("updateInforesponse", response);
    if (response.data["Profile Info"]) {
      callbackFn(
        profileResponseTransformer(response.data["Profile Info"]["User Info"])
      );
    } else if (response.data["User Info"]) {
      callbackFn(profileResponseTransformer(response.data["User Info"]));
    }
  };

  this.profileOperation = ({
    params,
    callbackFn,
    socialMediaType,
    isLinkedInLogout,
    isHubspotLogout,
    isTiktokLogout,
    profileOperationType,
    isDiscordLogout,
    isSalesForceLogout,
    data,
  }) => {
    switch (profileOperationType) {
      case PROFILE_OPERATION_TYPE.socialMediaSaveToken:
        if (socialMediaType) {
          return this.saveToken({
            socialMediaType,
            params,
            data,
            isLinkedInLogout,
            isHubspotLogout,
            isDiscordLogout,
            isSalesForceLogout,
          });
        }
        break;

      case PROFILE_OPERATION_TYPE.socialMediaLogout:
        if (socialMediaType) {
          const isLogout =
            isLinkedInLogout ||
            isTiktokLogout ||
            isHubspotLogout ||
            isDiscordLogout ||
            isSalesForceLogout;

          return this.saveToken({ socialMediaType, params, data, isLogout });
        }
        break;

      case PROFILE_OPERATION_TYPE.updateInfo:
        return this.updateInfo(data, callbackFn);

      case PROFILE_OPERATION_TYPE.getProfileInfo:
        return this.getProfileInfo(params, callbackFn);

      case PROFILE_OPERATION_TYPE.addCompany:
      case PROFILE_OPERATION_TYPE.removeCompany:
      case PROFILE_OPERATION_TYPE.editCompany:
        return this.editCompany(params, data, callbackFn);

      default:
        return this.getProfileInfo(params, callbackFn);
    }
  };
};

export const profileOperationHandler = new profileInfo().profileOperation;
