import { INSTAGRAM_TAB_MAPPING, TIKTOK_TAB_MAPPING } from './metadata';

export const DESCRIPTION = {
  'Users Active Timeline': `"Active Hours" tracks user activity, including the timing of their mentions, on a company’s social media accounts, user’s social media accounts, hashtags, or keyword-related posts, and organizes it into one-hour slots throughout the day. This analysis helps identify peak engagement times, allowing you to optimize social media post schedule, marketing campaign scheduling and enhance overall effectiveness.`,
  "User's Active Timeline (in GMT)": `"Active Hours" tracks user activity, including the timing of their mentions, on a company’s social media accounts, user’s social media accounts, hashtags, or keyword-related posts, and organizes it into one-hour slots throughout the day. This analysis helps identify peak engagement times, allowing you to optimize social media post schedule, marketing campaign scheduling and enhance overall effectiveness.`,
  'Post/Mentions by Language': `"Post/Mentions by Language" analyzes the languages used in comments on a company’s social media accounts, user’s social media accounts, hashtags, or keyword-related posts. It provides a breakdown of comment counts by language and identifies the most prevalent ones, offering valuable insights into the linguistic preferences of your audience.`,
  'All Mentions': `"All Mentions" gathers all comments along with sentiment on a company's/user profile posts and identifies the users. It shows all the key insights like their sentiment, likes, reactions etc in a easy way to sort and filter.`,
  'Follower Stats Comparision': `"Follower Stats Comparison" monitors follower counts across various social media platforms by analyzing comments on company's social account, user's social media account, hashtags, or keyword posts. It enables the comparison of follower growth over time, enhancing brand awareness and marketing strategies.`,
  Follower: `"Follower Stats Comparison" monitors follower counts across various social media platforms by analyzing comments on company's social account, user's social media account, hashtags, or keyword posts. It enables the comparison of follower growth over time, enhancing brand awareness and marketing strategies.`,

  'Total Mentions': `"Total Mentions" provides all comments on a company’s social media accounts, user's social media accounts, hashtags, or keyword-related postsand categorizes them as positive, negative, or neutral. This helps understand overall sentiment and track changes in public perception for Brand Reputation and several Brand marketing initiatives.`,
  'Mentions By Social Media': `"Mentions By Social Media" aggregates all user comments that mention a company’s social media accounts, user's social media accounts, hashtags, or keyword-related posts, categorizing them as positive, negative, or neutral sentiment. This comprehensive analysis offers valuable insights into social media sentiment across various platforms, empowering brands to effectively monitor their reputation and optimize marketing efforts.`,
  'Top Hashtags': `"Top Hashtags" shows all comments on a company’s social media accounts, user's social media accounts, hashtags, or keyword-related posts to identify the most used positive, negative, and neutral hashtags by users. This helps track trending hashtags and sentiment for better marketing campaigns and brand awareness.`,
  'Sentiment Trend': `"The Sentiment Trend" shows all comments on a   company/user profile/hashtag/keyword posts, tracking positive, negative, and neutral sentiment over time. It helps quickly address negative issues and identify influencers boosting brand reputation.`,
  'Mentions By Location': `"Mentions By Location" shows all comments on a company’s social media accounts, user's social media accounts, hashtags, or keyword-related posts and identifies the users' locations. This helps track where discussions and engagement are happening geographically for targted marketing campaigns and several brand awareness initiatives`,
  'Viral Posts Timeline': `"Viral Posts Timeline"  identifies which posts and videos have gone viral from a  company’s social media accounts, user's social media accounts, hashtags, or keyword-related posts, based on collected comments. This helps highlight the most impactful posts for effective marketing campaigns and address brand reputation issues quickly.`,
  'Engagement Trend Summary': `"The Engagement Trend Summary" analyzes comments on a company’s social media accounts, user's social media acocunts, hashtags, or keyword-related posts to track metrics such as likes, engagement percentage, positive mentions, shares, and play counts. This helps measure overall interaction and popularity, offering valuable insights to boost marketing efforts and enhance brand awareness.`,
  'Hashtag Trend': `"Top Hashtags Trend" tracks and aggregates comments on a company’s social media accounts, user’s social media accounts, hashtags, or keyword-related posts to identify trending hashtags over time, categorized by sentiment—positive, negative, or neutral. This trend analysis highlights the most popular hashtags and sentiment shifts, enabling you to stay ahead of emerging trends and enhance your marketing strategies for improved brand visibility and engagement.`,
  'Aspect Sentiment': `"Aspect Sentiment " analyzes comments on a company’s social media accounts, user’s social media accounts, hashtags, or keyword-related posts to track trends in sentiment related to specific aspects—positive, negative, and neutral. This analysis helps quickly identify emerging issues and opportunities for improving brand reputation and customer service, while also highlighting positive feedback and influential supporters.`,
  'Top Aspect Sentiment': `"Aspect Sentiment " analyzes comments on a company’s social media accounts, user’s social media accounts, hashtags, or keyword-related posts to track trends in sentiment related to specific aspects—positive, negative, and neutral. This analysis helps quickly identify emerging issues and opportunities for improving brand reputation and customer service, while also highlighting positive feedback and influential supporters.`,
  'Top Aspect Sentiment Trend': `"Aspect Sentiment Trend" analyzes comments on a company’s social media accounts, user’s social media accounts, hashtags, or keyword-related posts to track trends in sentiment related to specific aspects—positive, negative, and neutral—over time. This analysis helps quickly identify emerging issues and opportunities for improving brand reputation and customer service, while also highlighting positive feedback and influential supporters.`,
  'Aspect Sentiment Trend': `"Aspect Sentiment Trend" analyzes comments on a company’s social media accounts, user’s social media accounts, hashtags, or keyword-related posts to track trends in sentiment related to specific aspects—positive, negative, and neutral—over time. This analysis helps quickly identify emerging issues and opportunities for improving brand reputation and customer service, while also highlighting positive feedback and influential supporters.`,
  'Entities Trend': `"Top Entities Trend" analyzes comments on a company’s social media accounts, user’s social media accounts, hashtags, or keyword-related posts to identify which entities—such as people, places, and locations—are trending over time, categorized by sentiment—positive, negative, or neutral. This trend analysis highlights the most frequently mentioned entities and their associated sentiment, offering valuable insights for monitoring public perception and enhancing brand awareness.`,
  'Sentiment Category Timeline': `"Sentiment Category Timeline" shows all comments on company’s social media accounts, user's social media accounts, hashtags, or keyword-related posts, showing the trends of positive, negative, and neutral sentiments over time. It helps to identify the negative issues quickly for better brand reputaion and customer services and much more. Also, helps to understand the positive comments and idenitfy the influencers supporting Brand Reputation.`,
  'Followers Trend Stats': `"Follower Stats Comparison" monitors follower counts across various social media platforms by analyzing comments on company's social account, user's social media account, hashtags, or keyword posts. It enables the comparison of follower growth over time, enhancing brand awareness and marketing strategies.`,
  'Sentiments Score': `"Sentiment Score" aggregates sentiment data from comments across all social media platforms related to a company, user profiles, hashtags, and keywords, encompassing positive, negative, and neutral emotions. This comprehensive score allows for comparative analysis of sentiment trends across different platforms, helping to identify issues, refine brand strategies, and gauge overall public perception.`,
  'Mentions Count By Country': `"Mentions By Location" shows all comments on a company’s social media accounts, user's social media accounts, hashtags, or keyword-related posts and identifies the users' locations. This helps track where discussions and engagement are happening geographically for targted marketing campaigns and several brand awareness initiatives`,
  'Mentions Count By City': `"Mentions By Location" shows all comments on a company’s social media accounts, user's social media accounts, hashtags, or keyword-related posts and identifies the users' locations. This helps track where discussions and engagement are happening geographically for targted marketing campaigns and several brand awareness initiatives`,
  'Image Categories Trend': `"Top Image Categories Trend" tracks the most frequently shared image types over time (Months, Weeks,Days) in posts across a company’s social media accounts, user’s social media accounts, hashtags, or keyword-related content. By analyzing these trends, it highlights the logos, key objects, and visuals that consistently resonate with your audience, helping companies and users adjust their content strategy to stay aligned with current preferences and maximize engagement.`,
  'Engagement Percentage Trend': `"The Engagement Percentage Trend" provides key engagement metrics by analyzing comments on a company’s social media accounts, user's social media acocunts, hashtags, or keyword-related posts to track metrics such as likes, engagement percentage, positive mentions, shares, and play counts. This helps measure overall interaction and popularity, offering valuable insights to boost marketing efforts and enhance brand awareness.`,
  'Post Timeline Trend': `"Post Trends" chart tracks the likes and mentions of posts on a company’s social media accounts, user’s social media accounts, hashtags, or keyword-related content over time. By offering insights into engagement trends for each post, this tool enables businesses to identify what resonates with their audience and refine their content strategy to boost engagement.`,
  'Top Image Categories': `"Top Image Categories" identifies the most frequently shared image types in posts across a company’s social media accounts, user’s social media accounts, hashtags, or keyword-related content. This analysis helps pinpoint the logos, key objects, and visuals that resonate most with your audience, enabling companies and users to refine their content strategy for greater engagement.`,
  'Average Sentiment Timeline': `"Average Sentiment Timeline" shows all comments on company’s social media accounts, user's social media accounts, hashtags, or keyword-related posts, showing the trends of positive, negative, and neutral sentiments over time (Months, Weeks, Days) . It helps to identify the negative issues quickly for better brand reputaion and customer services and much more. Also, helps to understand the positive comments and idenitfy the influencers supporting Brand Reputation.`,
  'Total Followers': `"Follower Stats Comparison" monitors follower counts across various social media platforms by analyzing comments on company's social account, user's social media account, hashtags, or keyword posts. It enables the comparison of follower growth over time, enhancing brand awareness and marketing strategies.`,
  'Social Media Sentiment Timeline': `"The Sentiment Trend" shows all comments on a   company/user profile/hashtag/keyword posts, tracking positive, negative, and neutral sentiment over time. It helps quickly address negative issues and identify influencers boosting brand reputation.`,
  'Social Media Sentiment': `"The Sentiment Trend" shows all comments on a   company/user profile/hashtag/keyword posts, tracking positive, negative, and neutral sentiment over time. It helps quickly address negative issues and identify influencers boosting brand reputation.`,
  'Top Comments Multi Company': `"Total Mentions" provides all comments on a company’s social media accounts, user's social media accounts, hashtags, or keyword-related postsand categorizes them as positive, negative, or neutral. This helps understand overall sentiment and track changes in public perception for Brand Reputation and several Brand marketing initiatives.`,
  'AI Video Insights and Viral Posts': `"Viral Posts Timeline"  identifies which posts and videos have gone viral from a  company’s social media accounts, user's social media accounts, hashtags, or keyword-related posts, based on collected comments. This helps highlight the most impactful posts for effective marketing campaigns and address brand reputation issues quickly.`,

  // Linkedin
  'LinkedIn Public Page Mention Insights': `"Linkedin Mention Insights' provides all comments on a company’s social media accounts, user's social media accounts, hashtags, or keyword-related posts and categorizes them as positive, negative, or neutral. This helps understand overall sentiment and track changes in public perception for Brand Reputation and several Brand marketing initiatives.`,
  Entities: `"Top Entities" analyzes all comments on a company’s social media accounts, user's social media accounts, hashtags, or keyword-related posts to identify the most frequently mentioned entities—such as people, places, and locations—categorized by sentiment as positive, negative, or neutral. This tool helps track trending entities and their associated sentiment, providing valuable insights for monitoring public perception and Brand awareness.`,
  'Top Entities': `"Top Entities" analyzes all comments on a company’s social media accounts, user's social media accounts, hashtags, or keyword-related posts to identify the most frequently mentioned entities—such as people, places, and locations—categorized by sentiment as positive, negative, or neutral. This tool helps track trending entities and their associated sentiment, providing valuable insights for monitoring public perception and Brand awareness.`,
  'Job Seniority - by User Mentions': `"Job Seniority - by User Mentions" categorizes the job titles of individuals mentioning a company, social accoun user social account, hashtag, or keyword-related posts — ranging from managers to owners and beyond. By aggregating vast numbers of comments, it reveals the seniority levels of those interacting, providing valuable insights that enhance brand awareness, inform marketing strategies, and support other key initiatives.`,
  'Top Influencers': `"Top Influencers" identifies key user accounts from a vast array of posts about a company, hashtags, and keyword-related content, highlighting their level of engagement and sentiment. It showcases these key influencers by detailing their engagement metrics, posting frequency, average likes, comments, and overall sentiment, providing valuable insights to enhance brand reputation, drive marketing strategies, and more.`,
  'Active Followers': `"Active Followers" tracks individuals who mention a company, user account, hashtag, or keyword, highlighting their level of engagement and sentiment. By analyzing their interactions, this feature provides insights into the nature of their influence, helping to enhance brand reputation, refine marketing strategies, and more.`,
  'User Demographics': `"User Demographics" identifies the locations of users including their country and city (when available) and their sentiment positive, neutral, negative based on their mentions of a company’s social account, user social account, hashtag, or keyword posts.  These helps valuable insights into audience's geographic distribution enable more precise marketing, strengthen brand awareness, and enhance reputation management.`,
  'Job Seniority - by User Likes': `"Job Seniority - by User Mentions" categorizes the job titles of individuals liking a company social account, user social account, hashtag, or keyword-posts — ranging from managers to owners and beyond. By aggregating vast numbers of comments, it reveals the seniority levels of those interacting, providing valuable insights that enhance brand awareness, inform marketing strategies, and support other key initiatives.`,
  'Linkedin Mention Insights': `"Linkedin Mention Insights' provides all comments on a company’s social media accounts, user's social media accounts, hashtags, or keyword-related posts and categorizes them as positive, negative, or neutral. This helps understand overall sentiment and track changes in public perception for Brand Reputation and several Brand marketing initiatives.`,
  'Mentions Sentiment Trend': `"The Sentiment Trend" shows all comments on a   company’s social media accounts, user's social media accounts, hashtags, or keyword-related posts, tracking positive, negative, and neutral sentiment over time. It helps quickly address negative issues and identify influencers boosting brand reputation.`,
  'Mentions Sentiment': `"The Sentiment Trend" shows all comments on a   company’s social media accounts, user's social media accounts, hashtags, or keyword-related posts, tracking positive, negative, and neutral sentiment over time. It helps quickly address negative issues and identify influencers boosting brand reputation.`,
  'Mentions/LIkers Count by Region': `"Mentions/LIkers Count by Region identifies the locations of users by Region based on their mentions of a company’s social account, user social account, hashtag, or keyword posts.  These helps valuable insights into audience's geographic distribution enable more precise marketing, strengthen brand awareness, and enhance reputation management.`,
  'Follower Trend': `The "Followers Trend Chart" visualizes the growth and fluctuation of followers over time (Months, Weeks,Days) for a company’s social media accounts, user's social media accounts. It tracks the number of followers gained or lost, providing insights into trends, peaks, and declines in follower count. This chart helps identify the impact of specific campaigns, content, or events on follower growth, offering valuable data for optimizing marketing strategies and improving brand engagement. `,
  'Follower Growth Trend': `The "Followers Growth Chart" visualizes the growth and fluctuation of followers over time (Months, Weeks,Days) for a company’s social media accounts, user's social media accounts. It tracks the number of followers gained or lost, providing insights into trends, peaks, and declines in follower count. This chart helps identify the impact of specific campaigns, content, or events on follower growth, offering valuable data for optimizing marketing strategies and improving brand engagement. `,
  'Mentions/Likers Count by Region': `"Mentions By Location" shows all comments on a company’s social media accounts, user's social media accounts, hashtags, or keyword-related posts and identifies the users' locations. This helps track where discussions and engagement are happening geographically for targted marketing campaigns and several brand awareness initiatives`,
  'User Demographics of Mentions/Likers by Country': `"User Demographics" identifies the locations of users including their country and city (when available) and their sentiment positive, neutral, negative based on their mentions of a company’s social account, user social account, hashtag, or keyword posts.  These helps valuable insights into audience's geographic distribution enable more precise marketing, strengthen brand awareness, and enhance reputation management.`,
  'User Demographics of Mentions/Likers by City': `"User Demographics" identifies the locations of users including their country and city (when available) and their sentiment positive, neutral, negative based on their mentions of a company’s social account, user social account, hashtag, or keyword posts.  These helps valuable insights into audience's geographic distribution enable more precise marketing, strengthen brand awareness, and enhance reputation management.`,
};

export const HELP_DESCRIPTION = {
  'Real-time Top Actionable Insights':
    'Provides Top Actionable Insights like Senitment Trend, Top Hashtags, Top Mentions, Menitons by Language.',
  Filters: `<div>
  <p>Choose your filters for Date, Boolean Search.</p>
  <p>Date: Choose No. of days or custom date range you need insights (e.g., 2 days or from and to date).</p>
  <p>You can select Day/Week/Month for analyzing the data view.</p>
  <p>Boolean Search: Use simple or complex boolean with AND, OR with multiple words.</p>
</div>`,
  'Social Media & Global Web Actionable Insights':
    'Provides detailed Actionable Insights like Video Insights, Viral Posts, Sentiment Trend, Followers Trend, User Demographics,  Top Influncers, Top Mentions, Entities, Image Insights and much more',
  "User's Intent on Buying Signals, Trends":
    "Insights on User's intent on buying signals, trending posts, mentions.",
  'LinkedIn Real-time Top Actionable Insights':
    'Provides Top Actionable Insights like Senitment Trend, User Demograhics, Followers Trend, Top Influencers, Top Hashtags, Top Mentions, Menitons by Language.',
  "LinkedIn Actionable Insights on Post's Mentions":
    'Provides detailed insights on posts with mentions sentiment, engagement metrics (likes, reactions)',
};
export const getRelevantTabsKeyWord = (socialMedia, searchObject) => {
  if (socialMedia === 'tiktok') {
    return TIKTOK_TAB_MAPPING.filter((tab) => {
      if (tab.value === 'userposts' && searchObject.userAccount) {
        return true;
      }
      if (tab.value === 'userhashtags' && searchObject.hashtag) {
        return true;
      }
      if (tab.value === 'keywordposts' && searchObject.keyword) {
        return true;
      }
      return false;
    });
  } else if (socialMedia === 'instagram') {
    return INSTAGRAM_TAB_MAPPING.filter((tab) => {
      if (tab.value === 'manual_users' && searchObject.userAccount) {
        return true;
      }
      if (tab.value === 'manual_hashtags' && searchObject.hashtag) {
        return true;
      }
      if (tab.value === 'manual_tags' && searchObject.userTag) {
        return true;
      }
      return false;
    });
  }
  return [];
};
export const SOCIAL_MEDIA_COLOR = {
  facebook: '#3690E9',
  instagram: '#d43c7d',
  reddit: '#FF4500',
  tiktok: '#505050',
  twitter: '#fe6a35',
  youtube: '#6A0D91',
  articles: '#3b5998',
};

/* SERVICE LAYER CONSTANTS */
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export const SOCIAL_MEDIA_TYPE = {
  twitter: 'twitter',
  facebook: 'facebook',
  reddit: 'reddit',
  instagram: 'instagram',
  articles: 'articles',
  youtube: 'youtube',
  tiktok: 'tiktok',
  linkedin: 'linkedin',
  linkedinPublicPage: 'linkedinPublicPage',
  facebookAds: 'facebookAds',
  trustpilot: 'trustPilotcompanyName',
  commonforAll: 'socialMedia',
};
export const SOCIAL_MEDIA_NAMES = {
  twitter: 'Twitter',
  facebook: 'Facebook',
  reddit: 'Reddit',
  instagram: 'Instagram',
  news: 'News',
  youtube: 'Youtube',
  tiktok: 'TikTok',
  linkedin: 'LinkedIn',
  facebookAds: 'FacebookAds',
  hubspot: 'HubSpot',
  discord: 'Discord',
  salesforce: 'salesforce',
  linkedinPublicPageHandler: 'LinkedInPublicPage',
};

export const SOCIAL_MEDIA_SUBTAB_PARAM = {
  instagram: 'ig_type',
  tiktok: 'tiktok_type',
};

export const SOCIAL_MEDIA_COMMENTS_TYPE = {
  twitter: 'externalTweets',
  facebook: 'comments',
  reddit: 'comments',
  instagram: 'comments',
  news: 'articles',
  youtube: 'comments',
  tiktok: 'comments',
  articles: 'articles',
  linkedin: 'comments',
  facebookAds: 'comments',
};

export const ORGANIZATION_STATS_DATA = {
  commentMentionsCount: 'commentMentionsCount',
  impressionCount: 'impressionCount',
  shareCount: 'shareCount',
  clickCount: 'clickCount',
  likeCount: 'likeCount',
  shareMentionsCount: 'shareMentionsCount',
  uniqueImpressionsCount: 'uniqueImpressionsCount',
};
//we have interval paramater which is sued in apis to show in the application, which will definte the chartd ata base don day, week, total. we are defining the constants here
export const INTERVAL = {
  total: 'total',
  day: 'day',
  week: 'week',
  month: 'month',
  year: 'year',
};
//in our application,  we have different tabs like active followers, sentiment trend etc. Here we are defining the constants.
export const TYPE = {
  videolocationcountdetails: 'videolocationcountdetails',
  videokeytopiccountdetails: 'videokeytopiccountdetails',
  videokeywordcountdetails: 'videokeywordcountdetails',
  videoemotioncountdetails: 'videoemotioncountdetails',
  videoentitiescountdetails: 'videoentitiescountdetails',
  videoobjectscountdetails: 'videoobjectscountdetails',
  videobrandscountdetails: 'videobrandscountdetails',
  videoocrtextcountdetails: 'videoocrtextcountdetails',
  countrycountdetails: 'countrycountdetails',
  citycountdetails: 'citycountdetails',
  sentiment: 'sentiment',
  locationcountdetails: 'locationcountdetails',
  engagementrate: 'engagementrate',
  detailed: 'detailed',
  signalsDetailed: 'signaldetails',
  categories: 'categories',
  // hashtags: "hashtags",
  influencerlocationgroup: 'influencerlocationgroup',
  userhashtags: 'hashtagsviewinfo',
  hashtags: 'hashtagsbysentimentbycount',
  hashtagsDetail: 'hashtagsbysentiment',
  categorySentiment: 'categorysentiment',
  aspectsentiment: 'aspectsentiment',
  entities: 'entities',
  followers: 'followers',
  instafollowers: 'instafollowers',
  influencers: 'influencers',
  activefollowers: 'activefollowers',
  posts: 'posts',
  followersbycountry: 'followersbycountry',
  activehours: 'activehours',
  emotion: 'emotion',
  topinfluencers: 'topinfluencers',
  keystats: 'keystats',
  activitystats: 'activitystats',
  imageanalysis: 'imageanalysis',
  imageanalysisdetail: 'imageanalysisdetails',
  aspectdetails: 'aspectsentimentdetails',
  entitiesdetail: 'entitiesdetails',
  viralposts: 'viralpostbasedonengagement',
  details: 'details',
  followerslist: 'followerslist',
  postswithcomments: 'postswithcomments',
  postswithoutcomments: 'postswithoutcommentsandlikers',
  postswithoutcommentsandlikers: 'postswithoutcommentsandlikers',
  postswithlatestcommentsandlikers: 'postswithoutcommentsandlikers',
  commentersenioritygroup: 'commentersenioritygroup',
  commentercategorygroup: 'commentercategorygroup',
  languagecountdetails: 'languagecountdetails',
  locationcountoflikersdetails: 'locationcountoflikersdetails',
  categorysentiment: 'categorysentiment',
  categoriesdetails: 'categoriesdetails',
};

// eslint-disable-next-line no-undef
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;

// export const FACEBOOK_LOGIN_SCOPE =
//   "ads_management,email,pages_messaging,read_insights,pages_manage_ads,pages_manage_metadata,pages_read_engagement,pages_read_user_content,pages_manage_posts,pages_manage_engagement,pages_show_list,public_profile";
export const FACEBOOK_LOGIN_SCOPE = 'email,public_profile';
export const FACEBOOK_ADS_LOGIN_SCOPE =
  'ads_read,pages_show_list,ads_management,pages_manage_posts,pages_manage_ads,pages_read_engagement,pages_manage_metadata,pages_manage_engagement,pages_messaging,catalog_management,pages_read_user_content';
export const INSTAGRAM_LOGIN_SCOPE =
  'instagram_manage_insights,instagram_basic,instagram_manage_comments,pages_show_list,pages_read_engagement,ads_read,pages_show_list,ads_management,pages_manage_posts,pages_manage_ads,pages_read_engagement,pages_manage_metadata,pages_manage_engagement,pages_messaging,catalog_management,pages_read_user_content';

export const FACEBOOK_SDK_API_OPERATION = {
  login: 'login',
  logout: 'logout',
  init: 'init',
  instagramUserDetails: 'instagramUserDetails',
  facebookUserDetails: 'facebookUserDetails',
  facebookAdsUserDetails: 'facebookAdsUserDetails',
};

export const SAVE_LOGIN_TOKEN_CHANNEL = {
  facebook: 'facebook',
  facebookads: 'facebookads',
  instagram: 'instagram',
  facebookadsbots: 'facebookadsbots',
  linkedin: 'linkedin',
  tiktok: 'tiktok',
  hubspot: 'hubspot',
  discord: 'discord',
  salesforce: 'salesforce',
};

export const USER_PROFILE_OPERATION = {
  profileInfo: 'profileInfo',
  updateProfileInfo: 'updateProfileInfo',
};

export const CHART_TYPE_NAME = {
  postIntent: 'Buyer Intent/Signals',
  // postIntent: "Buyer Intent/Signals",
  AspectSentimentTrendChart: 'Aspect Sentiment Trend Chart',
  twitterFacebookSentimentTimeline: 'Social Media Sentiment Timeline',
  globalNewsByCategories: 'Global News by Categories',
  followerComparisionChart: 'Followers Comparision Chart',
  latestNewsCluster:
    'Timeline of latest News by Clusters (Risk, Opportunity, Warnings etc)',
  sentimentDialChart: {
    twitter: 'Twitter Sentiment',
    facebook: 'Facebook Sentiment',
    reddit: 'Reddit Sentiment',
    articles: 'News Sentiment',
  },
  videoemotioncountdetails: 'videoemotioncountdetails',
  location: 'User Demographics of Mentions By Country',
  citycountdetails: 'User Demographics of Mentions By City',
  sentimentByCategory: 'Sentiment by Catergory',
  sentimentTrendLines: 'Average Sentiment Timeline',
  engagementTrendLines: 'Engagement Percentage Trend',
  followersTrendLines: 'Followers Trend',
  followersTimeline: 'Followers Timeline',
  followersReach: 'Followers Reach',
  sentimentTrendBars: 'Sentiment Category Timeline',
  mentiontimelineSummary: 'Engagement Trend Summary',
  mentiontimelineHistroy: 'Post Timeline Trend',
  wordCloudChart: {
    topHashTags: 'Top Hashtags',
    topAspectSentiment: 'Top Aspect Sentiment',
    positiveAspectSentiment: 'Positive Aspect Sentiment',
    negativeAspectSentiment: 'Negative Aspect Sentiment',
    neutralAspectSentiment: 'Neutral Aspect Sentiment',
    neutralEntities: 'Neutral Entities',
    positiveEntities: 'Entities',
    negativeEntities: 'Negative Entities',
  },
  FollowersLineChart: {
    twitter: 'Twitter Followers',
    facebook: 'Facebook Followers',
    reddit: 'Reddit Followers',
    articles: 'News Followers',
    youtube: 'Youtube Followers',
    instagram: 'Instagram Followers',
  },
  engagementEntries: 'Engagement Entries Stats',
  topFollowers: 'Top Followers',
  influencerMentions: 'Influencer Mentions',
  followersByCountry: 'Followers By Country',
  userActiveTimeline: "User's Active Timeline (in GMT)",
  emotion: 'Emotion Insights',
  emotionDailyTrend: 'Emotion Daily Trend',
  emotionInsights: 'Emotion Insights',
  followers: 'Followers',
  engagementStats: 'Engagement Stats',
  userActivity: '7 Days User Activity',
  weeklyUserActivity: 'Weekly User Activity',
  topinfluencersTreeMap: 'Top Influencers Ranking',
  campaignStatsTrend: 'Campaign Stats Trend',
  campaignStatsDetails: 'Campaign Stats Details',
  adsStatsTrend: 'Ads Stats Trend',
  adsStatsDetails: 'Ads Stats Details',
  adsetsStatsTrend: 'Adsets Stats Trend',
  adsetsStatsDetails: 'Adsets Stats Details',
  topImageCategories: 'Top Image Categories',
  imageCategoriesTrend: 'Image Categories Trend',
  hashtagTrend: 'Hashtag Trend',
  userHashtags: 'User Hashtags',
  language: 'Post/Mentions by Language',
};

export const DAYS = 'Days';

export const CUSTOM_RANGE = 'Custom_Range';

export const INSTAGRAM_TYPE = [
  'manual_users',
  'manual_tags',
  'manual_hashtags',
];

export const FACEBOOK_TYPE = ['userpost', 'keywords'];

export const PROFILE_OPERATION_TYPE = {
  getProfileInfo: 'getProfileInfo',
  socialMediaSaveToken: 'socialMediaSaveToken',
  addCompany: 'addCompany',
  removeCompany: 'removeCompany',
  editCompany: 'editCompany',
  updateInfo: 'updateInfo',
  socialMediaLogout: 'socialMediaLogout',
};

export const INSIGHTS_TYPE = {
  favouriteDashboard: 'favouriteDashboard',
  earlyWarningInsights: 'earlyWarningInsights',
  actionableInsights: 'actionableInsights',
  facebookAdsInsights: 'facebookAdsInsights',
  linkedInInsights: 'linkedInInsightss',
  portfolioInsights: 'portfolioInsights',
};

export const TIKTOK_TYPE = ['userposts', 'userhashtags', 'keywordposts'];
export const YOUTUBE_TYPE = ['channel', 'keyword'];

export const MAP_TYPE = { country: 'Country', region: 'Region' };

export const ACCORDIAN_EXPAND_TEXT = {
  showTweets: { show: 'Show Tweets', hide: 'Hide Tweets' },
  showMentions: { show: 'Show Mentions', hide: 'Hide Mentions' },
  showPosts: { show: 'Show Posts', hide: 'Hide Posts' },
};

export const TABLE_COMMENT_HREF_PLACEHOLDER_TEXT = {
  instagram: { placeholder: 'Show Comment' },
  reddit: { placeholder: 'Show Comment' },
  facebook: { placeholder: 'Show Comment' },
  tiktok: { placeholder: 'Show Comment' },
  twitter: { placeholder: 'Show Comment' },
  facebookAds: { placeholder: 'Show Comment' },
  linkedin: { placeholder: 'Show Comment' },
  [SOCIAL_MEDIA_TYPE.linkedinPublicPage]: { placeholder: 'Show Comment' },
  articles: { placeholder: 'Show Article' },
  youtube: { placeholder: 'Show Video' },
};
export const TABLE_COMMENT_LEAD = {
  instagram: { Lead: ' Add Lead' },
  reddit: { Lead: 'Add Lead' },
  facebook: { Lead: 'Add Lead' },
  tiktok: { Lead: 'Add Lead' },
  twitter: { Lead: 'Add Lead' },
  facebookAds: { Lead: 'Add Lead' },
  linkedin: { Lead: 'Add Lead' },
  articles: { Lead: 'Add Lead' },
  youtube: { Lead: 'Add Lead' },
  linkedinPublicPage: { Lead: 'Add Lead' },
};
export const TABLE_LEAD = {
  twitter: { Lead: 'Add as Lead' },
};

export const MENTIONTIMELINE_SUMMARY_MAPPING = {
  twitter: [
    {
      type: 'line',
      x: 'Date',
      y: 'Likes',
      name: 'Likes',
      yaxis: 'nsty2',
      color: '#050505',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'Followers',
      name: 'Followers',
      color: '#04b9a6',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'Engagement',
      name: 'Engagement',
      color: '#1c8299',
    },

    {
      type: 'bar',
      x: 'Date',
      y: 'Mentions',
      name: 'Mentions',
      color: '#006d3f',
    },

    {
      type: 'bar',
      x: 'Date',
      y: 'ShareCounts',
      name: 'Share Counts',
      color: '#1c5d99',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'totalPostCount',
      name: 'Post Counts',
      color: '#050505',
    },
  ],
  tiktok: [
    {
      type: 'bar',
      x: 'Date',
      y: 'Likes',
      name: 'Likes',
      yaxis: 'nsty2',
      color: '#050505',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'Mentions',
      name: 'Mentions',
      color: '#006d3f',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'Engagement',
      name: 'Engagement',
      color: '#1c8299',
    },

    {
      type: 'bar',
      x: 'Date',
      y: 'EngagementPercentage',
      name: 'Engagement %',
      color: '#1c5d99',
    },

    {
      type: 'bar',
      x: 'Date',
      y: 'ShareCounts',
      name: 'Share Counts',
      color: '#1c5d99',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'PlayCount',
      name: 'Play Count',
      color: '#009934',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'totalPostCount',
      name: 'Post Counts',
      color: '#050505',
    },
  ],
  linkedinPublicPage: [
    {
      type: 'bar',
      x: 'Date',
      y: 'Likes',
      name: 'Likes',
      yaxis: 'nsty2',
      color: '#009934',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'Mentions',
      name: 'Mentions',
      color: '#006d3f',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'Engagement',
      name: 'Engagement',
      color: '#1c8299',
    },

    {
      type: 'bar',
      x: 'Date',
      y: 'EngagementPercentage',
      name: 'Engagement %',
      color: '#1c5d99',
    },

    {
      type: 'bar',
      x: 'Date',
      y: 'ShareCounts',
      name: 'Share Counts',
      color: '#1c5d99',
    },

    {
      type: 'bar',
      x: 'Date',
      y: 'totalPostCount',
      name: 'Post Counts',
      color: '#050505',
    },
  ],
  facebook: [
    {
      type: 'line',
      x: 'Date',
      y: 'Likes',
      name: 'Likes',
      yaxis: 'nsty2',
      color: '#050505',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'Followers',
      name: 'Followers',
      color: '#04b9a6',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'Engagement',
      name: 'Engagement',
      color: '#1c8299',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'Mentions',
      name: 'Mentions',
      color: '#1c5d99',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'ShareCounts',
      name: 'Share Counts',
      color: '#1c5d99',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'totalPostCount',
      name: 'Post Counts',
      color: '#050505',
    },
  ],
  youtube: [
    {
      type: 'line',
      x: 'Date',
      y: 'Likes',
      name: 'Likes',
      yaxis: 'nsty2',
      color: '#050505',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'Followers',
      name: 'Followers',
      color: '#04b9a6',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'Engagement',
      name: 'Engagement',
      color: '#1c8299',
    },

    {
      type: 'bar',
      x: 'Date',
      y: 'Mentions',
      name: 'Mentions',
      color: '#006d3f',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'ShareCounts',
      name: 'Share Counts',
      color: '#1c5d99',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'totalPostCount',
      name: 'Post Counts',
      color: '#050505',
    },
  ],
  instagram: [
    {
      type: 'line',
      x: 'Date',
      y: 'Likes',
      name: 'Likes',
      yaxis: 'nsty2',
      color: '#050505',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'Followers',
      name: 'Followers',
      color: '#04b9a6',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'Engagement',
      name: 'Engagement',
      color: '#1c8299',
    },

    {
      type: 'bar',
      x: 'Date',
      y: 'Mentions',
      name: 'Mentions',
      color: '#006d3f',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'ShareCounts',
      name: 'Share Counts',
      color: '#1c5d99',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'totalPostCount',
      name: 'Post Counts',
      color: '#050505',
    },
  ],
  reddit: [
    {
      type: 'line',
      x: 'Date',
      y: 'Likes',
      name: 'Likes',
      yaxis: 'nsty2',
      color: '#050505',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'Followers',
      name: 'Followers',
      color: '#04b9a6',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'Engagement',
      name: 'Engagement',
      color: '#1c8299',
    },

    {
      type: 'bar',
      x: 'Date',
      y: 'Mentions',
      name: 'Mentions',
      color: '#006d3f',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'Mentions',
      name: 'Mentions',
      color: '#1c5d99',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'ShareCounts',
      name: 'Share Counts',
      color: '#1c5d99',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'totalPostCount',
      name: 'Post Counts',
      color: '#050505',
    },
  ],
  articles: [
    {
      type: 'line',
      x: 'Date',
      y: 'Likes',
      name: 'Likes',
      yaxis: 'nsty2',
      color: '#050505',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'Followers',
      name: 'Followers',
      color: '#04b9a6',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'Engagement',
      name: 'Engagement',
      color: '#1c8299',
    },

    {
      type: 'bar',
      x: 'Date',
      y: 'Mentions',
      name: 'Mentions',
      color: '#006d3f',
    },

    {
      type: 'bar',
      x: 'Date',
      y: 'ShareCounts',
      name: 'Share Counts',
      color: '#1c5d99',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'totalPostCount',
      name: 'Post Counts',
      color: '#050505',
    },
  ],
};
export const MENTIONTIMELINE_HISTROY_MAPPING = {
  twitter: [
    {
      type: 'line',
      x: 'Date',
      y: 'Likes',
      name: 'Likes',
      yaxis: 'nsty2',
      color: '#050505',
    },

    {
      type: 'bar',
      x: 'Date',
      y: 'Comments',
      name: 'Comments',
      color: '#1c5d99',
    },
  ],
  tiktok: [
    {
      type: 'bar',
      x: 'Date',
      y: 'Likes',
      name: 'Likes',
      yaxis: 'nsty2',
      color: '#050505',
    },
    {
      type: 'bar',
      x: 'Date',
      y: 'Comments',
      name: 'Comments',
      color: '#04b9a6',
    },
  ],
  facebook: [
    {
      type: 'line',
      x: 'Date',
      y: 'Likes',
      name: 'Likes',
      yaxis: 'nsty2',
      color: '#050505',
    },

    {
      type: 'bar',
      x: 'Date',
      y: 'Comments',
      name: 'Comments',
      color: '#1c5d99',
    },
  ],
  instagram: [
    {
      type: 'line',
      x: 'Date',
      y: 'Likes',
      name: 'Likes',
      yaxis: 'nsty2',
      color: '#050505',
    },

    {
      type: 'bar',
      x: 'Date',
      y: 'Comments',
      name: 'Comments',
      color: '#1c5d99',
    },
  ],
  reddit: [
    {
      type: 'line',
      x: 'Date',
      y: 'Likes',
      name: 'Likes',
      yaxis: 'nsty2',
      color: '#050505',
    },

    {
      type: 'bar',
      x: 'Date',
      y: 'Comments',
      name: 'Comments',
      color: '#1c5d99',
    },
  ],
  articles: [
    {
      type: 'line',
      x: 'Date',
      y: 'Likes',
      name: 'Likes',
      yaxis: 'nsty2',
      color: '#050505',
    },

    {
      type: 'bar',
      x: 'Date',
      y: 'Comments',
      name: 'Comments',
      color: '#1c5d99',
    },
  ],
  youtube: [
    {
      type: 'line',
      x: 'Date',
      y: 'Likes',
      name: 'Likes',
      yaxis: 'nsty2',
      color: '#050505',
    },

    {
      type: 'bar',
      x: 'Date',
      y: 'Comments',
      name: 'Comments',
      color: '#1c5d99',
    },
  ],
};
export const HISTORICAL_STATS_MAPPING = [
  {
    type: 'lines',
    x: 'Date',
    y: 'Likes',
    name: 'Likes',
    yaxis: 'nsty2',
    color: '#050505',
  },
  {
    type: 'lines',
    x: 'Date',
    y: 'Comments',
    name: 'Comments',
    color: '#006d3f',
  },
  {
    type: 'lines',
    x: 'Date',
    y: 'comments_to_likes_ratio',
    name: 'comments_to_likes_ratio',
    color: '#06adcf',
  },
  {
    type: 'lines',
    x: 'Date',
    y: 'engagement_rate',
    name: 'engagement_rate',
    color: '#b0390b',
  },
  {
    type: 'lines',
    x: 'Date',
    y: 'followers_count',
    name: 'followers_count',
    color: '#960631',
  },
  // {
  //   type: "lines",
  //   x: "Date",
  //   y: "following_count",
  //   name: "following_count",
  //   color: "#009934",
  //   fill: 'tozeroy',
  // },
  // {
  //   type: "lines",
  //   x: "Date",
  //   y: "weekly_posts",
  //   name: "weekly_posts",
  //   color: "#009934",
  //   fill: 'tozeroy',
  // },
  {
    type: 'lines',
    x: 'Date',
    y: 'media_count',
    name: 'media_count',
    color: '#d1db16',
  },
];

export const LIKERS_Field = {
  instagram: {},
  linkedinPublicPage: {},
};

export const testCompanyData = [
  {
    group_name: 'Group1',
    companies_list: [
      {
        masterSearch: 'nestle',
        id: '116b4f05-2cec-4840-9a63-f0556c5519fe',
        language: 'en',
        tiktokType: {
          userAccount: 'nestle',
          userTag: 'nestle',
          keyword: 'nestle',
          hashtag: 'nestle',
        },
        instagramType: {
          userAccount: 'nestle',
          userTag: 'nestle',
          keyword: '',
          hashtag: 'nestle',
        },
        tiktokOptions: 'userposts',
        instagramOptions: 'manual_users',
        youtubeOptions: 'channel',
        youtubeType: {
          keyword: 'nestle',
        },
        socialInfo: [
          {
            type: 'tiktok',
            id: '3f72e270-f9b6-4bd9-8866-8aab4cd07ffb',
            searchTerm: 'nestle',
            alerts: [],
          },
          {
            type: 'instagram',
            id: '2181f06f-9c72-4e40-8b81-3a3909870412',
            searchTerm: 'nestle',
            alerts: [],
          },
          {
            type: 'fb-simple',
            id: 'd896823f-cce9-42d9-9a27-4ee162647679',
            searchTerm: '',
            alerts: [],
          },
          {
            type: 'twitter',
            id: '4dd65770-d56f-4613-b455-cacd834f3171',
            searchTerm: 'nestle',
            alerts: [],
          },
          {
            type: 'youtube',
            id: '8e0159e8-0e6d-4d91-88a8-c0c4c15d7d54',
            searchTerm: 'nestle',
            alerts: [],
          },
          {
            type: 'news',
            id: 'ab21baf6-ddd5-407d-a586-bed7cabfff85',
            searchTerm: 'nestle',
            alerts: [],
          },
          {
            type: 'reddit',
            id: 'a25b3d03-0fa0-492a-8854-ecfb90520c22',
            searchTerm: 'nestle',
            alerts: [],
          },
          {
            type: 'linkedin',
            id: '66b530e2-d233-4efe-b80b-2dc33e4efb6b',
            alerts: [],
          },
        ],
        mediaSearchKeys: {
          tiktok: 'nestle',
          instagram: 'nestle',
          facebook: '',
          twitter: 'nestle',
          youtube: 'nestle',
          news: 'nestle',
          reddit: 'nestle',
        },
        selected: true,
        days: 2,
      },
      {
        masterSearch: 'adidas',
        id: 'eeb0a913-256d-4c36-84ef-319c928a520a',
        language: 'en',
        tiktokType: {
          userAccount: 'adidas',
          userTag: 'adidas',
          keyword: 'adidas',
          hashtag: 'adidas',
        },
        instagramType: {
          userAccount: 'adidas',
          userTag: 'adidas',
          keyword: '',
          hashtag: 'adidas',
        },
        tiktokOptions: 'userposts',
        instagramOptions: 'manual_users',
        youtubeOptions: 'channel',
        youtubeType: {
          channel: 'adidas',
          keyword: '',
        },
        socialInfo: [
          {
            type: 'tiktok',
            id: '1b3edbb3-5762-4d4c-9693-f73167d464b5',
            searchTerm: 'adidas',
            alerts: [],
          },
          {
            type: 'instagram',
            id: '25312534-a716-475d-9571-df2a0b3f28e6',
            searchTerm: 'adidas',
            alerts: [],
          },
          {
            type: 'fb-simple',
            id: '2d84548d-0015-4263-b6f9-4b90df1d19db',
            searchTerm: '',
            alerts: [],
          },
          {
            type: 'twitter',
            id: 'c4f86537-3881-4f50-910a-adaa0e65415d',
            searchTerm: 'adidas',
            alerts: [],
          },
          {
            type: 'youtube',
            id: '8fb9d6dc-c80a-4e79-a5de-0161bdf9aac6',
            searchTerm: 'adidas',
            alerts: [],
          },
          {
            type: 'news',
            id: '3266ad11-9e72-4c93-a756-3273eaeb5b25',
            searchTerm: 'adidas',
            alerts: [],
          },
          {
            type: 'reddit',
            id: 'bedb0be8-06b8-4d7d-ad4a-41abd133ee3c',
            searchTerm: 'adidas',
            alerts: [],
          },
          {
            type: 'linkedin',
            id: '7a764c57-1411-426b-9791-06846629f600',
            alerts: [],
          },
        ],
        mediaSearchKeys: {
          tiktok: 'adidas',
          instagram: 'adidas',
          facebook: '',
          twitter: 'adidas',
          youtube: 'adidas',
          news: 'adidas',
          reddit: 'adidas',
        },
        days: 2,
      },
      {
        masterSearch: 'hilton',
        id: '0f3ec499-c7e7-448d-b2b1-300e3996b6a2',
        language: 'en',
        tiktokType: {
          userAccount: 'hilton',
          userTag: '',
          keyword: 'hilton',
          hashtag: 'hilton',
        },
        instagramType: {
          userAccount: 'hilton',
          userTag: 'hilton',
          keyword: '',
          hashtag: 'hilton',
        },
        tiktokOptions: 'userhashtags',
        instagramOptions: 'manual_hashtags',
        youtubeOptions: 'channel',
        socialInfo: [
          {
            type: 'tiktok',
            id: '21eff983-6000-4b58-b23c-3d069eb13c13',
            searchTerm: 'hilton',
            alerts: [],
          },
          {
            type: 'instagram',
            id: 'd71da0f4-fdf0-41f5-a2e9-61bc4e9b5255',
            searchTerm: 'hilton',
            alerts: [],
          },
          {
            type: 'fb-simple',
            id: 'd7789c4d-6028-4273-a03a-1df3dcd0e3cd',
            searchTerm: '',
            alerts: [],
          },
          {
            type: 'twitter',
            id: '7b176ebd-5bf8-4ed8-a665-8260125ae776',
            searchTerm: '',
            alerts: [],
          },
          {
            type: 'youtube',
            id: 'f5777b2e-9d4b-4a4f-baf9-02af65d2c1cd',
            searchTerm: '',
            alerts: [],
          },
          {
            type: 'news',
            id: '664c4fd9-bf25-4607-ae07-bd680712aacf',
            searchTerm: '',
            alerts: [],
          },
          {
            type: 'reddit',
            id: '71536a0c-7469-4ed4-8509-b8e63c8ac30b',
            searchTerm: '',
            alerts: [],
          },
          {
            type: 'linkedin',
            id: 'f9f5bafd-1b0d-49d1-9c7f-f779441a0a68',
            searchTerm: '',
            alerts: [],
          },
        ],
        mediaSearchKeys: {
          tiktok: 'hilton',
          instagram: 'hilton',
          facebook: '',
          twitter: '',
          youtube: '',
          news: '',
          reddit: '',
          linkedin: '',
        },
        days: 2,
      },
    ],
  },
  {
    group_name: 'Group2',
    companies_list: [
      {
        masterSearch: 'tiktok shop',
        id: 'aeebd322-e91b-4daa-af04-c3040482c305',
        language: 'en',
        tiktokType: {
          userAccount: '',
          userTag: '',
          keyword: 'tiktok shop',
          hashtag: '',
        },
        instagramType: {
          userAccount: '',
          userTag: '',
          keyword: '',
          hashtag: '',
        },
        tiktokOptions: 'userposts',
        instagramOptions: 'manual_users',
        youtubeOptions: 'channel',
        youtubeType: {
          channel: '',
          keyword: '',
        },
        socialInfo: [
          {
            type: 'tiktok',
            id: 'd9061c50-1757-4e4d-b6c8-014f2728f0bd',
            searchTerm: 'tiktok shop',
            alerts: [],
          },
          {
            type: 'instagram',
            id: '53a8d5ed-4bb6-475e-b21d-96cc193ba212',
            searchTerm: '',
            alerts: [],
          },
          {
            type: 'fb-simple',
            id: '2443c7fc-a99b-4896-9dbb-5eb0f52ffb9f',
            searchTerm: '',
            alerts: [],
          },
          {
            type: 'twitter',
            id: '6bc8466b-3434-489f-9353-e306433a9e1d',
            searchTerm: '',
            alerts: [],
          },
          {
            type: 'youtube',
            id: '4c4666ad-0136-4644-a8f3-4418d96a2b29',
            searchTerm: '',
            alerts: [],
          },
          {
            type: 'news',
            id: 'd146374f-b111-4c33-9156-21cde49a4fd7',
            searchTerm: '',
            alerts: [],
          },
          {
            type: 'reddit',
            id: '61ffd73d-2938-4a2f-b1b4-8cbf874690f1',
            searchTerm: '',
            alerts: [],
          },
          {
            type: 'linkedin',
            id: '0be85e43-b3ee-4502-a817-80fad2daca86',
            searchTerm: '',
            alerts: [],
          },
        ],
        mediaSearchKeys: {
          tiktok: 'tiktok shop',
          instagram: '',
          facebook: '',
          twitter: '',
          youtube: '',
          news: '',
          reddit: '',
          linkedin: '',
        },
        days: 2,
      },
      {
        masterSearch: '7up',
        id: 'c07e1114-b7e0-48c9-86ed-1c898dda3361',
        language: 'en',
        tiktokType: {
          userAccount: '7up',
          userTag: '7up',
          keyword: '7up',
          hashtag: '7up',
        },
        instagramType: {
          userAccount: '7up',
          userTag: '7up',
          keyword: '',
          hashtag: '7up',
        },
        tiktokOptions: 'userposts',
        instagramOptions: 'manual_users',
        youtubeOptions: 'channel',
        youtubeType: {
          keyword: '7up',
        },
        socialInfo: [
          {
            type: 'tiktok',
            id: '2a9b53cf-9504-4cbc-a294-b6b7da228058',
            searchTerm: '7up',
            alerts: [],
          },
          {
            type: 'instagram',
            id: '93f5c68a-f5f2-4839-81f3-eafab338be10',
            searchTerm: '7up',
            alerts: [],
          },
          {
            type: 'fb-simple',
            id: '0388cd4d-3cda-4c0a-a480-f40ff9b0b8e9',
            searchTerm: '',
            alerts: [],
          },
          {
            type: 'twitter',
            id: 'cefd95fe-89f2-4e8b-aaea-33f2199ad018',
            searchTerm: '',
            alerts: [],
          },
          {
            type: 'youtube',
            id: '3ab98060-756f-4a39-8bcd-0ebdf73634e7',
            searchTerm: '7up',
            alerts: [],
          },
          {
            type: 'news',
            id: '4df1a0de-2332-4930-a497-a7f304dc19a7',
            alerts: [],
          },
          {
            type: 'reddit',
            id: 'dda6c073-c88a-4e2e-8536-42144c96338c',
            searchTerm: '',
            alerts: [],
          },
          {
            type: 'linkedin',
            id: '97f8c0a0-6a24-4ace-9880-d7830d8cca7b',
            alerts: [],
          },
        ],
        mediaSearchKeys: {
          tiktok: '7up',
          instagram: '7up',
          facebook: '',
          twitter: '',
          youtube: '7up',
          reddit: '',
        },
        days: 2,
      },
    ],
  },
];

export const testGroupCompanyData = [
  {
    masterSearch: 'nestle',
    group_name: 'group1',
    id: '116b4f05-2cec-4840-9a63-f0556c5519fe',
    language: 'en',
    tiktokType: {
      userAccount: 'nestle',
      userTag: 'nestle',
      keyword: 'nestle',
      hashtag: 'nestle',
    },
    instagramType: {
      userAccount: 'nestle',
      userTag: 'nestle',
      keyword: '',
      hashtag: 'nestle',
    },
    tiktokOptions: 'userposts',
    instagramOptions: 'manual_users',
    youtubeOptions: 'channel',
    youtubeType: {
      keyword: 'nestle',
    },
    socialInfo: [
      {
        type: 'tiktok',
        id: '3f72e270-f9b6-4bd9-8866-8aab4cd07ffb',
        searchTerm: 'nestle',
        alerts: [],
      },
      {
        type: 'instagram',
        id: '2181f06f-9c72-4e40-8b81-3a3909870412',
        searchTerm: 'nestle',
        alerts: [],
      },
      {
        type: 'fb-simple',
        id: 'd896823f-cce9-42d9-9a27-4ee162647679',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'twitter',
        id: '4dd65770-d56f-4613-b455-cacd834f3171',
        searchTerm: 'nestle',
        alerts: [],
      },
      {
        type: 'youtube',
        id: '8e0159e8-0e6d-4d91-88a8-c0c4c15d7d54',
        searchTerm: 'nestle',
        alerts: [],
      },
      {
        type: 'news',
        id: 'ab21baf6-ddd5-407d-a586-bed7cabfff85',
        searchTerm: 'nestle',
        alerts: [],
      },
      {
        type: 'reddit',
        id: 'a25b3d03-0fa0-492a-8854-ecfb90520c22',
        searchTerm: 'nestle',
        alerts: [],
      },
      {
        type: 'linkedin',
        id: '66b530e2-d233-4efe-b80b-2dc33e4efb6b',
        alerts: [],
      },
    ],
    mediaSearchKeys: {
      tiktok: 'nestle',
      instagram: 'nestle',
      facebook: '',
      twitter: 'nestle',
      youtube: 'nestle',
      news: 'nestle',
      reddit: 'nestle',
    },
    selected: true,
    days: 2,
  },
  {
    group_name: 'group2',
    masterSearch: 'adidas',
    id: 'eeb0a913-256d-4c36-84ef-319c928a520a',
    language: 'en',
    tiktokType: {
      userAccount: 'adidas',
      userTag: 'adidas',
      keyword: 'adidas',
      hashtag: 'adidas',
    },
    instagramType: {
      userAccount: 'adidas',
      userTag: 'adidas',
      keyword: '',
      hashtag: 'adidas',
    },
    tiktokOptions: 'userposts',
    instagramOptions: 'manual_users',
    youtubeOptions: 'channel',
    youtubeType: {
      channel: 'adidas',
      keyword: '',
    },
    socialInfo: [
      {
        type: 'tiktok',
        id: '1b3edbb3-5762-4d4c-9693-f73167d464b5',
        searchTerm: 'adidas',
        alerts: [],
      },
      {
        type: 'instagram',
        id: '25312534-a716-475d-9571-df2a0b3f28e6',
        searchTerm: 'adidas',
        alerts: [],
      },
      {
        type: 'fb-simple',
        id: '2d84548d-0015-4263-b6f9-4b90df1d19db',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'twitter',
        id: 'c4f86537-3881-4f50-910a-adaa0e65415d',
        searchTerm: 'adidas',
        alerts: [],
      },
      {
        type: 'youtube',
        id: '8fb9d6dc-c80a-4e79-a5de-0161bdf9aac6',
        searchTerm: 'adidas',
        alerts: [],
      },
      {
        type: 'news',
        id: '3266ad11-9e72-4c93-a756-3273eaeb5b25',
        searchTerm: 'adidas',
        alerts: [],
      },
      {
        type: 'reddit',
        id: 'bedb0be8-06b8-4d7d-ad4a-41abd133ee3c',
        searchTerm: 'adidas',
        alerts: [],
      },
      {
        type: 'linkedin',
        id: '7a764c57-1411-426b-9791-06846629f600',
        alerts: [],
      },
    ],
    mediaSearchKeys: {
      tiktok: 'adidas',
      instagram: 'adidas',
      facebook: '',
      twitter: 'adidas',
      youtube: 'adidas',
      news: 'adidas',
      reddit: 'adidas',
    },
    days: 2,
  },
  {
    masterSearch: 'nestle2',
    group_name: 'group1',
    id: '0f3ec499-c7e7-448d-b2b1-300e3996b6a2',
    language: 'en',
    tiktokType: {
      userAccount: 'hilton',
      userTag: '',
      keyword: 'hilton',
      hashtag: 'hilton',
    },
    instagramType: {
      userAccount: 'hilton',
      userTag: 'hilton',
      keyword: '',
      hashtag: 'hilton',
    },
    tiktokOptions: 'userhashtags',
    instagramOptions: 'manual_hashtags',
    youtubeOptions: 'channel',
    socialInfo: [
      {
        type: 'tiktok',
        id: '21eff983-6000-4b58-b23c-3d069eb13c13',
        searchTerm: 'hilton',
        alerts: [],
      },
      {
        type: 'instagram',
        id: 'd71da0f4-fdf0-41f5-a2e9-61bc4e9b5255',
        searchTerm: 'hilton',
        alerts: [],
      },
      {
        type: 'fb-simple',
        id: 'd7789c4d-6028-4273-a03a-1df3dcd0e3cd',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'twitter',
        id: '7b176ebd-5bf8-4ed8-a665-8260125ae776',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'youtube',
        id: 'f5777b2e-9d4b-4a4f-baf9-02af65d2c1cd',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'news',
        id: '664c4fd9-bf25-4607-ae07-bd680712aacf',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'reddit',
        id: '71536a0c-7469-4ed4-8509-b8e63c8ac30b',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'linkedin',
        id: 'f9f5bafd-1b0d-49d1-9c7f-f779441a0a68',
        searchTerm: '',
        alerts: [],
      },
    ],
    mediaSearchKeys: {
      tiktok: 'hilton',
      instagram: 'hilton',
      facebook: '',
      twitter: '',
      youtube: '',
      news: '',
      reddit: '',
      linkedin: '',
    },
    days: 2,
  },

  {
    masterSearch: 'il hpv',
    id: '89a2ce7b-276c-4e25-9d40-032c28726fbf',
    language: 'en',
    tiktokType: {
      userAccount: '',
      userTag: '',
      keyword: '',
      hashtag: '',
    },
    instagramType: {
      userAccount: '',
      userTag: '',
      keyword: '',
      hashtag: '',
    },
    tiktokOptions: 'userposts',
    instagramOptions: 'manual_users',
    youtubeOptions: 'channel',
    youtubeType: {
      channel: '',
      keyword: '',
    },
    socialInfo: [
      {
        type: 'tiktok',
        id: 'ce89827e-b3ab-496b-9fb2-6bae83dd2aa0',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'instagram',
        id: '54923d27-ed81-47f6-95f1-77358373eed2',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'fb-simple',
        id: 'f7d1b8f8-8a79-4e5b-94e6-96e231d87b20',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'twitter',
        id: '9ea27c6f-0216-4bfa-b06f-0ec058228c49',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'youtube',
        id: '0b0cb935-0c1d-44be-8202-e272c7e85521',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'news',
        id: 'e8923cbe-3a56-41de-ae3f-579e714b65e1',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'reddit',
        id: 'e6412899-872a-4443-836d-5e598fa4c021',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'linkedin',
        id: '2e3b38c2-8d39-4a9b-870e-03d5c52abf62',
        searchTerm: 'il hpv',
        alerts: [],
      },
    ],
    mediaSearchKeys: {
      tiktok: '',
      instagram: '',
      facebook: '',
      twitter: '',
      youtube: '',
      news: '',
      reddit: '',
      linkedin: 'il hpv',
    },
    days: 2,
    group_name: 'hpv',
  },
  {
    masterSearch: 'hpv',
    id: '7429d093-e347-4831-a500-970ea9a10b88',
    language: 'en',
    tiktokType: {
      userAccount: '',
      userTag: '',
      keyword: 'hpv',
      hashtag: '',
    },
    instagramType: {
      userAccount: '',
      userTag: '',
      keyword: '',
      hashtag: '',
    },
    tiktokOptions: 'userposts',
    instagramOptions: 'manual_users',
    youtubeOptions: 'channel',
    youtubeType: {
      channel: '',
      keyword: '',
    },
    socialInfo: [
      {
        type: 'tiktok',
        id: '22f37f2f-e706-4850-8ee1-f393132952e5',
        searchTerm: 'hpv',
        alerts: [],
      },
      {
        type: 'instagram',
        id: '3e6b9c36-c4be-4a04-bbb7-afe256ce63d4',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'fb-simple',
        id: '53785e7e-7db9-4747-91e5-df5295fd6e8a',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'twitter',
        id: '236b2934-e003-4b16-a028-ad6856c142d0',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'youtube',
        id: '53ca2135-228a-413b-9243-ed64603e7e38',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'news',
        id: '29db330f-f934-4376-b824-b884ab110ecd',
        alerts: [],
      },
      {
        type: 'reddit',
        id: 'db250d22-516a-487a-b3db-3689a270eb8a',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'linkedin',
        id: '61e28410-95b8-4b22-84d1-6bba6dcfc56e',
        searchTerm: 'hpv',
        alerts: [],
      },
    ],
    mediaSearchKeys: {
      tiktok: 'hpv',
      instagram: '',
      facebook: '',
      twitter: '',
      youtube: '',
      reddit: '',
      linkedin: 'hpv',
    },
    days: 2,
    group_name: 'hpv',
  },
  {
    masterSearch: 'humanpapillomavirus',
    id: '60f17304-c3f5-48be-98bb-8ebc4313e0f5',
    language: 'en',
    tiktokType: {
      userAccount: '',
      userTag: '',
      keyword: '',
      hashtag: '',
    },
    instagramType: {
      userAccount: '',
      userTag: '',
      keyword: '',
      hashtag: '',
    },
    tiktokOptions: 'userposts',
    instagramOptions: 'manual_users',
    youtubeOptions: 'channel',
    youtubeType: {
      channel: '',
      keyword: '',
    },
    socialInfo: [
      {
        type: 'tiktok',
        id: '82664df9-252c-4194-b7ba-bcc9711f8923',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'instagram',
        id: '0cb8558a-50ed-416f-b34b-27a97a18786d',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'fb-simple',
        id: '83a3cf27-fc1f-40eb-88ac-005e9be33ac1',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'twitter',
        id: '47ff0c19-035c-4886-bd7a-5c8bd9a123fc',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'youtube',
        id: '1600f00f-fa82-4e1f-b907-1937a05495f1',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'news',
        id: 'fa784f7c-224d-4d71-88c7-6452390e14af',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'reddit',
        id: '0a8e1708-84a5-4104-8e2d-a35bd596eaae',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'linkedin',
        id: '5479fcab-9669-49f2-a429-94763ffcd63f',
        searchTerm: 'humanpapillomavirus',
        alerts: [],
      },
    ],
    mediaSearchKeys: {
      tiktok: '',
      instagram: '',
      facebook: '',
      twitter: '',
      youtube: '',
      news: '',
      reddit: '',
      linkedin: 'humanpapillomavirus',
    },
    days: 2,
    group_name: 'hpv',
  },
  {
    masterSearch: 'il papilloma virus',
    id: '3a45de99-5d47-4216-b1d0-0dcd5b9b4f73',
    language: 'en',
    tiktokType: {
      userAccount: '',
      userTag: '',
      keyword: '',
      hashtag: '',
    },
    instagramType: {
      userAccount: '',
      userTag: '',
      keyword: '',
      hashtag: '',
    },
    tiktokOptions: 'userposts',
    instagramOptions: 'manual_users',
    youtubeOptions: 'channel',
    youtubeType: {
      channel: '',
      keyword: '',
    },
    socialInfo: [
      {
        type: 'tiktok',
        id: '182dff0f-80b2-4b65-b40d-d923a63c8490',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'instagram',
        id: '82081ab5-0637-4e37-b4ea-d7e770b88b1d',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'fb-simple',
        id: '725dcd92-dea1-4e2f-808e-96895c6ff2c2',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'twitter',
        id: 'b8632da4-a6be-4137-9542-c8d18c64987e',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'youtube',
        id: 'ec756d7c-b45d-41ae-bfea-25bb9b950a16',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'news',
        id: 'e1534434-bbf5-4993-9a1b-cc0d09f1caff',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'reddit',
        id: '9f5cc29d-9166-4c3c-9b0a-e622a89e1db3',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'linkedin',
        id: '18a9f585-b1bd-47b5-a1fb-8e45f1f3abcb',
        searchTerm: 'il papilloma virus',
        alerts: [],
      },
    ],
    mediaSearchKeys: {
      tiktok: '',
      instagram: '',
      facebook: '',
      twitter: '',
      youtube: '',
      news: '',
      reddit: '',
      linkedin: 'il papilloma virus',
    },
    days: 2,
    group_name: 'hpv',
  },
  {
    masterSearch: 'papilloma virus',
    id: '3673584b-2531-4787-81d6-9582a22b0f55',
    language: 'en',
    tiktokType: {
      userAccount: '',
      userTag: '',
      keyword: '',
      hashtag: '',
    },
    instagramType: {
      userAccount: '',
      userTag: '',
      keyword: '',
      hashtag: '',
    },
    tiktokOptions: 'userposts',
    instagramOptions: 'manual_users',
    youtubeOptions: 'channel',
    youtubeType: {
      channel: '',
      keyword: '',
    },
    socialInfo: [
      {
        type: 'tiktok',
        id: 'f3e28279-8e27-4fb7-96ad-6086e3776689',
        searchTerm: 'papilloma virus',
        alerts: [],
      },
      {
        type: 'instagram',
        id: 'bf9500a4-9713-4dd8-ac58-6efe3705edcf',
        searchTerm: 'papilloma virus',
        alerts: [],
      },
      {
        type: 'fb-simple',
        id: 'db701524-81fd-4696-9cc1-42e0617fb6f9',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'twitter',
        id: '9f71d0e3-3b92-4750-83c2-7da481c80f0c',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'youtube',
        id: 'ae90513c-6ff7-4cf5-a64b-1f1965554fe8',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'news',
        id: '9da0cfdb-59bb-433f-a0f4-b754ab71d7d6',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'reddit',
        id: '245369e0-2520-44fe-83b4-6825bdc6de62',
        searchTerm: '',
        alerts: [],
      },
      {
        type: 'linkedin',
        id: 'ad18215e-ed2a-4909-97cc-d8577752e3d1',
        searchTerm: 'papilloma virus',
        alerts: [],
      },
    ],
    mediaSearchKeys: {
      tiktok: 'papilloma virus',
      instagram: 'papilloma virus',
      facebook: '',
      twitter: '',
      youtube: '',
      news: '',
      reddit: '',
      linkedin: 'papilloma virus',
    },
    days: 2,
    group_name: 'hpv',
  },
];

// tiktokUtils.js
export function getItemsSubTabMulti(
  tiktokSubTabMulti,
  sortByTiktokSubTabMulti,
) {
  if (sortByTiktokSubTabMulti === 'all') {
    return tiktokSubTabMulti;
  } else if (tiktokSubTabMulti.includes(sortByTiktokSubTabMulti)) {
    return [sortByTiktokSubTabMulti];
  } else {
    return []; // If the provided value is not valid
  }
}
