import {
  FB_SEARCH_HANDLES_URI,
  INSTAGRAM_HASHTAG_SEARCH_HANDLES_URI,
  INSTAGRAM_USERACCOUNT_SEARCH_HANDLES_URI,
  LINKEDIN_COMPANY_SEARCH_HANDLES_URI,
  LIVE_SEARCH_URI,
  NEWS_SEARCH_HANDLER_URI,
  TIKTOK_HASHTAG_SEARCH_HANDLES_URI,
  TIKTOK_USERACCOUNT_SEARCH_HANDLES_URI,
  TWITTER_KEYWORD_SEARCH_HANDLES_URI,
  TWITTER_USERACCOUNT_SEARCH_HANDLES_URI,
  YOUTUBE_SEARCH_HANDLES_URI,
} from '../common/endpoints';

import { SOCIAL_MEDIA_TYPE } from '../../common/constants';
import { debounce } from 'lodash';
import { get } from '../common/axiosInstance';

const searchQueryHandlers = function () {
  this.youtube = debounce(async function (queryString, option, callbackFn) {
    if (option) {
      const response = await get(YOUTUBE_SEARCH_HANDLES_URI, {
        params: { channel_name: queryString, option: 'keyword' },
      });
      callbackFn(
        response?.data?.map(({ keyword }) => ({
          key: keyword,
          label: keyword,
          value: keyword,
        })) || null,
      );
    } else {
      const response = await get(YOUTUBE_SEARCH_HANDLES_URI, {
        params: { channel_name: queryString, option: 'channel' },
      });
      callbackFn(
        response?.data?.map(({ name, thumbnail, verification_status }) => ({
          key: name,
          label: name,
          thumbnail: thumbnail,
          value: name,
          verified: verification_status === 'True',
        })) || null,
      );
    }
  }, 2000);

  // this.news = debounce(async function (queryString, callbackFn) {
  //   const response = await get(YOUTUBE_SEARCH_HANDLES_URI, {
  //     params: { channel_name: queryString, option: "channel" },
  //   });

  //   callbackFn(
  //     response?.data?.map(
  //       ({ name, thumbnail, verification_status, keyword }) => ({
  //         key: name || keyword,
  //         label: name || keyword,
  //         thumbnail: thumbnail,
  //         value: name || keyword,
  //         verified: name ? String(verification_status).toLowerCase() : false,
  //       })
  //     ) || null
  //   );
  // }, 2000);

  this.news = debounce(async function (queryString, callbackFn) {
    const response = await get(NEWS_SEARCH_HANDLER_URI, {
      params: { account_name: queryString },
    });
    callbackFn(
      response?.data?.map((key) => ({
        key: `${key}-${key}`,
        label: `${key}`,
        value: key,
      })) || null,
    );
  }, 2000);

  this.reddit = debounce(async function (queryString, callbackFn) {
    const response = await get(NEWS_SEARCH_HANDLER_URI, {
      params: { account_name: queryString },
    });
    callbackFn(
      response?.data?.map((key) => ({
        key: `${key}-${key}`,
        label: `${key}`,
        value: key,
      })) || null,
    );
  }, 2000);

  this.facebook = debounce(async function (queryString, callbackFn) {
    const response = await get(FB_SEARCH_HANDLES_URI, {
      params: { page_name: queryString },
    });

    callbackFn(
      response?.data?.map(({ id, name, verification_status }) => ({
        key: id,
        label: name,
        value: id,
        verified: verification_status === 'blue_verified',
      })) || null,
    );
  }, 2000);

  this.twitter = debounce(async function (queryString, option, callbackFn) {
    if (option) {
      callbackFn([]);
    } else {
      const response = await get(TWITTER_USERACCOUNT_SEARCH_HANDLES_URI, {
        params: { account_name: queryString },
      });
      callbackFn(
        response?.data?.map(
          ({ name, profile_image_url_https, screen_name, verified }) => ({
            key: `${name}-${screen_name}`,
            label: `${name}(@${screen_name})`,
            thumbnail: profile_image_url_https,
            value: screen_name,
            verified,
          }),
        ) || null,
      );
    }
  }, 2000);

  // live searchQuery APi call
  this.instagram = debounce(async function (queryString, option, callbackFn) {
    if (option) {
      const response = await get(INSTAGRAM_HASHTAG_SEARCH_HANDLES_URI, {
        params: { account_name: queryString },
      });
      callbackFn(
        response?.data?.map(
          ({ name, profile_pic_url, search_result_subtitle }) => ({
            key: `${name} (${search_result_subtitle})`,
            label: `${name}`,
            thumbnail: profile_pic_url,
            value: name,
            search_result_subtitle,
          }),
        ) || null,
      );
    } else {
      const response = await get(INSTAGRAM_USERACCOUNT_SEARCH_HANDLES_URI, {
        params: { account_name: queryString },
      });
      callbackFn(
        response?.data?.map(
          ({ name, profile_image_url_https, username, verified }) => ({
            key: `${name}-${username}`,
            label: `${name}(@${username})`,
            //thumbnail: profile_image_url_https,
            value: username,
            verified,
          }),
        ) || null,
      );
    }
  }, 2000);

  this.tiktok = debounce(async function (queryString, option, callbackFn) {
    if (option) {
      const response = await get(TIKTOK_HASHTAG_SEARCH_HANDLES_URI, {
        params: { account_name: queryString },
      });
      callbackFn(
        response?.data?.map(({ HashtagID_Name, View_Count }) => ({
          key: `${HashtagID_Name} (${HashtagID_Name})`,
          label: `${HashtagID_Name} (${View_Count} views)`,
          value: HashtagID_Name,
        })) || null,
      );
    } else {
      const response = await get(TIKTOK_USERACCOUNT_SEARCH_HANDLES_URI, {
        params: { account_name: queryString },
      });
      callbackFn(
        response?.data?.map(({ nickname, tiktok_username }) => ({
          key: `${nickname}(@${tiktok_username || ''})`,
          label: `${nickname}(@${tiktok_username || ''})`,
          value: tiktok_username || nickname,
        })) || null,
      );
    }
  }, 2000);

  this.linkedin = debounce(async function (queryString, callbackFn) {
    const response = await get(LINKEDIN_COMPANY_SEARCH_HANDLES_URI, {
      params: { account_name: queryString },
    });
    callbackFn(
      response?.data.map(
        ({ company_name, company_url, image_url, follower_count }) => ({
          key: `${company_name} (${company_url})`,
          label: `${company_name} (${follower_count} followers)`,
          thumbnail: image_url,
          value: company_name,
          url: company_url,
        }),
      ) || null,
    );
  }, 2000);

  this.trustpilot = debounce(async function (queryString, callbackFn) {
    const response = await get(NEWS_SEARCH_HANDLER_URI, {
      params: { account_name: queryString },
    });

    callbackFn(
      response?.data?.data?.trustPilot?.data?.map(
        ({ companyName, pageUrl, rating, id }) => ({
          key: `${companyName}-${id}`,
          label: `${companyName} (${rating} rating)`,
          // thumbnail: image_url,
          value: companyName,
          url: pageUrl,
        }),
      ) || null,
    );
  }, 2000);

  this.getChannelHandles = (channel, option, searchTerm, callbackFn) => {
    switch (channel) {
      case SOCIAL_MEDIA_TYPE.facebook:
        return this.facebook(searchTerm, callbackFn);
      case SOCIAL_MEDIA_TYPE.twitter:
        return this.twitter(searchTerm, option, callbackFn);
      case SOCIAL_MEDIA_TYPE.youtube:
        return this.youtube(searchTerm, option, callbackFn);
      case 'news':
        return this.news(searchTerm, callbackFn);
      case SOCIAL_MEDIA_TYPE.reddit:
        return this.reddit(searchTerm, callbackFn);
      case SOCIAL_MEDIA_TYPE.tiktok:
        return this.tiktok(searchTerm, option, callbackFn);
      case SOCIAL_MEDIA_TYPE.instagram:
        return this.instagram(searchTerm, option, callbackFn);
      case SOCIAL_MEDIA_TYPE.linkedin:
        return this.linkedin(searchTerm, callbackFn);
      case SOCIAL_MEDIA_TYPE.trustpilot:
        return this.trustpilot(searchTerm, callbackFn);
      default:
        callbackFn([]);
    }
  };
};

export const searchHandlers = new searchQueryHandlers().getChannelHandles;
