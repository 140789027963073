import PropTypes from 'prop-types';
import React from 'react';

export default function SummaryDialog({
  title,
  body,
  negativeButton2,
  positiveButton1,
  showDialog,
  setShowDialog,
  footer,
  bodyPaddingClass,
  onModalclick,
  noCenter,
  children,
  width = '50%',
}) {
  return (
    <>
      {showDialog ? (
        <>
          <div
            onClick={onModalclick}
            className={`flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ${
              !noCenter
                ? 'justify-center items-center'
                : 'justify-end items-start'
            }`}
          >
            <div className={`relative my-6 mx-5 w-[${width}]`}>
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                {title && (
                  <div className="flex items-start justify-between p-4 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-xl font-semibold">{title}</h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowDialog(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                )}
                {/*body*/}
                <div
                  className={`relative ${
                    bodyPaddingClass ? bodyPaddingClass : ''
                  } flex-auto`}
                >
                  <p className="mb-4 mt-2  text-lg leading-relaxed whitespace-pre-line">
                    {children}
                  </p>
                </div>
                {/*footer*/}
                {(!!negativeButton2 || !!positiveButton1) && (
                  <div className="flex items-center justify-end p-3 border-t border-solid border-slate-200 rounded-b">
                    {negativeButton2 && negativeButton2.text && (
                      <button
                        className="bg-rose-500 text-white active:bg-rose-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => {
                          negativeButton2.callback();
                          setShowDialog(false);
                        }}
                      >
                        {negativeButton2.text}
                      </button>
                    )}
                    {positiveButton1 && positiveButton1.text && (
                      <button
                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-8 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => {
                          positiveButton1.callback();
                          setShowDialog(false);
                        }}
                      >
                        {positiveButton1.text}
                      </button>
                    )}
                  </div>
                )}

                {footer?.text && (
                  <div
                    onClick={footer?.callback}
                    class="w-full cursor-pointer text-center text-gray-500 uppercase text-sm py-2"
                  >
                    {footer?.text}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

SummaryDialog.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  positiveButton1: PropTypes.shape({
    text: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired,
  }).isRequired,
  negativeButton2: PropTypes.shape({
    text: PropTypes.string.isRequired,
    callback: PropTypes.func,
  }),
  showDialog: PropTypes.bool.isRequired,
  setShowDialog: PropTypes.func.isRequired,
};
