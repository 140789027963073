import React, { useEffect, useState } from 'react';

import { CiCircleInfo } from 'react-icons/ci';
import DataList from './DataList';
import Loader from '../../App/LayoutComponents/Loader/Loader';
import LogoHolder from './LogoHolder';
import PropTypes from 'prop-types';
import { SOCIAL_MEDIA_TYPE } from '../../../common/constants';
import Tooltip from '../../../common/Tooltip/Tooltip';
import useSearchTermCustomHook from '../../customHooks/useSearchTerm.customHook';
import { v4 as uuidv4 } from 'uuid';

// individual channel card based on the social media
const ChannelCard = ({
  mediaItem,
  masterSearchQuery,
  option,
  onMediaSourceToggle,
  setMediaSearchInput,
  handleOptionSelect,
  socialMediaSearchValues,
  setSocialMediaSearchValues,
  youtubeOption,
  twitterOption,
  tiktokOption,
  instagramOption,
  facebookOption,
  setInstagramType,
  setTiktokType,
  setYoutubeType,
  companyInfo,
  isEdit,
}) => {
  console.log(mediaItem, 'mediaItem');
  const { searchTermLoader, searchTermResponse, handleSearch, error } =
    useSearchTermCustomHook();

  const [currentSelection, setCurrentSelection] = useState();
  const [isActive, setIsActive] = useState(isEdit ? '' : 'userAccount');
  const [isActiveTab, setIsActiveTab] = useState(isEdit ? [] : ['userAccount']);
  const [searchKey, setSearchKey] = useState(masterSearchQuery);
  const [isActiveChannel, setIsActiveChannel] = useState(false);

  console.log(masterSearchQuery, searchKey, 'masterSearchQuery');

  console.log(isEdit, companyInfo, 'isEdit');
  const [instagramHandler, setInstagramHandler] = useState({
    userAccount: '',
    userTag: '',
    keyword: '',
    hashtag: '',
  });

  useEffect(() => {
    if (isEdit) {
      // if (mediaItem.label.toLowerCase() != "facebook") {
      setInstagramHandler({
        userAccount:
          mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.tiktok
            ? companyInfo?.tiktokType?.userAccount || ''
            : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.instagram
            ? companyInfo?.instagramType?.userAccount || ''
            : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.youtube
            ? companyInfo?.youtubeType?.channel || ''
            : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.twitter
            ? companyInfo?.mediaSearchKeys?.twitter || ''
            : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.facebook
            ? companyInfo?.mediaSearchKeys?.facebook || ''
            : mediaItem.label.toLowerCase() == 'news'
            ? companyInfo?.mediaSearchKeys?.news || ''
            : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.reddit
            ? companyInfo?.mediaSearchKeys?.reddit || ''
            : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.linkedin
            ? companyInfo?.mediaSearchKeys?.linkedin || ''
            : '',

        userTag:
          mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.tiktok
            ? companyInfo?.tiktokType?.userAccount || ''
            : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.instagram
            ? companyInfo?.instagramType?.userAccount || ''
            : '',
        keyword:
          mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.tiktok
            ? companyInfo?.tiktokType?.keyword || ''
            : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.instagram
            ? companyInfo?.instagramType?.keyword || ''
            : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.youtube
            ? companyInfo?.youtubeType?.keyword || ''
            : '',
        hashtag:
          mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.tiktok
            ? companyInfo?.tiktokType?.hashtag || ''
            : mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.instagram
            ? companyInfo?.instagramType?.hashtag || ''
            : '',
      });
      // }
    }
  }, [companyInfo]);

  useEffect(() => {
    if (isEdit) {
      if (mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.tiktok) {
        console.log(companyInfo, 'isActiveChannel');
        let isActiveTiktok = '';
        isActiveTiktok =
          companyInfo?.tiktokType?.userAccount ||
          companyInfo?.tiktokType?.userTag ||
          companyInfo?.tiktokType?.keyword ||
          '';

        console.log(isActiveTiktok, 'isActiveTiktok');
        if (isActiveTiktok != '') {
          console.log(isActiveTiktok, 'isActiveTiktokifff');
          // onMediaSourceToggle(mediaItem.id, true);
          setIsActiveChannel(true);
        } else {
          setIsActiveChannel(false);
          // onMediaSourceToggle(mediaItem.id, false);
        }
      }
      if (mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.instagram) {
        let isActiveInstagram = '';
        isActiveInstagram =
          companyInfo?.instagramType?.userAccount ||
          companyInfo?.instagramType?.userTag ||
          companyInfo?.instagramType?.hashtag ||
          '';

        if (isActiveInstagram != '') {
          setIsActiveChannel(true);
          // onMediaSourceToggle(mediaItem.id, true);
        } else {
          setIsActiveChannel(false);
          // onMediaSourceToggle(mediaItem.id, false);
        }
      }
      if (mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.youtube) {
        let isActiveYoutube = '';
        isActiveYoutube =
          companyInfo?.youtubeType?.userAccount ||
          companyInfo?.youtubeType?.keyword ||
          '';
        if (isActiveYoutube != '') {
          setIsActiveChannel(true);
          // onMediaSourceToggle(mediaItem.id, true);
        } else {
          setIsActiveChannel(false);
          // onMediaSourceToggle(mediaItem.id, false);
        }
      }
      if (mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.twitter) {
        let isActive = '';
        isActive =
          companyInfo?.mediaSearchKeys?.[SOCIAL_MEDIA_TYPE.twitter] || '';
        if (isActive != '') {
          setIsActiveChannel(true);
          // onMediaSourceToggle(mediaItem.id, true);
        } else {
          setIsActiveChannel(false);
          // onMediaSourceToggle(mediaItem.id, false);
        }
      }
      if (mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.facebook) {
        let isActive = '';
        isActive =
          companyInfo?.mediaSearchKeys?.[SOCIAL_MEDIA_TYPE.facebook] || '';
        if (isActive != '') {
          setIsActiveChannel(true);
          // onMediaSourceToggle(mediaItem.id, true);
        } else {
          setIsActiveChannel(false);
          // onMediaSourceToggle(mediaItem.id, false);
        }
      }
      if (mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.reddit) {
        let isActive = '';
        isActive =
          companyInfo?.mediaSearchKeys?.[SOCIAL_MEDIA_TYPE.reddit] || '';
        if (isActive != '') {
          setIsActiveChannel(true);
          // onMediaSourceToggle(mediaItem.id, true);
        } else {
          setIsActiveChannel(false);
          // onMediaSourceToggle(mediaItem.id, false);
        }
      }
      if (mediaItem.label.toLowerCase() == SOCIAL_MEDIA_TYPE.articles) {
        let isActive = '';
        isActive =
          companyInfo?.mediaSearchKeys?.[SOCIAL_MEDIA_TYPE.articles] || '';
        if (isActive != '') {
          setIsActiveChannel(true);
          // onMediaSourceToggle(mediaItem.id, true);
        } else {
          setIsActiveChannel(false);
          // onMediaSourceToggle(mediaItem.id, false);
        }
      }
    }
  }, [companyInfo, searchKey]);

  // const [youtubeHandler, setYoutubeHandler] = useState({
  //   userAccount: "",
  //   keyword: "",
  // });

  // useEffect(() => {
  //   if (isActiveChannel) {
  //     onMediaSourceToggle(mediaItem.id, true);
  //   }
  // }, [isActiveChannel]);

  useEffect(() => {
    if (!isEdit) {
      if (mediaItem.label.toLowerCase() != 'facebook') {
        setInstagramHandler({
          userAccount: searchKey,
          userTag: '',
          keyword: '',
          hashtag: '',
        });
      }
    }
  }, [searchKey]);

  useEffect(() => {
    if (mediaItem.label.toLowerCase() == 'tiktok') {
      setTiktokType(instagramHandler);
    }
    if (mediaItem.label.toLowerCase() == 'instagram') {
      setInstagramType(instagramHandler);
    }
  }, [instagramHandler]);

  useEffect(() => {
    updateInstagramOption(currentSelection, isActive);
  }, [currentSelection]);

  console.log(instagramHandler, 'showResponse');

  useEffect(() => {
    if (mediaItem.label.toLowerCase() === SOCIAL_MEDIA_TYPE.youtube) {
      setYoutubeType({
        channel: instagramHandler.userAccount,
        keyword: instagramHandler.keyword,
      });
    }
  }, [instagramHandler, mediaItem.label]);

  useEffect(() => {
    if (mediaItem.label.toLowerCase() != 'facebook') {
      // Check if searchTermResponse already contains an entry with the same key
      const existingIndex = searchTermResponse.findIndex(
        (item) => item.key === '24287259392111',
      );

      // Create a new entry
      const newEntry = {
        key: '24287259392111',
        label: `${searchKey} (selected keyword)`,
        value: searchKey,
        verified: false,
      };

      // If an entry with the same key exists, update it with the new value
      if (existingIndex !== -1) {
        searchTermResponse.splice(existingIndex, 1, newEntry);
      } else {
        // If not, add the new entry at the beginning of the array
        searchTermResponse.unshift({ ...newEntry });
      }
    }
  }, [searchKey, searchTermResponse]);

  function updateInstagramOption(currentSelection, tabName) {
    setInstagramHandler((prevHandler) => {
      // Check if the key already exists and currentSelection is not empty
      if (currentSelection != '' && currentSelection != undefined) {
        // Key exists, update with the latest non-empty value
        setIsActiveTab([...isActiveTab, tabName]);
        return {
          ...prevHandler,
          [tabName]: currentSelection,
        };
      } else {
        // Key doesn't exist, add it with the currentSelection if it's not empty
        return {
          ...prevHandler,
          [tabName]: currentSelection,
        };
      }
      // else {
      //   // Key doesn't exist, and currentSelection is empty, do not update
      //   return prevHandler;
      // }
    });
  }

  const removeHandler = (currentSelection, tabName, optionvalue) => {
    handleNameToggle(tabName);
    // if (mediaItem.label.toLowerCase() != "facebook") {
    handleSearch(
      searchKey,
      mediaItem.label.toLowerCase(),
      optionvalue,
      isEdit ? isActiveChannel : mediaItem.state,
    );
    // }
    setIsActive(tabName);

    setInstagramHandler((prevHandler) => {
      return {
        ...prevHandler,
        [tabName]: currentSelection,
      };
    });
  };

  const instaTooltipContent =
    "<p>User  - e.g., nike's account</p><p>Tagged  - e.g., @nike tagged by others</p><p>Hashtags - e.g., #nike tagged by others</p>";
  const tiktokToopTipContent =
    "<p>User  - e.g., nike's account</p><p>Keyword - e.g., All (nike,#nike,@nike)</p><p>Hashtags - e.g., #nike tagged by others</p>";

  const youtubeTooltipContent =
    "<p>Channel - e.g., nike's own channel</p><p>Keyword - e.g., All (nike,#nike,@nike)</p>";

  const handleNameToggle = (tabName) => {
    const index = isActiveTab.indexOf(tabName);
    if (index !== -1) {
      // If name is found in the array, remove it
      const updatedNames = [...isActiveTab];
      updatedNames.splice(index, 1);
      setIsActiveTab(updatedNames);
      setCurrentSelection('');
      updateInstagramOption('', tabName);
    } else {
      // If name is not found, add it
      // if (currentSelection != "") {
      setIsActiveTab([...isActiveTab, tabName]);
      // if (currentSelection != "") {
      //   updateInstagramOption(currentSelection, tabName);
      // } else {
      //   updateInstagramOption(searchKey, tabName);
      // }
      // }
      updateInstagramOption(searchKey, tabName);
    }
    // setIsActive(tabName);

    console.log(instagramHandler, 'instagramOption');
  };

  const handleMediaSearch = (searchQuery) => {
    setSearchKey(searchQuery);
    setSocialMediaSearchValues((prevData) => ({
      ...prevData,
      [mediaItem.label.toLowerCase()]: searchQuery,
    }));

    if (mediaItem.label.toLowerCase() == 'facebook') {
      handleSearch(searchQuery, mediaItem.label.toLowerCase(), option, true);
    }
  };

  const liveSearch = () => {
    handleSearch(
      searchKey,
      mediaItem.label.toLowerCase(),
      option,
      isActiveChannel,
    );
  };

  // main search term change action
  useEffect(() => {
    handleMediaSearch(masterSearchQuery);
    setCurrentSelection();
    setMediaSearchInput(mediaItem.label.toLowerCase(), undefined);
  }, [masterSearchQuery, isActiveChannel]);

  // initial call action
  useEffect(() => {
    const value =
      (socialMediaSearchValues &&
      SOCIAL_MEDIA_TYPE.facebook == mediaItem.label.toLowerCase()
        ? masterSearchQuery
        : socialMediaSearchValues[mediaItem.label.toLowerCase()]) ||
      masterSearchQuery;
    console.log(
      masterSearchQuery,

      value,
      socialMediaSearchValues[mediaItem.label.toLowerCase()],
      'sdsdsdsdsvalue',
    );
    handleMediaSearch(value);
    setCurrentSelection();
    setMediaSearchInput(mediaItem.label.toLowerCase(), undefined);
  }, []);

  // option toggle action
  useEffect(() => {
    const value =
      (socialMediaSearchValues &&
      SOCIAL_MEDIA_TYPE.facebook == mediaItem.label.toLowerCase()
        ? masterSearchQuery
        : socialMediaSearchValues[mediaItem.label.toLowerCase()]) ||
      masterSearchQuery;
    console.log(
      value,
      socialMediaSearchValues[mediaItem.label.toLowerCase()],
      'sdsdsdsdsvalue',
    );
    handleMediaSearch(value);
    setCurrentSelection();
    setMediaSearchInput(mediaItem.label.toLowerCase(), undefined);
  }, [option]);

  const handleChannelClick = (dataItem) => {
    if (dataItem.value === currentSelection) {
      setCurrentSelection();
      setMediaSearchInput(mediaItem.label.toLowerCase(), undefined);
    } else {
      if (mediaItem.label.toLowerCase() === 'facebook') {
        setCurrentSelection(dataItem.value);
        setMediaSearchInput(
          mediaItem.label.toLowerCase(),
          dataItem.value,
          dataItem.label,
        );
      } else if (mediaItem.label.toLowerCase() === 'linkedin') {
        setCurrentSelection(dataItem.value);
        setMediaSearchInput(
          mediaItem.label.toLowerCase(),
          dataItem.value,
          dataItem.url,
        );
      } else {
        setCurrentSelection(dataItem.value);
        setMediaSearchInput(
          mediaItem.label.toLowerCase(),
          dataItem.value,
          dataItem.value,
        );
      }
    }
  };

  return (
    <div
      className={`${
        mediaItem.state || isActiveChannel ? 'h-96' : 'h-40'
      } min-w-[330px] max-w-[330px] shadow-md rounded-t-lg rounded-b-md m-2 mt-10 self-center bg-white relative`}
    >
      <LogoHolder data={mediaItem} iconClickEvent={onMediaSourceToggle} />
      {(socialMediaSearchValues[mediaItem.label.toLowerCase()] ||
        masterSearchQuery) && (
        <div
          className={`relative flex items-center justify-center mx-[15%] ${
            mediaItem.state && 'mt-12'
          } ${isActiveChannel && 'mt-12'}  `}
        >
          <input
            type="text"
            onFocus={() => {
              onMediaSourceToggle(mediaItem.id, true);
              setIsActiveChannel(true);
            }}
            value={socialMediaSearchValues[mediaItem.label.toLowerCase()]}
            name={`${mediaItem.label}-search`}
            onChange={(e) => {
              handleMediaSearch(e.target.value);
            }}
            className={`rounded-md appearance-none border border-primary text-base focus:outline-none focus:ring-1`}
          />
          <div className="absolute right-3 top-2 pointer-events-none text-primary">
            <span className="material-icons text-md">edit</span>
          </div>
          {/* <div className="flex ml-4">
            <Tooltip
              text="User  - e.g., nike's account
                      Tagged  - e.g., @nike tagged by others
                      Hashtags - e.g., #nike tagged by others
                      Keyword - e.g., All (nike,#nike,@nike)"
              align="start"
              searchText={
                mediaItem.label.toLowerCase() === "instagram"
                  ? instaTooltipContent
                  : mediaItem.label.toLowerCase() === "tiktok"
                  ? tiktokToopTipContent
                  : mediaItem.label.toLowerCase() === "youtube"
                  ? youtubeTooltipContent
                  : null
              }
              width="w-[20rem]"
            >
              <div className="cursor-pointer relative">
                <CiCircleInfo className="text-[20px] font-bold text-green-700" />
              </div>
            </Tooltip>
          </div> */}
        </div>
      )}
      {(mediaItem.state || isActiveChannel) &&
        (mediaItem.label.toLowerCase() === 'youtube' ||
          mediaItem.label.toLowerCase() === 'twitter' ||
          mediaItem.label.toLowerCase() === 'news' ||
          mediaItem.label.toLowerCase() === 'reddit' ||
          mediaItem.label.toLowerCase() === 'facebook' ||
          mediaItem.label.toLowerCase() === 'tiktok' ||
          mediaItem.label.toLowerCase() === 'linkedin' ||
          mediaItem.label.toLowerCase() === 'instagram') && (
          <div className="flex items-center justify-center w-full">
            <label
              htmlFor={
                (mediaItem.label.toLowerCase() === 'youtube' &&
                  'youtubemode') ||
                (mediaItem.label.toLowerCase() === 'linkedin' &&
                  mediaItem.label.toLowerCase() === 'twitter' &&
                  'twittermode') ||
                (mediaItem.label.toLowerCase() === 'tiktok' && 'tiktokmode') ||
                (mediaItem.label.toLowerCase() === 'instagram' &&
                  'instagrammode') ||
                (mediaItem.label.toLowerCase() === 'facebook' && 'facebookmode')
              }
              className="flex items-center cursor-pointer"
            >
              <div className="flex flex-col">
                {(mediaItem.label.toLowerCase() === 'youtube' ||
                  // mediaItem.label.toLowerCase() === "twitter" ||
                  mediaItem.label.toLowerCase() === 'tiktok' ||
                  mediaItem.label.toLowerCase() === 'instagram') && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!isActiveTab.includes('userAccount')) {
                        handleOptionSelect(
                          false,
                          mediaItem.label.toLowerCase(),
                        );
                      }
                      // handleOptionSelect(false, mediaItem.label.toLowerCase());
                      setIsActive('userAccount');
                      handleNameToggle('userAccount');
                    }}
                    className={` px-[4px] m-1 py-[2px] text-sm rounded shadow border  hover:border-secondary   cursor-pointer ${
                      // isActive == "userAccount"

                      (instagramHandler.userAccount != '' && isEdit) ||
                      isActiveTab.includes('userAccount')
                        ? 'bg-onsecondaryvariant text-onsecondary'
                        : 'bg-onprimary'
                    }`}
                  >
                    {(mediaItem.label.toLowerCase() === 'youtube' &&
                      'Channel') ||
                      (mediaItem.label.toLowerCase() === 'twitter' &&
                        'User Handler') ||
                      (mediaItem.label.toLowerCase() === 'tiktok' &&
                        'User Account') ||
                      (mediaItem.label.toLowerCase() === 'instagram' &&
                        'User Account')}
                  </div>
                )}

                {(instagramHandler?.userAccount || currentSelection) && (
                  <div className="flex justify-center items-center ">
                    <Tooltip text={instagramHandler?.userAccount} align="start">
                      <div className="cursor-pointer relative">
                        {/* <CiCircleInfo className="text-[20px] font-bold text-green-700" /> */}
                        <p className="text-sm text-secondary">
                          {instagramHandler?.userAccount?.length > 10
                            ? `${instagramHandler.userAccount.slice(0, 10)}..`
                            : instagramHandler.userAccount || currentSelection}
                        </p>
                      </div>
                    </Tooltip>
                    <p
                      onClick={() =>
                        removeHandler('', 'userAccount', undefined)
                      }
                    >
                      <svg
                        dataSlot="icon"
                        fill="none"
                        strokeWidth={1}
                        width="18px"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18 18 6M6 6l12 12"
                        />
                      </svg>
                    </p>
                  </div>
                )}
              </div>

              {/* <div className="relative m-2"> */}
              {/* <input
                  id={
                    (mediaItem.label.toLowerCase() === "youtube" &&
                      "youtubemode") ||
                    (mediaItem.label.toLowerCase() === "twitter" &&
                      "twittermode") ||
                    (mediaItem.label.toLowerCase() === "tiktok" &&
                      "tiktokmode") ||
                    (mediaItem.label.toLowerCase() === "instagram" &&
                      "instagrammode") ||
                    (mediaItem.label.toLowerCase() === "facebook" &&
                      "facebookmode")
                  }
                  type="checkbox"
                  className={`sr-only ${option ? "bg-blue" : ""}`}
                  defaultChecked={option}
                  onChange={(e) => {
                    e.stopPropagation();
                    handleOptionSelect(!option, mediaItem.label.toLowerCase());
                  }}
                /> */}

              {/* <div className="block bg-primary w-10 h-6 rounded-full"></div>
                <div
                  className={`${
                    option
                      ? "translate-x-full rotate-180"
                      : "translate-x-0 rotate-0"
                  } absolute left-1 top-1 bg-onprimary w-4 h-4 rounded-full transition duration-700 flex items-center`}
                >
                  <span className="material-icons text-sm text-primary">
                    arrow_back
                  </span>
                </div> */}
              {/* </div> */}

              {/* Additional section */}
              {mediaItem.label.toLowerCase() === 'instagram' ? (
                <div className="flex flex-col">
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!isActiveTab.includes('userTag')) {
                        handleOptionSelect(
                          false,
                          mediaItem.label.toLowerCase(),
                        );
                      }

                      setIsActive('userTag');
                      handleNameToggle('userTag');
                    }}
                    className={` px-[4px] m-1 py-[2px] text-sm rounded shadow border  hover:border-secondary   cursor-pointer ${
                      // isActive == "userTag"
                      (instagramHandler.userTag != '' && isEdit) ||
                      isActiveTab.includes('userTag')
                        ? 'bg-onsecondaryvariant text-onsecondary'
                        : 'bg-onprimary'
                    }`}
                  >
                    <div className="text-sm">User Tag</div>
                  </div>
                  {instagramHandler?.userTag && (
                    <div className="flex justify-center  items-center">
                      <Tooltip text={instagramHandler?.userTag} align="start">
                        <div className="cursor-pointer relative">
                          {/* <CiCircleInfo className="text-[20px] font-bold text-green-700" /> */}
                          <p className="text-sm text-secondary">
                            {instagramHandler?.userTag?.length > 10
                              ? `${instagramHandler.userTag.slice(0, 10)}..`
                              : instagramHandler.userTag}
                          </p>
                        </div>
                      </Tooltip>
                      <p
                        onClick={() => removeHandler('', 'userTag', undefined)}
                      >
                        {' '}
                        <svg
                          dataSlot="icon"
                          fill="none"
                          strokeWidth={1}
                          width="18px"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18 18 6M6 6l12 12"
                          />
                        </svg>{' '}
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                (mediaItem.label.toLowerCase() === 'tiktok' ||
                  mediaItem.label.toLowerCase() === 'youtube') && (
                  <div className="flex flex-col">
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!isActiveTab.includes('keyword')) {
                          handleOptionSelect(
                            false,
                            mediaItem.label.toLowerCase(),
                          );
                        }

                        setIsActive('keyword');
                        handleNameToggle('keyword');
                      }}
                      className={`px-[4px] m-1 py-[2px] text-sm rounded shadow border  hover:border-secondary   cursor-pointer ${
                        // isActive == "keyword"
                        (instagramHandler.keyword != '' && isEdit) ||
                        isActiveTab.includes('keyword')
                          ? 'bg-onsecondaryvariant text-onsecondary'
                          : 'bg-onprimary'
                      }`}
                    >
                      <div className="text-sm">Keyword</div>
                    </div>
                    {instagramHandler?.keyword && (
                      <div className="flex justify-center">
                        <Tooltip text={instagramHandler.keyword} align="start">
                          <div className="cursor-pointer relative">
                            {/* <CiCircleInfo className="text-[20px] font-bold text-green-700" /> */}
                            <p className="text-sm text-secondary">
                              {instagramHandler?.keyword?.length > 10
                                ? `${instagramHandler.keyword.slice(0, 10)}..`
                                : instagramHandler.keyword}
                            </p>
                          </div>
                        </Tooltip>
                        <p
                          onClick={() =>
                            removeHandler('', 'keyword', undefined)
                          }
                        >
                          {' '}
                          <svg
                            dataSlot="icon"
                            fill="none"
                            strokeWidth={1}
                            width="18px"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18 18 6M6 6l12 12"
                            />
                          </svg>{' '}
                        </p>
                      </div>
                    )}
                  </div>
                )
              )}
              {mediaItem.label.toLowerCase() === 'instagram' ||
              mediaItem.label.toLowerCase() === 'tiktok' ? (
                <div className="flex flex-col">
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!isActiveTab.includes('hashtag')) {
                        handleOptionSelect(true, mediaItem.label.toLowerCase());
                      }
                      // handleOptionSelect(true, mediaItem.label.toLowerCase());
                      setIsActive('hashtag');
                      handleNameToggle('hashtag');
                    }}
                    className={` px-[4px] m-1 py-[2px] text-sm rounded shadow border  hover:border-secondary   cursor-pointer ${
                      // isActive == "hashtag"
                      (instagramHandler.hashtag != '' && isEdit) ||
                      isActiveTab.includes('hashtag')
                        ? 'bg-onsecondaryvariant text-onsecondary'
                        : 'bg-onprimary'
                    }`}
                  >
                    <div className="text-sm">Hashtag</div>
                  </div>
                  {instagramHandler?.hashtag && (
                    <div className="flex justify-center items-center ">
                      <Tooltip text={instagramHandler.hashtag} align="start">
                        <div className="cursor-pointer relative">
                          {/* <CiCircleInfo className="text-[20px] font-bold text-green-700" /> */}
                          <p className="text-sm text-secondary">
                            {instagramHandler?.hashtag?.length > 10
                              ? `${instagramHandler.hashtag.slice(0, 10)}..`
                              : instagramHandler.hashtag}
                          </p>
                        </div>
                      </Tooltip>
                      <p onClick={() => removeHandler('', 'hashtag', true)}>
                        {' '}
                        <svg
                          dataSlot="icon"
                          fill="none"
                          strokeWidth={1}
                          width="18px"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18 18 6M6 6l12 12"
                          />
                        </svg>{' '}
                      </p>
                    </div>
                  )}
                </div>
              ) : null}
            </label>
            <div className="flex">
              {(mediaItem.label.toLowerCase() === 'instagram' ||
                mediaItem.label.toLowerCase() === 'tiktok' ||
                mediaItem.label.toLowerCase() === 'youtube') && (
                <Tooltip
                  text="User  - e.g., nike's account
                      Tagged  - e.g., @nike tagged by others
                      Hashtags - e.g., #nike tagged by others
                      Keyword - e.g., All (nike,#nike,@nike)"
                  align="start"
                  searchText={
                    mediaItem.label.toLowerCase() === 'instagram'
                      ? instaTooltipContent
                      : mediaItem.label.toLowerCase() === 'tiktok'
                      ? tiktokToopTipContent
                      : mediaItem.label.toLowerCase() === 'youtube'
                      ? youtubeTooltipContent
                      : null
                  }
                  width="w-[20rem]"
                >
                  <div className="cursor-pointer relative">
                    <CiCircleInfo
                      className="text-[20px] font-bold text-green-700"
                      // onMouseOver={() => setTooltip(!Tooltip)}
                      // onMouseLeave={() => setTooltip(!Tooltip)}
                    />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        )}

      {(mediaItem.state || isActiveChannel) && (
        <React.Fragment>
          <div className="flex absolute right-2 top-2">
            {!searchTermLoader && (
              <span
                className="material-icons text-primary opacity-70 hover:opacity-100 scale-75 hover:scale-100"
                onClick={() =>
                  handleMediaSearch(
                    socialMediaSearchValues[mediaItem.label.toLowerCase()],
                  )
                }
                title={`Refresh list`}
              >
                refresh
              </span>
            )}
            <span
              className="material-icons text-error opacity-70 hover:opacity-100 scale-75 hover:scale-100"
              onClick={() => {
                onMediaSourceToggle(mediaItem.id, false);
                setIsActiveChannel(false);
              }}
              title={`Disable ${mediaItem.label} channel`}
            >
              cancel
            </span>
          </div>
          {searchTermLoader && (
            <Loader message={`Scanning ${mediaItem.label}`} />
          )}

          {!searchTermLoader && !!searchTermResponse.length && (
            <div
              className={`${
                mediaItem.label.toLowerCase() === 'youtube'
                  ? 'h-[245px]'
                  : 'h-[285px]'
              } overflow-y-scroll mt-1 overflow-x-hidden`}
            >
              {instagramHandler?.[isActive] == '' ||
              !instagramHandler?.[isActive] ? (
                searchTermResponse.map((resultItem) => (
                  <DataList
                    key={uuidv4()}
                    dataItem={resultItem}
                    handleChannelClick={handleChannelClick}
                    currentSelection={currentSelection}
                  />
                ))
              ) : (
                <p className="text-xs px-4 py-2">
                  For more search results, deselect the X button next to the
                  search word
                </p>
              )}
            </div>
          )}
          {!searchTermLoader && !searchTermResponse.length && (
            <div className="text-surfacevariant flex h-[285px] justify-center items-center">
              No Suggestions to show
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

ChannelCard.propTypes = {
  mediaItem: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
    state: PropTypes.bool,
  }),
  option: PropTypes.bool,
  masterSearchQuery: PropTypes.string,
  onMediaSourceToggle: PropTypes.func,
  setMediaSearchInput: PropTypes.func,
  handleOptionSelect: PropTypes.func,
  socialMediaSearchValues: PropTypes.shape({
    twitter: PropTypes.string,
    facebook: PropTypes.string,
    instagram: PropTypes.string,
    reddit: PropTypes.string,
    news: PropTypes.string,
    tiktok: PropTypes.string,
    linkedin: PropTypes.string,
    youtube: PropTypes.string,
  }),
  setSocialMediaSearchValues: PropTypes.func.isRequired,
};

export default React.memo(ChannelCard);
